@import '../../css/themes.scss';
@import '../../css/mixins.scss';

.personal-accinsu-tab-imagebannermain-en {
    float: left;
    width: 100%;
    background: url(https://cdn.insuranceinbox.com/krImages/products_tabs_bg_01.jpg) no-repeat top center;
    padding: 5% 0px 0px 0px;
    height: 413px;
  background-size: cover;
  background-position: top;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .personal-accinsu-tab-imagebannermain-en {
        float: left;
        width: 100%;
        background: $white;
        padding: 80px 10px 0px;
        margin-top: 15px;
        height: auto;
}
.personal-accinsu-tab-imagebannermain-en h1.personal-accinsu-banner-heading-en {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;    
    color: $vdblue;
    text-align: center;
}
}
.personal-accinsu-banner-con-en {
    width: 1200px;
    margin: 0px auto;
}
.personal-accinsu-bdy-en {
    float: left;
    width: 100%;
    margin-bottom: 100px;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .personal-accinsu-banner-con-en {
        width: 100%;
        margin: 0px auto;
    }
}
/* DESKTOP BANNER BUTTONS CSS STARTS HERE */
.personal-accinsu-tab-btn-en {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    margin-top: 30px;
}
.personal-accinsu-tab-btn-item-en {
    flex: 0 auto;
    background: $white;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: $vdblue;
    padding: 8px 35px;
    margin: 5px;
    cursor: pointer;
    border-radius: 4px;
}
.personal-accinsu-tab-btn-item-active-en {
    flex: 0 auto;
    background: #b10e1f !important;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #fff;
    padding: 8px 35px;
    margin: 5px;
    cursor: pointer;
    border-radius: 4px;
}
/* DESKTOP BANNER BUTTONS CSS ENDS HERE */
.personal-accinsutab-banne-sec-en {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}
.personal-accinsutab-banne-sec-en h1.personal-accinsu-banner-heading-en {
    flex: 0 auto;
    padding-top: 80px;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .personal-accinsutab-banne-sec-en h1 {
        flex: 0 auto;
        font-size: 22px;
        font-weight: bold;
        color: $mred;
        padding-top: 40px;
    }
}

@media screen and (max-width: 650px) {
}
@media screen and (max-width: 400px) {
}

/* RESPONSIVE CSS STARTS HERE  */

@media all and (min-width:320px) and (max-width: 767px) {
}

/* TAB CSS STARTS HERE Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
.personal-accinsu-tab-imagebannermain-en {
    width: 100%;
}
.personal-accinsu-banner-con-en {
    width: 98%;
    margin: 0px auto;
    padding: 20px;
}
}
/* TAB CSS ENDS HERE Medium devices (landscape tablets, 768px and up) */

.personal-accinsu-dflex-en {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row;
    width: 100%;
    padding-top: 25px;
}
.personal-accinsu-dlex-lft-en {
    flex: 0 auto;
    width: 600px;
    padding-right: 50px;
}
.personal-accinsu-dlex-lft-en h2.pagetitle-en {
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.personal-accinsu-dlex-lft-en p.pg-txt-con {
    margin: 0px 0px 1rem 0px;
    padding: 0px 0px 0px 0px;
}
.personal-accinsu-dlex-lft-en h3.whykrinsu-title-en {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
h3.personal-accinsu-whykrinsu-title-en {
    float: left;
    width: 100%;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.personal-accinsu-pins-list-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    margin-bottom: 20px;
    width: 100%;
}
.personal-accinsu-pins-list-en .personal-accinsu-lt-one-en, .personal-accinsu-pins-list-en .personal-accinsu-lt-two-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.personal-accinsu-pins-list-en .personal-accinsu-lt-one-en img, .personal-accinsu-pins-list-en .personal-accinsu-lt-two-en img {
    width: 15px;
    margin-right: 10px;
}
.personal-accinsu-pins-list-en .personal-accinsu-lt-one-en {
    width: 25%;
}
.personal-accinsu-pins-list-en .personal-accinsu-lt-two-en {
    width: 75%;
}
.peraccinsu-tab-lifinsu-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin: 10px 0px 0px 0px;
}
.peraccinsu-tab-lifinsu-item-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: justify;
    margin: 0px 0px 15px;
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.peraccinsu-tab-lifinsu-item-en img {
    flex: 0 auto;
    width: 15px;
    margin-right: 10px;
    margin-top: 0px;
}
.personal-accinsu-dlex-rgt-en {
    flex: 0 auto;
    width: 600px;
}
.personal-accinsu-dlex-rgt-en img {
    width: 100%;
}
p.personal-accinsu-txt-con {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $black;
    flex: 0 auto;
    width: 100%;
}
h4.personal-accinsu-keyfet-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: $vdblue;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.personal-accinsu-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}
.personal-accinsu-keyfeatures-q-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px;
    padding: 0px;
}
.personal-accinsu-keyfeatures-q-en img {
    width: 15px;
    margin-right: 3px;
    margin-top: -3px;
}
.personal-accinsu-keyfeatures-ans-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 25px 23px;
    padding: 0px;
}
h3.personal-accinsu-page-sbu-title-en {
    float: left;
    width: 100%;
    margin: 5px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
p.personal-accinsu-txt-con {
    float: left;
    width: 100%;
    padding: 0px;
    margin: 0px;
}
.personal-accinsu-dsk-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 20px;
}
.personal-accinsu-dsk-keyfeatures-img-en {
    flex: 0 auto;
    width: 2%;
    margin: 0px 0px 15px 0px;
}
.personal-accinsu-dsk-keyfeatures-img-en img {
    width: 15px;
}
.personal-accinsu-dsk-keyfeatures-q-en {
    flex: 0 auto;
    width: 97%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: justify;
    margin: 0px 0px 15px 0px;
    padding: 0px;
}
.personal-accinsu-dsk-keyfeatures-ans-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: justify;
    margin: 10px 10px 25px 8%;
    padding: 0px;
}

/* MOBILE SECTION CSS STARTS HERE */
.personal-accinsu-mob-main-en {
    float: left;
    width: 100%;
    padding: 72px 15px 20px;
    margin: 0px 0px 0px 0px;
}
.personal-accinsu-mob-main-en h2.personal-accinsu-mob-main-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 20px;
    padding: 0px;
}
.personal-accinsu-mob-img-en {
    width: 100%;
    display: block;
    margin: 20px 0px;

}
.personal-accinsu-mob-img-en img {
    width: 100%;

}
.personal-accinsu-mob-txt-en {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: justify;
    margin: 0px 0px 20px;
    padding: 0px;
}
.personal-accinsu-tbpoints-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
}
.personal-accinsu-tbpoints-imgitem-en {
    flex: 0 auto;
    width: 3%;
    color: $vdblue;
    margin: 0px 0px 15px;
    padding: 0px;
}
.personal-accinsu-tbpoints-imgitem-en img {
    flex: 0 auto;
    width: 21px;
    margin-right: 15px;
    margin-top: 4px;
}
.personal-accinsu-tbpoints-item-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    margin: 0px 0px 15px 20px;
    padding: 0px;
}
h3.personal-accinsu-whykrinsu-mob-title-en {
    float:left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}
.personal-accinsu-mob-whykrlist-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
}
.personal-accinsu-mob-whykrlist-item-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 15px;
}
.personal-accinsu-mob-whykrlist-item-en img {
    width: 21px;
    margin-right: 7px;
}
h3.personal-accinsu-subtitle-en, h4.personal-accinsu-subtitle-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: $vdblue;
    margin: 10px 0px;
}
.personal-accinsu-mob-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 10px;
}
.personal-accinsu-mob-keyfeatures-img-en {
    flex: 0 auto;
    width: 3%;
}
.personal-accinsu-mob-keyfeatures-img-en img {
    width: 21px;
    margin-top: 4px;
}
.personal-accinsu-mob-keyfeatures-q-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    margin: 0px 0px 0px 20px;
    padding: 0px;
}
.personal-accinsu-mob-keyfeatures-ans-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: justify;
    margin: 10px 10px 25px 8%;
    padding: 0px;
}
/* MOBILE SECTION CSS STARTS HERE */