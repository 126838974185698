@import '../../css/themes.scss';
@import '../../css/mixins.scss';

.motorinsu-tab-imagebannermain-en {
    float: left;
    width: 100%;
    background: url('../../../public/products_tabs_bg_01.webp')no-repeat top center;
    // background: url(https://cdn.insuranceinbox.com/krImages/products_tabs_bg_01.jpg) no-repeat top center;
    padding: 5% 0px 0px 0px;
    height: 413px;
    background-size: cover;
    background-position: top;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .motorinsu-tab-imagebannermain-en {
        float: left;
        width: 100%;
        background: $white;
        padding: 80px 10px 0px;
        margin-top: 15px;
        height: auto;
}
.motorinsu-tab-imagebannermain-en h1.motorinsu-banner-heading-en {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;    
    color: $vdblue;
    text-align: center;
}
}
.motorinsu-banner-con-en {
    width: 1200px;
    margin: 0px auto;
}
.motorinsu-bdy-en {
    float: left;
    width: 100%;
    margin-bottom: 100px;
    h2.pagetitle-en {
        font-family: "Poppins", sans-serif;
        font-size: 36px;
        font-weight: 600;
        color: #00426a;
        margin: 10px 0px 15px 0px;
        padding: 0px 0px 0px 0px;
    }
.motorinsu-imgtxt-en {
    width: 100%;
    margin: 0;
 img.right-side {
    float: right;
    width: 50%;
    padding: 1%;
  }
  p.dsk-txt-con {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    text-align: justify;
  }
}
}
@media all and (min-width:320px) and (max-width: 767px) {
    .motorinsu-banner-con-en {
        width: 100%;
        margin: 0px auto;
    }
}
/* DESKTOP BANNER BUTTONS CSS STARTS HERE */
.motorinsu-tab-btn-en {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    margin-top: 30px;
}
.motorinsu-tab-btn-item-en {
    flex: 0 auto;
    background: $white;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: $vdblue;
    padding: 8px 35px;
    margin: 5px;
    cursor: pointer;
    border-radius: 4px;
}
.motorinsu-tab-btn-item-active-en {
    flex: 0 auto;
    background: #b10e1f !important;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #fff;
    padding: 8px 35px;
    margin: 5px;
    cursor: pointer;
    border-radius: 4px;
}
/* DESKTOP BANNER BUTTONS CSS ENDS HERE */
.motorinsutab-banne-sec-en {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}
.motorinsutab-banne-sec-en h1.motorinsu-banner-heading-en {
    flex: 0 auto;
    padding-top: 80px;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .motorinsutab-banne-sec-en h1 {
        flex: 0 auto;
        font-size: 22px;
        font-weight: bold;
        color: $mred;
        padding-top: 40px;
    }
}

@media screen and (max-width: 650px) {
}
@media screen and (max-width: 400px) {
}

/* RESPONSIVE CSS STARTS HERE  */

@media all and (min-width:320px) and (max-width: 767px) {
}

/* ACCORDING CSS STARTS HERE **** /

/*Acco*/
.acc-container {
    max-width: 100%;
    width: 100%;
  }
  .acc {
    margin-bottom: 10px;
  }  
  .acc-head-en {
    background-color: #ccc;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    padding: 8px 10px;
    margin: 0px;
    position: relative;
    cursor: pointer;
   }
   .acc-head-en.active {
     background-color: #b10e1f;
     color: #fff;
    }
   .acc-head-en::before,
   .acc-head-en::after {
    content: '';
    position: absolute;
    top: 50%;
    background-color: #fff;
    transition: all .3s;
   }
   .acc-head-en::before {
    right: 20px;
    width: 3px;
    height: 20px;
    margin-top: -10px;
  }
  .acc-head-en::after {
    right: 11px;
    width: 20px;
    height: 3px;
    margin-top: -2px;
  }
  .acc-head-en p {
    padding: 0px 18px 0px 0px;
    margin: 0px;
   }
  .acc-content {
    padding: 15px 10px;
    display: none;
  } 
  .acc-head-en.active::before {
      transform: rotate(90deg);
  }

.acc-content h2.person-hdtitle-en {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 700;
    
    color: $vdblue;
}
.acc-content h3.person-subtitle-en {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    
    color: $vdblue;
    margin: 20px 0px 10px;
}
.acc-content h4.person-sub-twotitle-en {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    margin: 20px 0px 10px;
}
.acc-content p.txt-en {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    padding: 0px;
    margin: 0px 0px 10px;
}
.acc-content .tab-keyfeatures-mob-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 20px;
}
.acc-content .plin-mob-img-en {
    width: 100%;
    display: block;
    margin: 20px 0px;

}
.acc-content .plin-mob-img-en img {
    width: 100%;
}
/* TAB CSS STARTS HERE Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
.motorinsu-tab-imagebannermain-en {
    width: 100%;
}
.motorinsu-banner-con-en {
    width: 98%;
    margin: 0px auto;
    padding: 20px;
}
}
/* TAB CSS ENDS HERE Medium devices (landscape tablets, 768px and up) */

.motorinsu-dflex-en {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row;
    width: 100%;
    padding-top: 25px;
}
.motorinsu-dlex-lft-en {
    flex: 0 auto;
    width: 600px;
    padding-right: 50px;
}
.motorinsu-dlex-lft-en h2.pagetitle-en {
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.motorinsu-dlex-lft-en p.pg-txt-con {
    margin: 0px 0px 1rem 0px;
    padding: 0px 0px 0px 0px;
}
.motorinsu-dlex-lft-en h3.whykrinsu-title-en {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    margin: 25px 0px 10px 0px;
    padding: 0px 0px 0px 0px;
}
h3.motorinsu-page-sbu-title-en {
    float: left;
    width: 100%;
    margin: 5px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}

.motorinsu-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}
.motorinsu-keyfeatures-itemimg-en {
    flex: 0 auto;
    width: 2%;
    margin: 0px 0px 15px 0px;
}
.motorinsu-keyfeatures-itemimg-en img {
    width: 15px;
    margin-top: 2px;
}
.motorinsu-keyfeatures-item-en {
    flex: 0 auto;
    width: 96%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: justify;
    padding: 0px;
    margin: 0px 0px 15px 10px;
}
.motorinsu-kft-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}
.motorinsu-kft-itemimg-en {
    flex: 0 auto;
    width: 1%;
    margin: 0px 0px 15px 0px;
}
.motorinsu-kft-itemimg-en img {
    width: 15px;
    margin-top: 2px;
}
.motorinsu-kft-item-en {
    flex: 0 auto;
    width: 98%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: justify;
    padding: 0px;
    margin: 0px 0px 15px 10px;
}
h3.motorinsu-page-sbu-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    margin: 5px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
h3.motorinsu-whykrinsu-title-en {
    float: left;
    width: 100%;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.motorinsu-pins-list-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    margin: 0px 0px 20px;
    width: 100%;
}
.motorinsu-pins-list-en .motorinsu-lt-one-en, .motorinsu-pins-list-en .motorinsu-lt-two-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.motorinsu-pins-list-en .motorinsu-lt-one-en img, .motorinsu-pins-list-en .motorinsu-lt-two-en img {
    width: 15px;
    margin-right: 10px;
}
.motorinsu-pins-list-en .motorinsu-lt-one-en {
    width: 25%;
}
.motorinsu-pins-list-en .motorinsu-lt-two-en {
    width: 75%;
}
.motorinsu-dlex-rgt-en {
    flex: 0 auto;
    width: 600px;
}
.motorinsu-dlex-rgt-en img {
    width: 100%;
    margin-bottom: 20px;
}
p.motorinsu-txt-con {
    flex: 0 auto;
    width: 100%;
    padding: 0px;
    margin: 0px;
}
h4.motorinsu-keyfet-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: $vdblue;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}

/* MOBILE SECTION CSS STARTS HERE */
.motorinsu-mob-main-en {
    float: left;
    width: 100%;
    padding: 72px 15px 20px;
    margin: 0px 0px 0px 0px;
}
.motorinsu-mob-main-en h2.motorinsu-mob-main-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 20px;
    padding: 0px;
}
.motorinsu-mob-img-en {
    width: 100%;
    display: block;
    margin: 20px 0px;

}
.motorinsu-mob-img-en img {
    width: 100%;

}
.motorinsu-mob-txt-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: justify;
    margin: 0px 0px 20px;
    padding: 0px;
}
h3.motorinsu-whykrinsu-mob-title-en {
    float:left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}
.motorinsu-mob-whykrlist-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}
.motorinsu-mob-whykrlist-item-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 15px;
}
.motorinsu-mob-whykrlist-item-en img {
    width: 21px;
    margin-right: 5px;
}
h3.motorinsu-subtitle-en, h4.motorinsu-subtitle-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px;
}
.motorinsu-mob-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}
.motorinsu-mob-keyfeatures-img-en {
    flex: 0 auto;
    width: 3%;
}
.motorinsu-mob-keyfeatures-img-en img {
    width: 20px;
    padding-top: 5px
}
.motorinsu-mob-keyfeatures-q-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    margin: 0px 0px 15px 20px;
    padding: 0px;
}
.motorinsu-mob-keyfeatures-ans-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: justify;
    margin: 10px 10px 25px 8%;
    padding: 0px;
}
.dropdown-sec {
    background: #F7F7F7;
    padding: 30px 20px 20px 20px;
    position: fixed;
    text-align: center;
    left: 0px;
    right: 0px;
    top: 65px;
    border-bottom: #ECECEC solid 1px;
}
.form-select {
    font-family: 'Poppins', sans-serif;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: $vdblue !important;
    height: 45px !important;
    
}
.form-select option {
    margin: 10px 0px !important;
    font-size: 13px !important;
    color: #000 !important;
}
/* MOBILE SECTION CSS STARTS HERE */

@include for-tablet-portrait-up {
    .tab-view {
        display: block;
    }
}