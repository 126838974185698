@import '../css/themes.scss';
@import '../css/mixins.scss';

.kr-ourteam-en {
  width: 1360px;
    min-height: 100vh;
    position: relative;
    z-index: 0;
    margin: 0px auto;
    padding: 7% 0px 80px 0px;  
  }
  @media all and (min-width: 1200px) and (max-width:1359px) {
    .kr-ourteam-en {
      width: 1200px;
      }
  }
  @media all and (min-width:320px) and (max-width: 767px) {
    .kr-ourteam-en {
      width: 100%;
      min-height: 100vh;
      position: relative;
      z-index: 0;
      margin: 0px auto;
      padding: 90px 20px 40px;
    }
  }
  
h1.team-title-en {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    text-align: center;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 1% 0px;
  }
  @media all and (min-width:320px) and (max-width: 767px) {
    h1.team-title-en {
      display: block;
      width: 100%;
      font-size: 30px;
      font-weight: 600;
      color: $mred;
      text-align: center;
      padding: 0px 0px 0px 0px;
      margin: 0px 0px 20px 0px;
    }
  }
.team-head-txt-en {
  display: block;
  width: 56%;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: $black;
  text-align: center;
  padding: 0px 100px;
  margin: 0px auto 40px;
}
  @media all and (min-width:320px) and (max-width: 767px) {
    .team-head-txt-en {
      display: block;
      width: 100%;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      color: $black;
      padding: 0px 20px 0px 20px;
      margin: 0px 0px 30px 0px;
    }
  }
  .kr-ourteam .contactUs{
    background: linear-gradient(-45deg, $mred 18%, $vdblue 0%);
    background: -moz-linear-gradient(-45deg, $mred 18%, $vdblue 0%);
    background: -o-linear-gradient(-45deg, $mred 18%, $vdblue 0%);
    background: -webkit-linear-gradient(-45deg, $mred 18%, $vdblue 0%);
    padding: 15px 30px;
    border: solid 0px;
    color: #fff;
    margin-top: 7%;
    border-radius: 0%;
    font-size: 16px;
    
  font-family: 'Poppins', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
  }
  .contactUs:hover {
    background: linear-gradient(-45deg, $mred 18%, $vdblue 0%);
    background: -moz-linear-gradient(-45deg, $mred 18%, $vdblue 0%);
    background: -o-linear-gradient(-45deg, $mred 18%, $vdblue 0%);
    background: -webkit-linear-gradient(-45deg, $mred 18%, $vdblue 0%);
  }
  .contactUs:focus {
    background: linear-gradient(-45deg, $mred 18%, $vdblue 0%);
    background: -moz-linear-gradient(-45deg, $mred 18%, $vdblue 0%);
    background: -o-linear-gradient(-45deg, $mred 18%, $vdblue 0%);
    background: -webkit-linear-gradient(-45deg, $mred 18%, $vdblue 0%);
  }
  
  .kr-ourteam .contactUs .fa{
    font: normal normal normal 14px/1 FontAwesome !important;
  }
  
  .kr-ourteam .contact-persons{
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
  }
  
  .kr-ourteam .contact-persons .first-person{
    margin: 0px 22px;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
  
  }
  
  .kr-ourteam .first-person:nth-child(2), .kr-ourteam .first-person:nth-child(3),
  .kr-ourteam .first-person:nth-child(7), .kr-ourteam .first-person:nth-child(6)
  {
    margin-top: 100px;
  }
  
  .kr-ourteam .first-person p.person-name{
  padding: 15px 0px 20px 0px;
  }
  
  .kr-ourteam .first-person p{
    color:$vdblue;
    font-size: 16px;
    margin-bottom: 0px;
  }
  
  
  .kr-ourteam .first-person .fa{
    font: normal normal normal 14px/1 FontAwesome !important;
    padding-right: 10px;
  }

  .first-person p svg{
  margin-right: 10px;
  }
  
  .kr-ourteam p.mob-num{
    font-size: 16px;
    color:$mred;
  }
  .ourteam-en {
    display: flex;
    justify-content: flex-start;
    /* justify-content: center; */
    align-items: flex-start;
    flex-flow: row wrap;
    /* margin-left: auto;
    margin-right: auto; */
    max-width: 100%;
}
.ourteam-item-en {
    flex: 0 auto;
    /* width: 238px; */
    width: 292px;
    margin:0px 24px 50px 24px;
    position: relative;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .ourteam-item-en {
      flex: 0 auto;
      /* width: 238px; */
      width: 292px;
      margin:0px 0px 50px 0px;
      position: relative;
  }
}
.portfolioDisc {
    position: absolute;
    top: -80%;
    width: 100%;
    height: 350px;
    overflow:hidden;
    -webkit-transition: all 0.6s linear;
    -moz-transition: all 0.6s linear;
    -o-transition: all 0.6s linear;
    transition: all 0.6s linear;
    position:absolute;
    background-color: rgba(0,0,0, 0.4);
    text-align:center;
}
.portfolioDisc {
  /*margin-top: -400px;*/
  top: 0px;
}
.port:hover .portfolioDisc {
    top: 0px;
    height:350px;
    
}
.portfolioDisc p {
    -webkit-transition: all 0.130s linear;
    -moz-transition: all 0.130s linear;
    -o-transition: all 0.130s linear;
    transition: all 0.130s linear;
    margin-top:0px;
  padding:10px 20px;
  text-align:center;
  
}
.portfolioDisc p a {
  color:#fff;
}
.port {
    background-color: red;
    width:100%;
    height: 450px;
    position:relative;
}
h4.name {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #00a769;
}
p.desg {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: $black;
}

.tup-style-1-en {
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 1;
}
.tup-style-1-en .tup-style-1-pic-en {
    margin: 0;
    position: relative;
    transition: all .5s ease 0s;
}
.tup-style-1-en .tup-style-1-pic-no-en {
    margin: 0;
    position: relative;
    transition: all .5s ease 0s;
}
.tup-style-1-en:hover .tup-style-1-pic-en {
    border-color: #33343e;
}
.tup-style-1-en .tup-style-1-pic-en:after {
    content: "";
    width: 100%;
    height: 0;
    background: #33343e;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transform-origin: 0 0 0;
    transition: all .5s ease 0s;
}
.tup-style-1-en:hover .tup-style-1-pic-en:after {
    height: 100%;
    opacity: .85;
}
.tup-style-1-en img {
    /* width: 100%; */
    width: 292px;
    height: 334px;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .tup-style-1-en img {
      width: 100%;
      height: 100%;
      /* width: 292px;
      height: 334px; */
  }
}
.tup-style-1-en .tup-style-1-content-en {
    width: 100%;
    position: absolute;
    top: -80%;
    left: 0;
    transition: all .5s ease .2s;
}
.tup-style-1-en:hover .tup-style-1-content-en {
    top: 5%;
}
.tup-style-1-en .title {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin: 0 0 5px;
}
.tup-style-1-en .tup-style-1-biography-en {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
    color: #fff;
    line-height: 26px;
    padding: 0 15px;
}
.tup-style-1-en .tup-popup-area-1-en {
    display: block;
    overflow: hidden;
}
.tup-style-1-en .tup-style-1-biography-en a {
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #fff;
    text-decoration: underline !important;
    margin-top: 0;
    outline: medium none;
    border: medium none;
    box-shadow: none;
    cursor: pointer;
}
.tup-style-1-en .tup-style-1-team-info-en {
    /* display: block;
    overflow: hidden; */
    padding: 15px 5px;
    line-height: inherit !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
}
.tup-style-1-title-en {
    display: block;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #00a769;
    padding: 0;
}
.tup-style-1-designation-en {
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 0px;
  overflow: hidden;
  line-height: inherit !important;
}

/* .modal-backdrop.fade {
  opacity: 0;
} */

.team-mem-en {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row wrap;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .team-mem-en {
    flex-flow: column;
  }
}
.team-mempic-en {
  flex: 0 auto;
  width: 20%;
  margin-right: 20px;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .team-mempic-en {
    flex: 0 auto;
    width: 100%;
    margin-right: 0px;
  }
}
.team-mempic-nm-en {
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 700;
  color: #00a769;
  text-align: center;
  padding: 0px 0px 0px 0px;
  margin: 20px 0px 0px 0px;
}
@media all and (min-width:320px) and (max-width: 767px){
  .team-mempic-nm-en {
    margin: 20px 0px 10px 0px;
  }
}
.team-mempic-dec-en {
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $black;
  text-align: center;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.team-memcon-en {
  flex: 0 auto;
  width: 75%;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $black;  
  padding-right: 20px;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .team-memcon-en {
    width: 100%;
    font-size: 16px;
    text-align: center;
    padding-right:0px;
    padding-top: 20px;
  }
}
.modal-fullscreen {
  width: 80vw !important;
  height: auto !important;
  margin: 0 auto !important;
  border-radius: 20px !important;
}
.modal-fullscreen .modal-content {
  border-radius: 15px !important;
}



  @media all and (min-width:320px) and (max-width: 767px){
    .kr-ourteam{
      padding-top: 0px;
    }
    .kr-ourteam .contact-persons{
      padding-top: 20px;
    }
    .kr-ourteam .first-person p {
      font-size: 20px;
    }
    .kr-ourteam p.mob-num{
      font-size: 20px;
    }
    .ourteam-en {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
  }
  .ourteam-item-en {
      flex: 0 auto;
      width: 80%;
      margin:0px 0px 20px 0px;
      position: relative;
  }
.team-mem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.team-mempic {
  flex: 0 auto;
  width: 100%;
  margin-bottom: 20px;
}
.team-memcon {
  flex: 0 auto;
  width: 100%;
  margin-bottom: 20px;
  padding-right: 0px;
}
.modal-fullscreen .modal-body {
  padding: 20px 0px;
}
  }
.btn-secondary {
    font-family: 'Amaranth', sans-serif !important;
    font-size: 18px !important;
    font-weight: 400;
  }

/* NORMAL DESKTOP CSS STARTS HERE */
@media all and (min-width: 1200px) and (max-width:1359px) {
  .team-mempic-en {
    flex: 0 1 auto;
    width: 30%;
    margin-right: 20px;
}
.team-memcon-en {
  flex: 0 1 auto;
  width: 60%;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $black;
  padding-right: 20px;
}
}
/* NORMAL DESKTOP CSS ENDS HERE */

/* TAB CSS STARTS HERE */
@media only screen and (min-width: 768px) and (max-width:1200px) {
  .kr-ourteam-en {
    width: 100%;
    min-height: 100vh;
    position: relative;
    z-index: 0;
    margin: 0px auto;
    padding: 13% 0px 80px 0px;
}
.team-head-txt-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: $black;
  text-align: center;
  padding: 0px 30px;
  margin: 0px 0px 40px 0px;
}
.ourteam-en {
  display: flex;
  /* justify-content: flex-start; */
  justify-content: space-evenly;
  align-items: flex-start;
  flex-flow: row wrap;
  max-width: 100%;
}
.ourteam-item-en {
  flex: 0 1 auto;
  /* width: 238px; */
  width: 40%;
  margin: 0px 24px 25px 24px;
  position: relative;
}
.tup-style-1-en img {
  width: 100%;
  height: 100%;
  /* width: 292px;
  height: 334px; */
}
.team-mem-en {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.team-mempic-en {
  flex: 0 1 auto;
  width: 100%;
  margin-right: 0px;
  text-align: center;
}
.team-mempic-nm-en {
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #00a769;
  text-align: center;
  padding: 0px 0px 0px 0px;
  margin: 20px 0px 0px 0px;
}
.team-mempic-dec-en {
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: $black;
  text-align: center;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.team-memcon-en {
  flex: 0 1 auto;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 400;
  color: $black;
  text-align: center;
  padding-right: 0px;
  margin-top: 35px;
}
}