@import '../../css/mixins.scss';
@import '../../css/themes.scss';

.wmencompinsu-tab-imagebannermain-ar {
    float: left;
    width: 100%;
    background: url(https://cdn.insuranceinbox.com/krImages/products_tabs_bg_01.jpg) no-repeat top center;
    padding: 5% 0px 0px 0px;
    height: 413px;
  background-size: cover;
  background-position: top;
  @include for-tablet-portrait-up {
    height: 465px;
  }
}
@media all and (min-width:320px) and (max-width: 767px) {
    .wmencompinsu-tab-imagebannermain-ar {
        float: left;
        width: 100%;
        background: $white;
        padding: 80px 10px 0px;
        margin-top: 15px;
        height: auto;
}
.wmencompinsu-tab-imagebannermain-ar h1.wmencompinsu-banner-heading-ar {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;    
    color: $vdblue;
    text-align: center;
}
}
.wmencompinsu-banner-con-ar {
    width: 1200px;
    margin: 0px auto;
}
.wmencompinsu-bdy-ar {
    float: left;
    width: 100%;
    margin-bottom: 100px;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .wmencompinsu-banner-con-ar {
        width: 100%;
        margin: 0px auto;
    }
}
/* DESKTOP BANNER BUTTONS CSS STARTS HERE */
.wmencompinsu-tab-btn-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row-reverse wrap;
    margin-top: 20px;
    @include for-tablet-portrait-up {
        margin-top: 10px;
    }
    .wmencompinsu-tab-btn-item-ar {
        flex: 0 auto;
        background: $white;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: $vdblue;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
    .wmencompinsu-tab-btn-item-active-ar {
        flex: 0 auto;
        background: $vdblue !important;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
}
/* DESKTOP BANNER BUTTONS CSS ENDS HERE */
.wmencompinsutab-banne-sec-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}
.wmencompinsutab-banne-sec-ar h1.wmencompinsu-banner-heading-ar {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;
    padding-top: 20px;
    @include for-tablet-portrait-up {
        padding-top: 40px;
    }
}
@media all and (min-width:320px) and (max-width: 767px) {
    .wmencompinsutab-banne-sec-ar h1 {
        flex: 0 auto;
        font-size: 22px;
        font-weight: bold;
        color: $mred;
        padding-top: 40px;
    }
}

@media screen and (max-width: 650px) {
}
@media screen and (max-width: 400px) {
}

/* RESPONSIVE CSS STARTS HERE  */

@media all and (min-width:320px) and (max-width: 767px) {
}
/* TAB CSS STARTS HERE Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
.wmencompinsu-tab-imagebannermain-ar {
    width: 100%;
}
.wmencompinsu-banner-con-ar {
    width: 98%;
    margin: 0px auto;
    padding: 20px;
}
}
/* TAB CSS ENDS HERE Medium devices (landscape tablets, 768px and up) */

.wmencompinsu-dflex-ar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row;
    width: 100%;
    padding-top: 25px;
}
.wmencompinsu-dlex-lft-ar {
    flex: 0 auto;
    width: 600px;
    padding-left: 50px;
}
.wmencompinsu-dlex-lft-ar h2.pagetitle-ar {
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.wmencompinsu-dlex-lft-ar p.pg-txt-con-ar {
    text-align: right;
    margin: 0px 0px 1rem 0px;
    padding: 0px 0px 0px 0px;
}
.wmencompinsu-dlex-lft-ar h3.whykrinsu-title-en {
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
h3.wmencompinsu-whykrinsu-title-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.wmencompinsu-pins-list-dksp-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
}
.wmencompinsu-pins-list-dksp-en .wmencompinsu-lt-one-dksp-en, .wmencompinsu-pins-list-dksp-en .wmencompinsu-lt-two-dksp-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $vdblue;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.wmencompinsu-pins-list-dksp-en .wmencompinsu-lt-one-dksp-en img, .wmencompinsu-pins-list-dksp-en .wmencompinsu-lt-two-dksp-en img {
    width: 15px;
    margin-right: 10px;
}
.wmencompinsu-pins-list-dksp-en .wmencompinsu-lt-one-dksp-en {
    width: 20%;
}
.wmencompinsu-pins-list-dksp-en .wmencompinsu-lt-two-dksp-en {
    width: 80%;
}


.wmencompinsu-pins-list-lft-ar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
}
.wmencompinsu-pins-list-lft-ar .wmencompinsu-lt-one-lft-ar, .wmencompinsu-pins-list-lft-ar .wmencompinsu-lt-two-lft-ar {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.wmencompinsu-pins-list-lft-ar .wmencompinsu-lt-one-lft-ar img, .wmencompinsu-pins-list-lft-ar .wmencompinsu-lt-two-lft-ar img {
    width: 15px;
    margin-left: 10px;
}
.wmencompinsu-pins-list-lft-ar .wmencompinsu-lt-one-lft-ar {
    width: 25%;
}
.wmencompinsu-pins-list-lft-ar .wmencompinsu-lt-two-lft-ar {
    width: 75%;
}

h3.wmencompinsu-subtitle-ar {
    float: left;
    width: 100%;
    margin: 10px 0px;
}

.wmencompinsu-pins-list-dsk-en {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
}
.wmencompinsu-pins-list-dsk-itemimg-en {
    flex: 0 auto;
    width: 2%;
    margin-bottom: 15px;
}
.wmencompinsu-pins-list-dsk-itemimg-en img {
    width: 15px;
}
.wmencompinsu-pins-list-dsk-item-en {
    flex: 0 auto;
    width: 97%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 15px;
}
.wmencompinsu-pins-list-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    margin: 20px 0px;
}
.wmencompinsu-pins-list-en .wmencompinsu-lt-one-en, .wmencompinsu-pins-list-en .wmencompinsu-lt-two-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $vdblue;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.wmencompinsu-pins-list-en .wmencompinsu-lt-one-en img, .wmencompinsu-pins-list-en .wmencompinsu-lt-two-en img {
    width: 15px;
    margin-right: 10px;
}
.wmencompinsu-pins-list-en .wmencompinsu-lt-one-en {
    width: 46%;
}
.wmencompinsu-pins-list-en .wmencompinsu-lt-two-en {
    width: 54%;
}
.wmencompinsu-dlex-rgt-ar {
    flex: 0 auto;
    width: 600px;
}
.wmencompinsu-dlex-rgt-ar img {
    width: 100%;
}
p.wmencompinsu-txt-con-ar {
    float: left;
    width: 100%;
    text-align: right;
    flex: 0 auto;
    width: 100%;
}
h4.wmencompinsu-keyfet-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: $vdblue;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.wmencompinsu-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}
.wmencompinsu-keyfeatures-q-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px;
    padding: 0px;
}
.wmencompinsu-keyfeatures-q-en img {
    width: 15px;
    margin-right: 3px;
    margin-top: -3px;
}
.wmencompinsu-keyfeatures-ans-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 25px 23px;
    padding: 0px;
}
.wmencompinsu-keypoint-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    .wmencompinsu-keypoint-itemimg-ar {
        flex: 0 auto;
        width: 5%;
        margin-bottom: 15px;
        img {
            width: 15px;
            margin-top: -2px;
        }
    }
    .wmencompinsu-keypoint-item-ar {
        flex: 0 auto;
        width: 90%;
        margin-right: 15px;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: $black;
        text-align: right;
        margin-bottom: 15px;
    }
}
.wmencompinsu-kpnt-en {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    .wmencompinsu-kpnt-itemimg-en {
        flex: 0 auto;
        width: 2%;
        margin-bottom: 15px;
        img {
            width: 15px;
            margin-top: -2px;
        }
    }
    .wmencompinsu-kpnt-item-en {
        flex: 0 auto;
        width: 97%;
        margin-left: 10px;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-weight: 400;
        color: $vdblue;
        text-align: right;
        margin-bottom: 15px;
    }
}


/* MOBILE SECTION CSS STARTS HERE */
.wmencompinsu-mob-main-ar {
    float: left;
    width: 100%;
    padding: 72px 15px 20px;
    margin: 0px 0px 0px 0px;
}
.wmencompinsu-mob-main-ar h2.wmencompinsu-mob-main-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 20px;
    padding: 0px;
}
.wmencompinsu-mob-img-ar {
    width: 100%;
    display: block;
    margin: 20px 0px;
}
.wmencompinsu-mob-img-ar img {
    width: 100%;
}
.wmencompinsu-mob-txt-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: justify;
    margin: 0px 0px 20px;
    padding: 0px;
}
h3.wmencompinsu-whykrinsu-mob-title-ar {
    float:left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}
.wmencompinsu-pins-list-mob-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
    .wmencompinsu-pins-list-mob-itemimg-ar {
        flex: 0 auto;
        width: 3%;
        margin-bottom: 15px;
        padding-top: 3px;
        img {
            width: 22px;
        }
    }
    .wmencompinsu-pins-list-mob-item-ar {
        flex: 0 auto;
        width: 90%;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: $black;
        text-align: right;
        margin-right: 15px;
        margin-bottom: 15px;
    }
}
.wmencompinsu-mob-whykrlist-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    .wmencompinsu-mob-whykrlist-item-ar {
        flex: 0 auto;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: $black;
        text-align: right;
        margin-bottom: 15px;
        img {
            width: 21px;
            margin-left: 15px;
        }
    }
}
h3.wmencompinsu-subtitle-ar, h4.wmencompinsu-subtitle-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 10px 0px;
}
.wmencompinsu-subtitle-mob-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    text-align: right;
    line-height: 30px;
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}
.wmencompinsu-mob-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}
.wmencompinsu-mob-keyfeatures-img-en {
    flex: 0 auto;
    width: 3%;
}
.wmencompinsu-mob-keyfeatures-img-en img {
    width: 20px;
    padding-top: 5px
}
.wmencompinsu-mob-keyfeatures-q-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 20px 20px;
    padding: 0px;
}
.wmencompinsu-mob-keyfeatures-ans-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: justify;
    margin: 10px 10px 20px 8%;
    padding: 0px;
}
.dropdown-sec {
    background: #F7F7F7;
    padding: 30px 20px 20px 20px;
    position: fixed;
    text-align: center;
    left: 0px;
    right: 0px;
    top: 65px;
    border-bottom: #ECECEC solid 1px;
}
.form-select {
    font-family: 'Poppins', sans-serif;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: $vdblue !important;
    height: 45px !important;
    
}
.form-select option {
    margin: 10px 0px !important;
    font-size: 13px !important;
    color: #000 !important;
}
/* MOBILE SECTION CSS STARTS HERE */