@import './css/themes.scss';
@import './css/main.scss'; 
@import './css/slick-theme.min.scss';
@import './css/slick.min.scss';

.content {
  overflow-x: hidden;
}
.propertyinsutab-banne-sec-en h1.engineerinsu-banner-heading-en,
.terrpolirskinsutab-banne-sec-en h1.terrpolirskinsu-banner-heading-en,
.motorfleetinsutab-banne-sec-en h1.motorfleetinsu-banner-heading-en,
.propertyinsutab-banne-sec-en h1.grphlthinsu-banner-heading-en,
.propertyinsutab-banne-sec-en h1.grplifeinsu-banner-heading-en, 
.propertyinsutab-banne-sec-en h1.fidelityguarinsu-banner-heading-en, 
.burglaryinsutab-banne-sec-en h1.burglaryinsu-banner-heading-en, 
.wmencompinsutab-banne-sec-en h1.wmencompinsu-banner-heading-en, 
.grpacc-covinsutab-banne-sec-en h1.grpacc-covinsu-banner-heading-en, 
.propertyinsutab-banne-sec-en h1.propertyinsu-banner-heading-en, 
.propertyinsutab-banne-sec-en h1.cybercrimeinsubanner-heading-en, 
.propertyinsutab-banne-sec-en h1.jewellersblkinsu-banner-heading-en, 
.stckcovinsutab-banne-sec-en h1.stckcovinsu-banner-heading-en, 
.propertyinsutab-banne-sec-en h1.hauvalcarinsu-banner-heading-en, 
.splriskevtinsutab-banne-sec-en h1.splriskevtinsu-banner-heading-en, 
.liabilityinsutab-banne-sec-en h1.liabilityinsu-banner-heading-en, 
.marineinsutab-banne-sec-en h1.marineinsu-banner-heading-en, 
.propertyinsutab-banne-sec-en h1.energyinsu-banner-heading-en, 
.motorinsutab-banne-sec-en h1.motorinsu-banner-heading-en, 
.travinsutab-banne-sec-en h1.travinsu-banner-heading-en,
.personal-accinsutab-banne-sec-en h1.personal-accinsu-banner-heading-en, 
.relocationinsutab-banne-sec-en h1.relocationinsu-banner-heading-en, 
.healthinsutab-banne-sec-en h1.healthinsu-banner-heading-en, 
.artinsutab-banne-sec-en h1.artinsu-banner-heading-en,
.golfinsutab-banne-sec-en h1.golfinsu-banner-heading-en, 
.homemaidinsutab-banne-sec-en h1.homemaidinsu-banner-heading-en, 
.yachtinsutab-banne-sec-en h1.yachtinsu-banner-heading-en, 
.homeinsutab-banne-sec-en h1.homeinsu-banner-heading-en, 
.krtab-banne-sec-en h1.plinsu-heading-en, 
.krtab-banne-sec-en h1.plinsu-heading-en, 
.riskinspetservtab-banne-sec-en h1.riskinspetserv-banner-heading-en, 
.themogtestinsutab-banne-sec-en h1.themogtestinsu-banner-heading-en, 
.valuservicestab-banne-sec-en h1.valuservices-banner-heading-en, 
.insurauditstab-banne-sec-en h1.insuraudits-banner-heading-en, 
.servicexcelltab-banne-sec-en h1.servicexcell-banner-heading-en, 
.claimhandinsutab-banne-sec-en h1.claimhandinsu-banner-heading-en, 
.inhouautgartab-banne-sec-en h1.inhouautgar-banner-heading-en, 
.erpbasedsolutab-banne-sec-en h1.erpbasedsolu-banner-heading-en,
.lifeinsu-tab-banne-sec-en h1.lifeinsu-banner-heading-en,
.privacy-policy-banner-sec-en h1.privacy-policy-heading-en,
.reportclaimtab-banne-sec-en h1.reportclaim-banner-heading-en,
.projectinsu-tab-imagebannermain-en h1.projectinsu-banner-heading-en, 
.empwellprotab-banne-sec-en h1.empwellpro-banner-heading-en,
// ARBIC 
.propertyinsutab-banne-sec-ar h1.propertyinsu-banner-heading-ar, 
.propertyinsutab-banne-sec-ar h1.projectinsu-banner-heading-ar, 
h1.terrpolirskinsu-banner-heading-ar,
h1.motorfleetinsu-banner-heading-ar,
h1.grphlthinsu-banner-heading-ar,
h1.grplifeinsu-banner-heading-ar, 
h1.fidelityguarinsu-banner-heading-ar, h1.grpacc-covinsu-banner-heading-ar,
h1.cybercrimeinsubanner-heading-ar, h1.jewellersblkinsu-banner-heading-ar, 
h1.stckcovinsu-banner-heading-ar, h1.hauvalcarinsu-banner-heading-ar,
h1.liabilityinsu-banner-heading-ar, h1.energyinsu-banner-heading-ar,
h1.privacy-policy-heading-ar, h1.motorinsu-banner-heading-ar,
h1.travinsu-banner-heading-ar, h1.personal-accinsu-banner-heading-ar, h1.relocationinsu-banner-heading-ar,
h1.healthinsu-banner-heading-ar, h1.artinsu-banner-heading-ar, h1.golfinsu-banner-heading-ar, 
h1.homemaidinsu-banner-heading-ar, h1.yachtinsu-banner-heading-ar, h1.homeinsu-banner-heading-ar, 
h1.lifeinsu-banner-heading-ar, h1.riskinspetserv-banner-heading-ar, h1.insuraudits-banner-heading-ar, 
h1.themogtestinsu-banner-heading-ar, h1.claimhandinsu-banner-heading-ar, h1.servicexcell-banner-heading-ar,
h1.inhouautgar-banner-heading-ar, h1.erpbasedsolu-banner-heading-ar, h1.valuservices-banner-heading-ar, 
h1.burglaryinsu-banner-heading-ar, h1.wmencompinsu-banner-heading-ar, h1.splriskevtinsu-banner-heading-ar, 
h1.marineinsu-banner-heading-ar, h1.empwellpro-banner-heading-ar {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #fff;
}
.propertyinsu-dlex-lft-en h2.pagetitle-en, h3.propertyinsu-page-sbu-title-en,
.projectinsu-dlex-lft-en h2.pagetitle-en, h3.projectinsu-page-sbu-title-en, h3.projectinsu-whykrinsu-title-en,
.terrpolirskinsu-dlex-lft-en h2.pagetitle-en, h3.terrpolirskinsu-page-sbu-title-en, h3.terrpolirskinsu-whykrinsu-title-en,
.motorfleetinsu-dlex-lft-en h2.pagetitle-en, h3.motorfleetinsu-page-sbu-title-en, 
.grphlthinsu-dlex-lft-en h2.pagetitle-en, h3.grphlthinsu-page-sbu-title-en, .grphlthinsu-dlex-lft-en h3.grphlthinsu-whykrinsu-title-en, h3.grphlthinsu-whykrinsu-title-en, 
.grplifeinsu-dlex-lft-en h2.pagetitle-en, h3.grplifeinsu-page-sbu-title-en, .grplifeinsu-dlex-lft-en h3.grplifeinsu-whykrinsu-title-en, h3.grplifeinsu-whykrinsu-title-en, 
.fidelityguarinsu-dlex-lft-en h2.pagetitle-en, h3.fidelityguarinsu-page-sbu-title-en, h3.fidelityguarinsu-whykrinsu-title-en,
.burglaryinsu-dlex-lft-en h2.pagetitle-en, h3.burglaryinsu-page-sbu-title-en, .burglaryinsu-dlex-lft-en h3.burglaryinsu-whykrinsu-title-en, h3.burglaryinsu-whykrinsu-title-en, 
.wmencompinsu-dlex-lft-en h2.pagetitle-en, h3.wmencompinsu-subtitle-en, h4.wmencompinsu-subtitle-en, h3.wmencompinsu-whykrinsu-title-en, 
.grpacc-covinsu-dlex-lft-en h2.pagetitle-en, h3.grpacc-covinsu-page-sbu-title-en, h3.grpacc-covinsu-whykrinsu-title-en, 
.cybercrimeinsudlex-lft-en h2.pagetitle-en, h3.cybercrimeinsu-page-sbu-title-en, h3.cybercrimeinsu-whykrinsu-title-en, 
.jewellersblkinsu-dlex-lft-en h2.pagetitle-en, h3.jewellersblkinsu-page-sbu-title-en, h3.jewellersblkinsu-whykrinsu-title-en, 
.stckcovinsu-dlex-lft-en h2.pagetitle-en, h3.stckcovinsu-page-sbu-title-en, h3.stckcovinsu-whykrinsu-title-en, 
.hauvalcarinsu-dlex-lft-en h2.pagetitle-en, h3.hauvalcarinsu-page-sbu-title-en, h3.hauvalcarinsu-whykrinsu-title-en, 
.splriskevtinsu-dlex-lft-en h2.pagetitle-en, h3.splriskevtinsu-page-sbu-title-en, h3.splriskevtinsu-whykrinsu-title-en, 
.liabilityinsu-dlex-lft-en h2.pagetitle-en, h3.liabilityinsu-page-sbu-title-en, h3.liabilityinsu-whykrinsu-title-en, 
.marineinsu-dlex-lft-en h2.pagetitle-en, h3.marineinsu-page-sbu-title-en, h3.marineinsu-whykrinsu-title-en, 
.energyinsu-dlex-lft-en h2.pagetitle-en,
.motorinsu-dlex-lft-en h2.pagetitle-en, h3.motorinsu-page-sbu-title-en, h3.motorinsu-whykrinsu-title-en, 
.travinsu-dlex-lft-en h2.pagetitle-en, h3.travinsu-page-sbu-title-en, h4.travinsu-keyfet-en,  h3.travinsu-whykrinsu-title-en, 
.personal-accinsu-dlex-lft-en h2.pagetitle-en, h3.personal-accinsu-page-sbu-title-en, h3.personal-accinsu-whykrinsu-title-en, 
.relocationinsu-dlex-lft-en h2.pagetitle-en, h3.relocationinsu-page-sbu-title-en, h3.relocationinsu-whykrinsu-title-en, 
.healthinsu-dlex-lft-en h2.pagetitle-en, h3.healthinsu-page-sbu-title-en, h3.healthinsu-whykrinsu-title-en, 
.artinsu-dlex-lft-en h2.pagetitle-en, h3.artinsu-page-sbu-title-en, 
.golfinsu-dlex-lft-en h2.pagetitle-en, h3.golfinsu-page-sbu-title-en, 
.homemaidinsu-dlex-lft-en h2.pagetitle-en, h3.homemaidinsu-page-sbu-title-en, h3.homemaidinsu-whykrinsu-title-en, 
.yachtinsu-dlex-lft-en h2.pagetitle-en, h3.yachtinsu-page-sbu-title-en, h3.yachtinsu-whykrinsu-title-en, 
.homeinsu-dlex-lft-en h2.pagetitle-en, h3.homeinsu-page-sbu-title-en, h3.homeinsu-whykrinsu-title-en, 
.lifeinsu-dlex-lft-en h2.pagetitle-en, h3.lifeinsu-page-sbu-title-en, h3.whykrinsu-title-en, 
.riskinspetserv-dlex-lft-en h2.pagetitle-en, h3.riskinspetserv-whykrinsu-title-en, 
.themogtestinsu-dlex-lft-en h2.pagetitle-en, .themogtestinsu-dlex-lft-en h3.themogtestinsu-whykrinsu-title-en, 
.valuservices-dlex-lft-en h2.pagetitle-en, h3.valuservices-whykrinsu-title-en, 
.insuraudits-dlex-lft-en h2.pagetitle-en, h3.insuraudits-whykrinsu-title-en, 
.servicexcell-bdy-en h2.pagetitle-en, h3.servicexcell-whykrinsu-title-en, 
.claimhandinsu-bdy-en h2.pagetitle-en, h3.claimhandinsu-whykrinsu-title-en, 
.inhouautgar-dlex-lft-en h2.pagetitle-en, h3.inhouautgar-whykrinsu-title-en, 
.erpbasedsolu-dlex-lft-en h2.pagetitle-en, h3.erpbasedsolu-whykrinsu-title-en, 
.empwellpro-dlex-lft-en h2.pagetitle-en, h3.empwellpro-whykrinsu-title-en, .empwellpro-page-sbu-title-en, 
.tab-imgtxt-en h2,
//ARBIC
.propertyinsu-dlex-rgt-ar h2.pagetitle-ar, 
.projectinsu-dlex-lft-ar h2.pagetitle-ar, 
.terrpolirskinsu-dlex-lft-ar h2.pagetitle-ar,
.motorfleetinsu-dlex-lft-ar h2.pagetitle-ar,
.grphlthinsu-dlex-lft-ar h2.pagetitle-ar,
.grplifeinsu-dlex-lft-ar h2.pagetitle-ar,
h2.pagetitle-ar, 

h3.propertyinsu-page-sbu-title-ar, 
h3.projectinsu-page-sbu-title-ar, h3.projectinsu-whykrinsu-title-ar,
h3.terrpolirskinsu-page-sbu-title-ar, h3.terrpolirskinsu-whykrinsu-title-ar,
h3.motorfleetinsu-page-sbu-title-ar, h3.motorfleetinsu-subtitle-ar,
h3.motorfleetinsu-whykrinsu-mob-title-ar, h3.grphlthinsu-page-sbu-title-ar,
h3.grplifeinsu-page-sbu-title-ar, h3.grplifeinsu-whykrinsu-title-ar,
h3.fidelityguarinsu-page-sbu-title-ar, h3.fidelityguarinsu-whykrinsu-title-ar,
h3.burglaryinsu-page-sbu-title-ar, h3.burglaryinsu-whykrinsu-title-ar,
h3.wmencompinsu-page-sbu-title-ar, h3.wmencompinsu-whykrinsu-title-ar,
h3.wmencompinsu-subtitle-ar,
h3.grpacc-covinsu-page-sbu-title-ar, h3.grpacc-covinsu-whykrinsu-title-ar,
h3.cybercrimeinsu-page-sbu-title-ar, h3.cybercrimeinsu-whykrinsu-title-ar,
h3.jewellersblkinsu-page-sbu-title-ar, h3.jewellersblkinsu-whykrinsu-title-ar,
h3.stckcovinsu-page-sbu-title-ar, h3.stckcovinsu-whykrinsu-title-ar, h3.hauvalcarinsu-page-sbu-title-ar,
h3.splriskevtinsu-page-sbu-title-ar, h3.liabilityinsu-whykrinsu-title-ar,
h3.liabilityinsu-page-sbu-title-ar, h3.marineinsu-whykrinsu-title-ar, h3.marineinsu-page-sbu-title-ar,
h3.motorinsu-whykrinsu-title-ar, h3.travinsu-page-sbu-title-ar, h4.travinsu-keyfet-ar,
h3.travinsu-whykrinsu-title-ar, h3.personal-accinsu-page-sbu-title-ar, h3.personal-accinsu-whykrinsu-title-ar,
h3.healthinsu-page-sbu-title-ar, h3.healthinsu-whykrinsu-title-ar, h3.artinsu-page-sbu-title-ar, h3.golfinsu-page-sbu-title-ar,
h3.homemaidinsu-page-sbu-title-ar, h3.homemaidinsu-whykrinsu-title-ar, h3.yachtinsu-page-sbu-title-ar,
h3.yachtinsu-whykrinsu-title-ar, h3.homeinsu-whykrinsu-title-ar, h3.homeinsu-page-sbu-title-ar, 
h3.lifeinsu-page-sbu-title-ar, h3.whykrinsu-title-ar, h3.riskinspetserv-whykrinsu-title-ar, h3.insuraudits-whykrinsu-title-ar,
h3.valuservices-whykrinsu-title-ar, h3.claimhandinsu-whykrinsu-title-ar, h3.inhouautgar-whykrinsu-title-ar, 
h3.erpbasedsolu-whykrinsu-title-ar, h3.empwellpro-whykrinsu-title-ar, h3.empwellpro-page-sbu-title-ar, 
h3.splriskevtinsu-whykrinsu-title-ar, h3.relocationinsu-page-sbu-title-ar, h3.hauvalcarinsu-whykrinsu-title-ar
  {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  color: $vdblue;
}
.propertyinsu-dlex-lft-en p.pg-txt-con,
.projectinsu-dlex-lft-en, p.projectinsu-txt-con,
.terrpolirskinsu-dlex-lft-en p.pg-txt-con, p.terrpolirskinsu-txt-con, 
.motorfleetinsu-dlex-lft-en p.pg-txt-con, p.motorfleetinsu-txt-con, 
.grphlthinsu-dlex-lft-en p.grphlthinsu-txt-con, p.grphlthinsu-txt-con,
.grplifeinsu-dlex-lft-en p.grplifeinsu-txt-con, p.grplifeinsu-txt-con, 
.fidelityguarinsu-dlex-lft-en p.fidelityguarinsu-txt-con, p.fidelityguarinsu-txt-con, 
.burglaryinsu-dlex-lft-en p.burglaryinsu-pg-txt-con, p.burglaryinsu-txt-con, 
.wmencompinsu-dlex-lft-en p.pg-txt-con, p.wmencompinsu-txt-con, 
.grpacc-covinsu-dlex-lft-en p.pg-txt-con, p.grpacc-covinsu-txt-con, 
.cybercrimeinsudlex-lft-en p.cybercrimeinsu-txt-con, p.cybercrimeinsutxt-con, 
.jewellersblkinsu-dlex-lft-en p.pg-txt-con, p.jewellersblkinsu-txt-con, 
.stckcovinsu-dlex-lft-en p.pg-txt-con, p.stckcovinsu-txt-con, 
.hauvalcarinsu-dlex-lft-en p.pg-txt-con, p.hauvalcarinsu-txt-con, 
.splriskevtinsu-dlex-lft-en p.pg-txt-con, p.splriskevtinsu-txt-con, 
.liabilityinsu-dlex-lft-en p.pg-txt-con, p.liabilityinsu-txt-con, 
.marineinsu-dlex-lft-en p.pg-txt-con, p.marineinsu-txt-con, 
.energyinsu-dlex-lft-en p.energyinsu-txt-con, p.energyinsu-txt-con, 
.motorinsu-dlex-lft-en p.pg-txt-con, p.motorinsu-txt-con, 
.travinsu-dlex-lft-en p.pg-txt-con, 
.personal-accinsu-dlex-lft-en p.pg-txt-con, p.personal-accinsu-txt-con, 
.relocationinsu-dlex-lft-en p.pg-txt-con, 
.healthinsu-dlex-lft-en p.healthinsu-pg-txt-con, .healthinsu-keyfeatures-rgt-en, 
.artinsu-dlex-lft-en p.pg-txt-con, 
.golfinsu-dlex-lft-en p.pg-txt-con, 
.homemaidinsu-dlex-lft-en p.pg-txt-con, 
.yachtinsu-dlex-lft-en p.pg-txt-con, 
.homeinsu-dlex-lft-en p.pg-txt-con, p.homeinsu-txt-con, 
.lifeinsu-dlex-lft-en p.pg-txt-con, 
.riskinspetserv-dlex-lft-en p.riskinspetserv-pg-txt-con, 
.themogtestinsu-dlex-lft-en p.themogtestinsu-pg-txt-con, 
.valuservices-dlex-lft-en p.valuservices-pg-txt-con, 
.insuraudits-dlex-lft-en p.insuraudits-pg-txt-con, 
.servicexcell-dlex-lft-en p.servicexcell-pg-txt-con, 
.inhouautgar-dlex-lft-en p.inhouautgar-pg-txt-con, 
.erpbasedsolu-dlex-lft-en p.erpbasedsolu-pg-txt-con, 
.empwellpro-txt-con, .empwellpro-dlex-lft-en p.empwellpro-pg-txt-con,

// ARBIC 
.propertyinsu-dlex-rgt-ar p.pg-txt-con, .pagetitle-ar
{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: $black;
  text-align: justify;
}
.askquto-btn-sec {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 30px;
  @include for-phone-only {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  a.askquto-btn {
    flex: 0 auto;
    background: $vdblue;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    padding: 12px 30px;
    margin: 5px;
    cursor: pointer;
    border-radius: 4px;
  }
  a.askquto-btn:hover {
    background: #b10e1f;
    color: #fff;
    text-decoration: none;
  }
}

.btn-secondary {
  font-family: 'Poppins', sans-serif !important;
  background: #b10e1f !important;
  color: #fff !important;
  text-decoration: none;
  border: #b10e1f solid 1px !important;
}


.askquto-btn-sec-ar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 30px;
  @include for-phone-only {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  a.askquto-btn-ar {
    flex: 0 auto;
    background: $vdblue;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    text-decoration: none;
    padding: 12px 30px;
    margin: 5px;
    cursor: pointer;
    border-radius: 4px;
  }
  a.askquto-btn-ar:hover {
    background: #b10e1f;
    color: #fff;
    text-decoration: none;
  }
}
.loadermain {
  position: absolute;
  background: rgba(255,255,255, 1);
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 150;
  height: 100%;
  text-align: center;
  padding: 20% 0px 0px 50%;
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #00426a;
  border-bottom: 5px solid #ab2328;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

