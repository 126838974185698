@import '../css/themes.scss';
@import '../css/mixins.scss';
a {outline : none;}
.main-fluid .kr-footer-ar {
  // background: url("https://cdn.insuranceinbox.com/omanbr/footer_images/footer_bg2.png") no-repeat center top;
  background: url('../../public/footer_images/footer_bg2.webp')no-repeat center top;
}

.main-fluid .kr-footer-ar::after  {
  // background: url("https://cdn.insuranceinbox.com/omanbr/footer_images/footer_bg2.png") no-repeat 0px 50px;
  background: url("../../public/footer_images/footer_bg2.webp") no-repeat 0px 50px;
}
.kr-footer-ar .footer-container {
  margin: 0px auto;
  position: relative;
  max-width: 90%;
  min-width: 90%;
  padding-top: 200px;
  color: $white;
}
.ft-wdcon-ar {
  width: 1000px;
  margin: 0 auto;
}
.ft-con-sec-ar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  padding-top: 90px;
}
.ft-con-itemone-ar {
  flex: 0 auto;
  width: 33%;
}
.ft-con-itemone-ar p.desc-ar {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
}
.smedia-ar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row;
}
.smedia-ar a {
  flex: 0 auto;
  margin-right: 15px;
}
.smedia-ar a img {
  width: 23px;
}
.ft-con-itemtwo-ar {
  flex: 0 auto;
  width: 33%;
  padding-left: 7%;
}
.ft-con-itemtwo-ar .footer-links-ar {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
}
.footer-links-ar a {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  text-decoration: none;
  padding-bottom: 8px;
  outline: none;
}
.footer-links-ar a:hover {
  color: $white;
  text-decoration: underline;
  outline: none;
}
.ft-con-itemthree-ar {
  flex: 0 auto;
  width: 33%;
  padding-left: 7%;
}
.get-direction-ar {
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  color: $white;
  text-decoration: none;
}
.get-direction-ar:hover {
  color: $white;
  text-decoration: underline;
}
p.address-ar {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
}
p.address-ar a {
  color: $white;
  text-decoration: none;
}
p.address-ar a:hover {
  color: $white;
  text-decoration: underline;
}
p.copywrights-ar {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 20px;
  margin: 0px 0px 0px 0px;
}
.kr-footer-ar .title {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
}
.kr-footer-ar .km-address p.address {
  font-size: 13px;
}
.kr-footer-ar p.desc {
  font-size: 13px;
}

.kr-footer-ar svg{
margin-right: 10px;
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
  .ft-wdcon-ar {
    width: 94%;
    margin: 0 auto;
}
}

/* TAB FOOTER CSS STARTS HERE */
.kr-tabfooter-ar {
  background: url("https://cdn.insuranceinbox.com/omanbr/footer_images/footer_bg2.png") no-repeat center top;
  display: block;
  width: 100%;
  color: $white;
  margin-top: 40px;
}
.tabcw-ar {
  flex: 0 auto;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  text-align: center;
  padding: 10px 0px;
  margin: 0px 0px 0px 0px;
}
.tab-ftimg-ar {
  position: absolute;
  width: 100%;
  top: -60px;
  left: 0px;
}
.tab-ft-con-sec-ar {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  padding: 34px 20px 0px;
}
.tabft-con-itemone-ar {
  flex: 0 auto;
  width: 33%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  margin-top: 30px;
}
.tabmediaicon-ar {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
}
.tabmediaicon-ar a {
  margin: 5px 13px 0px 5px;
}
.tabftlinks-ar {
  flex: 0 auto;
  width: 33%;
  margin-top: 30px;
  padding-left: 40px;
}
.tabftlinks-ar a {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  text-decoration: none;
  margin-bottom: 10px;
  padding-left: 30px;
}
.tabftlinks-ar a:hover {
  color: $white;
  text-decoration: underline;
}
.ft-con-itemtwo-ar {
  flex: 0 auto;
  width: 33%;
  padding-left: 7%;
}
/* TAB FOOTER CSS ENDS HERE */


/* MOBILE FOOTER CSS STARTS HERE */
.kr-mbfooter-ar {
  background: #b10e1f;
  float: left;
  width: 100%;
  position: relative;
  margin-top: 80px;
}
.mob-ftimg-ar {
  position: absolute;
  width: 100%;
  top: -50px;
  left: 0px;
}
.mobft-mobwdcon-ar {
  width: 100%;
  margin: 0 auto;
}
.mobft-con-sec-ar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: row wrap;
  padding-top: 32px;
}
.mobft-con-itemone-ar {
  flex: 0 auto;
  width: 100%;
  font-family: 'Poppins', sans-serif;
    font-size: 13px;
    color: $white;
    padding: 0px 30px;
  text-align: center;
}
.mobsmedia-ar {
  flex: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin: 20px 0px;
}
.mobsmedia-ar a {
  flex: 0 auto;
  margin: 10px;
}
.mobft-lnk-add-ar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row wrap;
  margin: 0px 0px 0px 0p;
}
.mobftlnkadd-item-ar {
  flex: 0 auto;
  width: 48%;
  padding-left: 25px;
}
.mobftlnkadd-item-ar a {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: $white;
  text-decoration: none;
  margin-bottom: 10px;
}
.mobftlnkadd-item-ar a:hover {
  color: $white;
  text-decoration: underline;
}
.mobftlnkadd-additem-ar {
  flex: 0 auto;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: $white;
  text-align: center;
  margin-top: 20px;
}
.mobftlnkadd-additem-ar a {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: $white;
  text-decoration: none;
}
.mobftlnkadd-additem-ar a:hover {
  color: $white;
  text-decoration: underline;
}
a.mobget-direction-ar {
  display: block;
  width: 100%;
  margin-top: 10px;
  text-decoration: none;
}
a.mobget-direction-ar {
  color: $white;
  text-decoration: none;
}
a.mobget-direction-ar:hover {
  color: $white;
  text-decoration: underline;
}
a.mobget-direction-ar img {
  margin-right: 7px;
}
.mobcopywrights-ar {
  float: left;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}