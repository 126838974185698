@import '../css/themes.scss';
@import '../css/mixins.scss';

.kr-contact-en {
    float: left;
    width: 100%;
    padding-top: 120px;
    padding-bottom: 150px;
}
.contactdkp-en {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap;
    padding-bottom: 150px;
}
.contactdkp-lft-en {
    flex: 0 auto;
    width: 44%;
}
.contactform-en {
    display: block;
    width: 100%;
    height: 800px;
    margin: 30px 0px 0px 0px;
    position: relative;
    @media (min-width: 768px) and (max-width: 1024px) {
        margin: 0px 0px 0px 0px;
    }
    @media (max-width: 768px) {
        margin: 0px 0px 0px 0px;
    }
    #ff-compose-Mob {
        .ff-form .ff-item {
            padding-block: 0px !important;
        }
        .ff-form .ff-powered-img {
            @media only screen and (min-width:320px) and (max-width: 768px) {
                padding: 0px !important;
                width: 50% !important;
            }            
        }
        .ff-form .ff-form-check-other input[type=text] {
            width: 90% !important;
        }
        .ff-form {
            padding:0px;
            @media only screen and (min-width:320px) and (max-width: 768px) {
                margin-bottom: 85px !important;
            }
            input[type="text"],
            input[type="email"],
            input[type="tel"],
            input[type="url"],
            textarea {
                background-color: #ffffff !important;
                border: 1px solid #cdcdcd !important;
            }
            textarea:hover, textarea:focus,
            input[type="tel"]:hover, input[type="tel"]:focus,
            input[type="text"]:hover, input[type="text"]:focus, 
            input[type="email"]:hover, input[type="email"]:focus {
                background-color: #ffffff !important;
                border: 1px solid #cdcdcd !important;   
            }
            .ff-section h3.ff-title {
                margin-left: -10px !important;
            }
            .ff-item {
                padding-bottom: 0px !important;
            }
            .ff-section .ff-secfields .ff-item {
                .ff-widget-error {
                    display: flex !important;
                    justify-content: flex-start !important;
                    align-items: flex-start !important;
                    color: #ab2328 !important;
                    padding: 5px 0px 0px 0px !important;
                    b {
                        flex: 0 auto;
                        margin-top: 3px;
                        width: 20px !important;
                        height: 20px !important;
                        background-color: #ab2328 !important;
                    }
                }
            }
        }
    }
    #ff-compose-Tab {
        .ff-form .ff-powered-img {
            @media only screen and (min-width:320px) and (max-width: 768px) {
                padding: 0px !important;
                width: 50% !important;
            }            
        }
        .ff-form {
            padding:0px;
            @media only screen and (min-width:320px) and (max-width: 768px) {
                margin-bottom: 85px !important;
            }
            input[type="text"],
            input[type="email"],
            input[type="tel"],
            input[type="url"],
            textarea {
                background-color: #ffffff !important;
                border: 1px solid #cdcdcd !important;
            }
            textarea:hover, textarea:focus,
            input[type="tel"]:hover, input[type="tel"]:focus,
            input[type="text"]:hover, input[type="text"]:focus, 
            input[type="email"]:hover, input[type="email"]:focus {
                background-color: #ffffff !important;
                border: 1px solid #cdcdcd !important;   
            }
            .ff-section h3.ff-title {
                margin-left: -10px !important;
            }
            .ff-item {
                padding-bottom: 0px !important;
            }
            .ff-section .ff-secfields .ff-item {
                .ff-widget-error {
                    display: flex !important;
                    justify-content: flex-start !important;
                    align-items: flex-start !important;
                    color: #ab2328 !important;
                    padding: 5px 0px 0px 0px !important;
                    b {
                        flex: 0 auto;
                        margin-top: 3px;
                        width: 20px !important;
                        height: 20px !important;
                        background-color: #ab2328 !important;
                    }
                }
            }
        }
    }
    #ff-compose{
        width:100%;
        height:100%;
        margin:auto;
        background-color: #ffffff;
        border-radius: 10px;
        box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
        -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
        -moz-box-shadow: -2px 0px 13px -1px rgba(0, 0, 0, 0.21);
        padding: 20px 30px;
        .ff-partial {
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 8px;
            padding: 0.75rem .50rem !important;
            border: 1px solid transparent;
            border-radius: 0.25rem;
            font-size: 14px !important;
            color: #B11D28 !important;
            background-color: rgba(177,29,40, 0.15) !important;
            border-color: rgba(177,29,40, 0.15) !important;
            span a {
                font-size: 14px !important;
                text-decoration: none !important;
                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
        .ff-form {
            padding:0px;
            @media only screen and (min-width:320px) and (max-width: 768px) {
                margin-bottom: 85px !important;
            }
            input[type="text"],
            input[type="email"],
            input[type="tel"],
            input[type="url"],
            textarea {
                background-color: #ffffff !important;
                border: 1px solid #cdcdcd !important;
            }
            textarea:hover, textarea:focus,
            input[type="tel"]:hover, input[type="tel"]:focus,
            input[type="text"]:hover, input[type="text"]:focus, 
            input[type="email"]:hover, input[type="email"]:focus {
                background-color: #ffffff !important;
                border: 1px solid #cdcdcd !important;   
            }
            .ff-section h3.ff-title {
                margin-left: -10px !important;
            }
            .ff-item {
                padding-bottom: 0px !important;
            }
            .ff-section .ff-secfields .ff-item {
                .ff-widget-error {
                    display: flex !important;
                    justify-content: flex-start !important;
                    align-items: flex-start !important;
                    color: #ab2328 !important;
                    padding: 5px 0px 0px 0px !important;
                    b {
                        flex: 0 auto;
                        margin-top: 3px;
                        width: 20px !important;
                        height: 20px !important;
                        background-color: #ab2328 !important;
                    }
                }
            }
        }
    }
}
.contactform-en iframe {
    position: absolute;
    left: -30px;
    @media screen and (max-width:767px) {
        left: 0px;
        z-index: inherit;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        left: 0px;
    }
}
p.contactfrom-msg-en {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
    padding: 0px 0px 0px 0px;
    margin: 0px;
}
.contactform-en .teQAzf {
    margin: 0px;
    padding: 0px;
    max-width: 82vw !important;
}
.RVEQke {
    background-color: rgb(0,66,106) !important;
    color: rgba(255, 255, 255, 1);
}
.contact-gmap-en {
    display: block;
    width: 100%;
    margin: 50px 0px 0px 0px;
}
p.contact-gmap-msg-en {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
    padding: 0px 0px 15px 0px;
    margin: 0px;
}
.contactadd-en {
    display: block;
    width: 100%;
    margin: 20px 0px 0px 0px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
    padding: 20px;
}
.contactadd-en h5.office-title-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.contactadd-en h5.office-title-en span.offitite-en {
    flex: 0 auto;
    font-size: 24px;
    font-weight: 600;
    color: $vdblue;
    padding-left: 20px;
}
.contactadd-en h5.office-title-en span.offiimg-en {
    flex: 0 auto;
}
.contactadd-en h5.office-title-en span.offiimg-en img {
    width: 25px;
}
.contactadd-en h5.office-title-en span.postalitite-en {
    flex: 0 auto;
    font-size: 24px;
    font-weight: 600;
    color: $vdblue;
    padding-left: 0px;
}
.office-add-en {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: $black;
    margin: 10px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.contactcall-email-en {
    display: block;
    width: 100%;
    background-color: $white;
    border-radius: 10px;
    box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
    padding: 20px;
    margin: 30px 0px 0px 0px;
}
.contactcall-email-en h5.callemail-title-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.contactcall-email-en h5.callemail-title-en span.calltite-en {
    flex: 0 auto;
    font-size: 24px;
    font-weight: 600;
    color: $vdblue;
    padding-left: 20px;
}
.contactcall-email-en h5.callemail-title-en span.callimg-en {
    flex: 0 auto;
}
.contactcall-email-en h5.callemail-title-en span.callimg-en img {
    width: 25px;
}
.contact-whrs-en {
    display: block;
    width: 100%;
    background-color: $white;
    border-radius: 10px;
    box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
    padding: 20px;
    margin: 30px 0px 0px 0px;
}
.contact-whrs-en h5.whrs-title-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.contact-whrs-en h5.whrs-title-en span.wkhrstite-en {
    flex: 0 auto;
    font-size: 24px;
    font-weight: 600;
    color: $vdblue;
    padding-left: 20px;
}
.contact-whrs-en h5.whrs-title-en span.whrsimg-en {
    flex: 0 auto;
}
.contact-whrs-en h5.whrs-title-en span.whrsimg-en img {
    width: 25px;
}
.contactwhrs-time-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
}
.contactwhrs-tmitem-en {
    flex: 0 auto;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: $black;
}
.contactcall-email-sec-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 10px;
}
.contactcallemail-secitem-en {
    flex: 0 auto;
    width: 34%;
    font-size: 18px;
    font-weight: 400;
    color: $black;
    margin-bottom: 5px;
    text-decoration: none;
    &:hover {
        color: $black;
        text-decoration: underline;
    }
}
.contactcall-email-en h5.email-title-en {
    display: block;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    color: $vdblue;
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
}
a.emlink {
    display: block;
    width: 100%;
    margin: 10px 0px 0px 0px;
    padding: 0px 0px 0px;
    font-size: 18px;
    font-weight: 400;
    color: $black;
    text-decoration: none;
}
a.emlink:hover {
    color: $black;
    text-decoration: underline;
}
.contactdkp-lft-title-en {
    font-size: 20px;
    font-weight: 500;
    color: $vdblue;
    line-height: 25px;
    margin: 0px;
    padding: 0px;
}
.contactdkp-rgt-en {
    flex: 0 auto;
    width: 50%;
}
.contactdkp-rgt-title-en {
    font-size: 20px;
    font-weight: 500;
    color: $vdblue;
    line-height: 25px;
    margin: 0px;
    padding: 0px;
}
.add-sec-en {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow:  row wrap;
    width: 100%;
    margin: 20px auto;
}
.calls-en {
    flex: 0 auto;
    width: 30%;
    background-color: $white;
    border-radius: 10px;
    box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
    padding: 20px;
}
.calls-inctitle-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.call-incimg-en {
    flex: 0 auto;
}
.call-inctxt-en {
    flex: 0 auto;
    font-size: 18px;
    font-weight: 500;
    color: $vdblue;
    padding-left: 5px;
    text-transform: uppercase;
}
.callnum-sec-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 15px;
}
.calnum-secitem-en {
    flex: 0 auto;
    width: 50%;
    font-size: 18px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 5px;
}
.location-en {
    flex: 0 auto;
    width: 30%;
    background-color: $white;
    border-radius: 10px;
    box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
    padding: 20px;
}
.locationinfo-sec-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 15px;
}
.locationinfo-secitem-en {
    flex: 0 auto;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 10px;
}
.locationifo-scitem-en span p {
    margin: 0px;
    padding: 0px;
}
.hrs-en {
    flex: 0 auto;
    width: 30%;
    background-color: $white;
    border-radius: 10px;
    box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
    padding: 20px;
}
.hrsinfo-sec-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 15px;
}
.hrsinfo-secitem-en {
    flex: 0 auto;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 5px;
}
.cont-bdy-en {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    margin-bottom: 111px;
    padding-top: 55px;
}

.cont-add-en {
    flex: 0 auto;
    width: 48%;

}
.c-add-en {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
}
.c-add-en h2 {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    color: $vdblue;
    font-weight: 600;
    margin-bottom: 40px;
}
.c-add-en h5 {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: $vdblue;
    margin-bottom: 20px;
}
.c-add-en p {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: $vdblue;
}
.c-add-en p a {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: $vdblue;
    text-decoration: none;
}
.c-add-en p a:hover {
    color: $mred;
    text-decoration: underline;
}
.cont-mapsec-en {
    flex: 0 auto;
    width: 52%;
    padding-top: 25px;
}
@media screen and (min-width: 1400px) and (max-width:1920px) {
    .cont-mapsec-en {
        padding-top: 30px;
    }
}
@media screen and (max-width: 767px) {
    .cont-bdy-en {
    flex-flow: column;
    margin-bottom: 30px;
}
.c-add-en {
    padding-top: 40px;
}
.c-add-en h5 {
    font-weight: 600;
    margin-bottom: 10px;
}
.c-add-en h2 {
    margin-bottom: 30px;
}
    .cont-add-en {
        flex: 0 auto;
        margin-bottom: 20px;
        width: 100%;
    }

.c-add-en p {
    margin-bottom: 8px;
}

    .cont-mapsec {
        width: 100% !important;
    }


.cont-mapsec-en {
    flex: 0 auto;
    width: 100%;
    text-align: center;
}
}


@media screen and (min-width: 1400px) and (max-width: 1920px) {
.cont-bdy-en {
    padding-top: 60px;
}
}

.mcWRN .teQAzf {
    margin: 0px !important;
    padding: 0px !important;
}
.teQAzf {
    padding: 0px !important;
    margin: 0px !important;
}

/* CONTACT MOBILE PAGE CSS STARTS HERE */
.mobcontact-en {
    display: block;
    width: 100%;
    padding-bottom: 50px;
}
.mobcontact-title-en {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: $vdblue;
    line-height: 30px;
    text-align: center;
}
.mobcontact-msg-en {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
    text-align: center;
    padding: 10px 0px 0px 0px;
    margin: 0px;
}
.mobcontactadd-en {
    width: 95%;
    margin: 20px auto;
    background-color: $white;
    border-radius: 10px;
    box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
    padding: 20px;
}
.mobcontactadd-en h5.moboffice-title-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.mobcontactadd-en h5.moboffice-title-en span.moboffitite-en {
    flex: 0 auto;
    font-size: 24px;
    font-weight: 600;
    color: $vdblue;
    padding-left: 20px;
}
.mobcontactadd-en h5.moboffice-title-en span.moboffiimg-en {
    flex: 0 auto;
}
.mobcontactadd-en h5.moboffice-title-en span.moboffiimg-en img {
    width: 25px;
}
.mobcontactadd-en h5.moboffice-title-en span.mobpostalitite-en {
    flex: 0 auto;
    font-size: 24px;
    font-weight: 600;
    color: $vdblue;
    padding-left: 0px;
}
.mobcontactcall-email-en {
    width: 95%;
    margin: 20px auto;
    background-color: $white;
    border-radius: 10px;
    box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
    padding: 20px;
}
.mobcontactcall-email-en h5.mobcallemail-title-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.mobcontactcall-email-en h5.mobcallemail-title-en span.mobcalltite-en {
    flex: 0 auto;
    font-size: 24px;
    font-weight: 600;
    color: $vdblue;
    padding-left: 20px;
}
.mobcontactcall-email-en h5.mobcallemail-title-en span.mobcallimg-en {
    flex: 0 auto;
}
.mobcontactcall-email-en h5.mobcallemail-title-en span.mobcallimg-en img {
    width: 25px;
}
.mobcontactcall-email-sec-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 20px;
}
.mobcontactcallemail-secitem-en {
    flex: 0 auto;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: $black;
    margin-bottom: 5px;
    text-decoration: none;
    &:hover {
        color: $black;
        text-decoration: underline;
    }
}
.mobcontactcall-email-en h5.mobemail-title-en {
    display: block;
    width: 100%;    
    font-size: 24px;
    font-weight: 600;
    color: $vdblue;
    margin: 0px 0px 0px 0px;
    padding: 20px 0px 0px 0px;
}
a.mobemlink {
    display: block;
    width: 100%;
    margin: 10px 0px 0px 0px;
    padding: 0px 0px 0px;
    font-size: 18px;
    font-weight: 400;
    color: $black;
    text-decoration: none;
}
a.mobemlink:hover {
    color: $black;
    text-decoration: underline;
}
.mobcontact-whrs-en {
    width: 95%;
    margin: 20px auto;
    background-color: $white;
    border-radius: 10px;
    box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
    padding: 20px;
}
.mobcontact-whrs-en h5.mobwhrs-title-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}
.mobcontact-whrs-en h5.mobwhrs-title-en span.mobwkhrstite-en {
    flex: 0 auto;
    font-size: 24px;
    font-weight: 600;
    color: $vdblue;
    padding-left: 20px;
}
.mobcontact-whrs-en h5.mobwhrs-title-en span.mobwhrsimg-en {
    flex: 0 auto;
}
.mobcontact-whrs-en h5.mobwhrs-title-en span.mobwhrsimg-en img {
    width: 25px;
}
.mobcontactwhrs-time-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
}
.mobcontactwhrs-tmitem-en {
    flex: 0 auto;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: $black;
}
.mobgmap-title-en {
    display: block;
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    color: $vdblue;
    line-height: 30px;
    text-align: center;
    margin-top: 30px;
}
.mobgmap-msg-en {
    display: block;
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    color: rgba(0,0,0,0.5);
    text-align: center;
    padding: 10px 0px 0px 0px;
    margin: 0px;
}
.mobcontact-gmap-en {
    display: block;
    width: 100%;
    margin: 20px 0px 0px 0px;
}
/* CONTACT MOBILE PAGE CSS ENDS HERE */
/* Media Query for Mobile Devices */
@media (max-width: 480px) {
.hidden-mobile {
    display: none;
}
.dsk-view {
    display: none;
}
.tab-view {
    display: none;
}
.mobile-view {
    display: block;
}
.kr-contact-en {
    padding-top: 96px;
    padding-bottom: 0px;
}
.mobcontact-en {
    display: block;
    width: 100%;
    padding-bottom: 0px;
}

}
/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
.hidden-lowtab {
    display: none;
}
.dsk-view {
    display: none;
}
.tab-view {
    display: none;
}
.mobile-view {
    display: block;
}
    .kr-contact-en {
        padding-bottom: 0px;
    }
}
  
/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
.hidden-portrait-mode-tab {
    display: none;
}
.dsk-view {
    display: none;
}
.tab-view {
    display: block;
}
.mobile-view {
    display: none;
}
.kr-contact-en {
    padding-bottom: 0px;
}

}
  
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px){
.hidden-laptop-desktops {
    display: none;
}
.dsk-view {
    display: block;
}
.tab-view {
    display: none;
}
.mobile-view {
    display: none;
}

}
  
/* Media Query for Large screens */
@media (min-width: 1281px) {
.hidden-largscreen {
    display: none;
}
.dsk-view {
    display: block;
}
.tab-view {
    display: none;
}
.mobile-view {
    display: none;
}

}

.ff-form .ff-item textarea {
    resize: none !important;
}