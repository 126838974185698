@import '../../css/themes.scss';
@import '../../css/mixins.scss';

.insuraudits-tab-imagebannermain-ar {
    float: left;
    width: 100%;
    background: url(https://cdn.insuranceinbox.com/krImages/products_tabs_bg_01.jpg) no-repeat top center;
    padding: 5% 0px 0px 0px;
    height: 413px;
    background-size: cover;
    background-position: top;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .insuraudits-tab-imagebannermain-ar {
        float: left;
        width: 100%;
        background: $white;
        padding: 80px 10px 0px;
        margin-top: 15px;
        height: auto;
    }

    .insuraudits-tab-imagebannermain-ar h1.insuraudits-banner-heading-ar {
        display: block;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-weight: bold;
        color: $vdblue;
        text-align: center;
    }
}

.insuraudits-banner-con-ar {
    width: 1200px;
    margin: 0px auto;
}

.insuraudits-bdy-ar {
    float: left;
    width: 100%;
    margin-bottom: 100px;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .insuraudits-banner-con-ar {
        width: 100%;
        margin: 0px auto;
    }
}
/* DESKTOP BANNER BUTTONS CSS STARTS HERE */
.insuraudits-tab-btn-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row-reverse wrap;
    margin-top: 20px;
    @include for-tablet-portrait-up {
        margin-top: 10px;
    }
    .insuraudits-tab-btn-item-ar {
        flex: 0 auto;
        background: $white;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: $vdblue;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
    .insuraudits-tab-btn-item-active-ar {
        flex: 0 auto;
        background: $vdblue !important;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
}
/* DESKTOP BANNER BUTTONS CSS ENDS HERE */
.insurauditstab-banne-sec-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}
.insurauditstab-banne-sec-ar h1.insuraudits-banner-heading-ar {
    flex: 0 auto;
    text-align: center;
    padding-top: 100px;
    width: 100%;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .insurauditstab-banne-sec-ar h1 {
        flex: 0 auto;
        font-size: 22px;
        font-weight: bold;
        color: $mred;
        padding-top: 40px;
    }
}

@media screen and (max-width: 650px) {}

@media screen and (max-width: 400px) {}

/* RESPONSIVE CSS STARTS HERE  */

@media all and (min-width:320px) and (max-width: 767px) {}

/* TAB CSS STARTS HERE Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
    .insuraudits-tab-imagebannermain-ar {
        width: 100%;
    }

    .insuraudits-banner-con-ar {
        width: 98%;
        margin: 0px auto;
        padding: 20px;
    }
}

/* TAB CSS ENDS HERE Medium devices (landscape tablets, 768px and up) */
.insuraudits-dflex-en {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row;
    width: 100%;
    padding-top: 15px;
}
.insuraudits-dlex-lft-ar {
    flex: 0 auto;
    width: 600px;
    padding-left: 50px;
}
.insuraudits-dlex-lft-ar h2.pagetitle-ar {
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.insuraudits-dlex-lft-ar p.insuraudits-pg-txt-con-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 0px 0px 1rem 0px;
    padding: 0px 0px 0px 0px;
}
h3.insuraudits-whykrinsu-title-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}

h3.insuraudits-page-sbu-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    text-align: right;
    margin: 0px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.insuraudits-pins-list-lftdksp-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
}

.insuraudits-pins-list-lftdksp-en .insuraudits-lt-one-lftdksp-en,
.insuraudits-pins-list-lftdksp-en .insuraudits-lt-two-lftdksp-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $vdblue;
    text-align: right;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.insuraudits-pins-list-lftdksp-en .insuraudits-lt-one-lftdksp-en img,
.insuraudits-pins-list-lftdksp-en .insuraudits-lt-two-lftdksp-en img {
    width: 15px;
    margin-left: 10px;
}
.insuraudits-pins-list-lftdksp-en .insuraudits-lt-one-lftdksp-en {
    width: 45%;
}
.insuraudits-pins-list-lftdksp-en .insuraudits-lt-two-lftdksp-en {
    width: 55%;
}
.insuraudits-pins-list-dksp-ar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
}
.insuraudits-pins-list-dksp-ar .insuraudits-lt-one-dksp-ar,
.insuraudits-pins-list-dksp-ar .insuraudits-lt-two-dksp-ar {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    text-align: right;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.insuraudits-pins-list-dksp-ar .insuraudits-lt-one-dksp-ar img,
.insuraudits-pins-list-dksp-ar .insuraudits-lt-two-dksp-ar img {
    width: 15px;
    margin-left: 10px;
}
.insuraudits-pins-list-dksp-ar .insuraudits-lt-one-dksp-ar {
    width: 25%;
}
.insuraudits-pins-list-dksp-ar .insuraudits-lt-two-dksp-ar {
    width: 75%;
}
.insuraudits-pins-list-dsk-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
}
.insuraudits-pins-list-dsk-itemimg-en {
    flex: 0 auto;
    width: 2%;
    margin-bottom: 15px;
}
.insuraudits-pins-list-dsk-itemimg-en img {
    width: 15px;
}
.insuraudits-pins-list-dsk-item-en {
    flex: 0 auto;
    width: 97%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin-bottom: 15px;
}
.insuraudits-pins-list-ar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    margin: 20px 0px;
}

.insuraudits-pins-list-ar .insuraudits-lt-one-en,
.insuraudits-pins-list-ar .insuraudits-lt-two-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $vdblue;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.insuraudits-pins-list-ar .insuraudits-lt-one-en img,
.insuraudits-pins-list-ar .insuraudits-lt-two-en img {
    width: 15px;
    margin-right: 10px;
}

.insuraudits-pins-list-ar .insuraudits-lt-one-en {
    width: 46%;
}

.insuraudits-pins-list-ar .insuraudits-lt-two-en {
    width: 54%;
}

.insuraudits-dlex-rgt-ar {
    flex: 0 auto;
    width: 600px;
}

.insuraudits-dlex-rgt-ar img {
    width: 100%;
}

p.insuraudits-txt-con {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    flex: 0 auto;
    width: 100%;
}

h4.insuraudits-keyfet-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: $vdblue;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}

.insuraudits-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}

.insuraudits-keyfeatures-q-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px;
    padding: 0px;
}

.insuraudits-keyfeatures-q-en img {
    width: 15px;
    margin-right: 3px;
    margin-top: -3px;
}

.insuraudits-keyfeatures-ans-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 25px 23px;
    padding: 0px;
}

.insuraudits-keypoint-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}

.insuraudits-keypoint-itemimg-en {
    flex: 0 auto;
    width: 3%;
    margin-bottom: 15px;
}

.insuraudits-keypoint-itemimg-en img {
    width: 15px;
    margin-top: -2px;
}

.insuraudits-keypoint-item-en {
    flex: 0 auto;
    width: 96%;
    margin-left: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 15px;
}

.insuraudits-kpnt-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}

.insuraudits-kpnt-itemimg-en {
    flex: 0 auto;
    width: 2%;
    margin-bottom: 15px;
}

.insuraudits-kpnt-itemimg-en img {
    width: 15px;
    margin-top: 4px;
}

.insuraudits-kpnt-item-en {
    flex: 0 auto;
    width: 97%;
    margin-left: 0px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 15px;
}


/* MOBILE SECTION CSS STARTS HERE */
.insuraudits-mob-main-en {
    float: left;
    width: 100%;
    padding: 72px 15px 20px;
    margin: 0px 0px 0px 0px;
}

.insuraudits-mob-main-en h2.insuraudits-mob-main-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 20px;
    padding: 0px;
}

.insuraudits-mob-img-en {
    width: 100%;
    display: block;
    margin: 20px 0px;
}

.insuraudits-mob-img-en img {
    width: 100%;
}

.insuraudits-mob-txt-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: justify;
    margin: 0px 0px 20px;
    padding: 0px;
}

h3.insuraudits-whykrinsu-mob-title-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 23px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}

.insuraudits-pins-list-mob-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
}

.insuraudits-pins-list-mob-itemimg-en {
    flex: 0 auto;
    width: 3%;
    margin-bottom: 15px;
    padding-top: 3px;
}

.insuraudits-pins-list-mob-itemimg-en img {
    width: 22px;
}

.insuraudits-pins-list-mob-item-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin-left: 20px;
    margin-bottom: 15px;
}

.insuraudits-mob-whykrlist-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    .insuraudits-mob-whykrlist-item-ar {
        flex: 0 auto;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: $black;
        text-align: right;
        margin-bottom: 15px;
        img {
            width: 21px;
            margin-left: 10px;
        }
    }
}
h3.insuraudits-subtitle-en,
h4.insuraudits-subtitle-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px;
}

.insuraudits-mob-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}

.insuraudits-mob-keyfeatures-img-en {
    flex: 0 auto;
    width: 3%;
}

.insuraudits-mob-keyfeatures-img-en img {
    width: 20px;
    padding-top: 5px
}

.insuraudits-mob-keyfeatures-q-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 20px 20px;
    padding: 0px;
}

.insuraudits-mob-keyfeatures-ans-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: justify;
    margin: 10px 10px 20px 8%;
    padding: 0px;
}

.dropdown-sec {
    background: #F7F7F7;
    padding: 30px 20px 20px 20px;
    position: fixed;
    text-align: center;
    left: 0px;
    right: 0px;
    top: 65px;
    border-bottom: #ECECEC solid 1px;
}

.form-select {
    font-family: 'Poppins', sans-serif;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: $vdblue !important;
    height: 45px !important;

}

.form-select option {
    margin: 10px 0px !important;
    font-size: 13px !important;
    color: #000 !important;
}

/* MOBILE SECTION CSS STARTS HERE */