@import '../../css/themes.scss';
@import '../../css/mixins.scss';

.inhouautgar-tab-imagebannermain-ar {
    float: left;
    width: 100%;
    background: url(https://cdn.insuranceinbox.com/krImages/products_tabs_bg_01.jpg) no-repeat top center;
    padding: 5% 0px 0px 0px;
    height: 413px;
    background-size: cover;
    background-position: top;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .inhouautgar-tab-imagebannermain-ar {
        float: left;
        width: 100%;
        background: $white;
        padding: 80px 10px 0px;
        margin-top: 15px;
        height: auto;
    }

    .inhouautgar-tab-imagebannermain-ar h1.inhouautgar-banner-heading-ar {
        display: block;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-weight: bold;
        color: $vdblue;
        text-align: center;
    }
}

.inhouautgar-banner-con-ar {
    width: 1200px;
    margin: 0px auto;
}

.inhouautgar-bdy-ar {
    float: left;
    width: 100%;
    margin-bottom: 100px;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .inhouautgar-banner-con-ar {
        width: 100%;
        margin: 0px auto;
    }
}

/* DESKTOP BANNER BUTTONS CSS STARTS HERE */
.inhouautgar-tab-btn-en {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    margin-top: 30px;
}

.inhouautgar-tab-btn-item-en {
    flex: 0 auto;
    background: $white;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: $vdblue;
    padding: 8px 35px;
    margin: 5px;
    cursor: pointer;
    border-radius: 4px;
}

.inhouautgar-tab-btn-item-active-en {
    flex: 0 auto;
    background: #b10e1f !important;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #fff;
    padding: 8px 35px;
    margin: 5px;
    cursor: pointer;
    border-radius: 4px;
}

/* DESKTOP BANNER BUTTONS CSS ENDS HERE */
.inhouautgartab-banne-sec-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}
.inhouautgartab-banne-sec-ar h1.inhouautgar-banner-heading-ar {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    padding-top: 100px;
    width: 100%;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .inhouautgartab-banne-sec-ar h1 {
        flex: 0 auto;
        font-size: 22px;
        font-weight: bold;
        color: $mred;
        padding-top: 40px;
    }
}

@media screen and (max-width: 650px) {}

@media screen and (max-width: 400px) {}

/* RESPONSIVE CSS STARTS HERE  */

@media all and (min-width:320px) and (max-width: 767px) {}

/* TAB CSS STARTS HERE Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
    .inhouautgar-tab-imagebannermain-ar {
        width: 100%;
    }

    .inhouautgar-banner-con-ar {
        width: 98%;
        margin: 0px auto;
        padding: 20px;
    }
}

/* TAB CSS ENDS HERE Medium devices (landscape tablets, 768px and up) */

.inhouautgar-dflex-ar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row;
    width: 100%;
    padding-top: 15px;
}
.inhouautgar-dlex-lft-ar {
    flex: 0 auto;
    width: 600px;
    padding-left: 50px;
}
.inhouautgar-dlex-lft-ar h2.pagetitle-ar {
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}

.inhouautgar-dlex-lft-ar p.inhouautgar-pg-txt-con-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 0px 0px 1rem 0px;
    padding: 0px 0px 0px 0px;
}
h3.inhouautgar-whykrinsu-title-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 0px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}

h3.inhouautgar-page-sbu-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.inhouautgar-pins-list-lftdksp-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
}

.inhouautgar-pins-list-lftdksp-en .inhouautgar-lt-one-lftdksp-en,
.inhouautgar-pins-list-lftdksp-en .inhouautgar-lt-two-lftdksp-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $vdblue;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.inhouautgar-pins-list-lftdksp-en .inhouautgar-lt-one-lftdksp-en img,
.inhouautgar-pins-list-lftdksp-en .inhouautgar-lt-two-lftdksp-en img {
    width: 15px;
    margin-right: 10px;
}

.inhouautgar-pins-list-lftdksp-en .inhouautgar-lt-one-lftdksp-en {
    width: 45%;
}

.inhouautgar-pins-list-lftdksp-en .inhouautgar-lt-two-lftdksp-en {
    width: 55%;
}

.inhouautgar-pins-list-dksp-ar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
}

.inhouautgar-pins-list-dksp-ar .inhouautgar-lt-one-dksp-ar,
.inhouautgar-pins-list-dksp-ar .inhouautgar-lt-two-dksp-ar {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    text-align: right;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.inhouautgar-pins-list-dksp-ar .inhouautgar-lt-one-dksp-ar img,
.inhouautgar-pins-list-dksp-ar .inhouautgar-lt-two-dksp-ar img {
    width: 15px;
    margin-left: 10px;
}
.inhouautgar-pins-list-dksp-ar .inhouautgar-lt-one-dksp-ar {
    width: 20%;
}
.inhouautgar-pins-list-dksp-ar .inhouautgar-lt-two-dksp-ar {
    width: 80%;
}
.inhouautgar-pins-list-dsk-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
}
.inhouautgar-pins-list-dsk-itemimg-en {
    flex: 0 auto;
    width: 2%;
    margin-bottom: 15px;
}
.inhouautgar-pins-list-dsk-itemimg-en img {
    width: 15px;
}
.inhouautgar-pins-list-dsk-item-en {
    flex: 0 auto;
    width: 97%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 15px;
}
.inhouautgar-pins-list-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    margin: 20px 0px;
}
.inhouautgar-pins-list-en .inhouautgar-lt-one-en,
.inhouautgar-pins-list-en .inhouautgar-lt-two-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $vdblue;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.inhouautgar-pins-list-en .inhouautgar-lt-one-en img,
.inhouautgar-pins-list-en .inhouautgar-lt-two-en img {
    width: 15px;
    margin-right: 10px;
}
.inhouautgar-pins-list-en .inhouautgar-lt-one-en {
    width: 46%;
}
.inhouautgar-pins-list-en .inhouautgar-lt-two-en {
    width: 54%;
}
.inhouautgar-dlex-rgt-ar {
    flex: 0 auto;
    width: 600px;
}
.inhouautgar-dlex-rgt-ar img {
    width: 100%;
}
p.inhouautgar-txt-con-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: right;
    flex: 0 auto;
    width: 100%;
}

h4.inhouautgar-keyfet-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: $vdblue;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}

.inhouautgar-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}

.inhouautgar-keyfeatures-q-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px;
    padding: 0px;
}

.inhouautgar-keyfeatures-q-en img {
    width: 15px;
    margin-right: 3px;
    margin-top: -3px;
}

.inhouautgar-keyfeatures-ans-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 25px 23px;
    padding: 0px;
}

.inhouautgar-keypoint-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}

.inhouautgar-keypoint-itemimg-en {
    flex: 0 auto;
    width: 3%;
    margin-bottom: 15px;
}

.inhouautgar-keypoint-itemimg-en img {
    width: 15px;
    margin-top: -2px;
}

.inhouautgar-keypoint-item-en {
    flex: 0 auto;
    width: 96%;
    margin-left: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 15px;
}

.inhouautgar-kpnt-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}

.inhouautgar-kpnt-itemimg-en {
    flex: 0 auto;
    width: 2%;
    margin-bottom: 15px;
}

.inhouautgar-kpnt-itemimg-en img {
    width: 15px;
    margin-top: 4px;
}

.inhouautgar-kpnt-item-en {
    flex: 0 auto;
    width: 97%;
    margin-left: 0px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 15px;
}


/* MOBILE SECTION CSS STARTS HERE */
.inhouautgar-mob-main-ar {
    float: left;
    width: 100%;
    padding: 72px 15px 20px;
    margin: 0px 0px 0px 0px;
}

.inhouautgar-mob-main-ar h2.inhouautgar-mob-main-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 20px;
    padding: 0px;
}

.inhouautgar-mob-img-en {
    width: 100%;
    display: block;
    margin: 20px 0px;
}

.inhouautgar-mob-img-en img {
    width: 100%;
}

.inhouautgar-mob-txt-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: right;
    margin: 0px 0px 20px;
    padding: 0px;
}

h3.inhouautgar-whykrinsu-mob-title-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    text-align: right;
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}

.inhouautgar-pins-list-mob-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
}

.inhouautgar-pins-list-mob-itemimg-en {
    flex: 0 auto;
    width: 3%;
    margin-bottom: 15px;
    padding-top: 3px;
}

.inhouautgar-pins-list-mob-itemimg-en img {
    width: 22px;
}

.inhouautgar-pins-list-mob-item-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin-left: 20px;
    margin-bottom: 15px;
}

.inhouautgar-mob-whykrlist-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    .inhouautgar-mob-whykrlist-item-ar {
        flex: 0 auto;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: $black;
        text-align: right;
        margin-bottom: 15px;
        img {
            width: 21px;
            margin-left: 10px;
        }
    }
}

h3.inhouautgar-subtitle-en,
h4.inhouautgar-subtitle-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px;
}

.inhouautgar-mob-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}

.inhouautgar-mob-keyfeatures-img-en {
    flex: 0 auto;
    width: 3%;
}

.inhouautgar-mob-keyfeatures-img-en img {
    width: 20px;
    padding-top: 5px
}

.inhouautgar-mob-keyfeatures-q-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 20px 20px;
    padding: 0px;
}

.inhouautgar-mob-keyfeatures-ans-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: justify;
    margin: 10px 10px 20px 8%;
    padding: 0px;
}

.dropdown-sec {
    background: #F7F7F7;
    padding: 30px 20px 20px 20px;
    position: fixed;
    text-align: center;
    left: 0px;
    right: 0px;
    top: 65px;
    border-bottom: #ECECEC solid 1px;
}

.form-select {
    font-family: 'Poppins', sans-serif;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: $vdblue !important;
    height: 45px !important;

}

.form-select option {
    margin: 10px 0px !important;
    font-size: 13px !important;
    color: #000 !important;
}

/* MOBILE SECTION CSS STARTS HERE */