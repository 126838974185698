@import '../css/themes.scss';
@import '../css/mixins.scss';
a {outline : none;}
.main-fluid .kr-footer-en {
  // background: url("https://cdn.insuranceinbox.com/omanbr/footer_images/footer_bg2.png") no-repeat center top;
  background: url('../../public/footer_images/footer_bg2.webp')no-repeat center top;
}

.main-fluid .kr-footer-en::after  {
  // background: url("https://cdn.insuranceinbox.com/omanbr/footer_images/footer_bg2.png") no-repeat 0px 50px;
  background: url("../../public/footer_images/footer_bg2.webp") no-repeat 0px 50px;
}
.kr-footer-en .footer-container{
  margin: 0px auto;
  position: relative;
  max-width: 90%;
  min-width: 90%;
  padding-top: 200px;
  color: $white;
}
.ft-wdcon-en {
  width: 1000px;
  margin: 0 auto;
}
.ft-con-sec-en {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  padding-top: 90px;
}
.ft-con-itemone-en {
  flex: 0 auto;
  width: 33%;
}
.ft-con-itemone-en p.desc-en {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
}
.smedia-en {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row;
}
.smedia-en a {
  flex: 0 auto;
  margin-right: 15px;
}
.smedia-en a img {
  width: 23px;
}
.ft-con-itemtwo-en {
  flex: 0 auto;
  width: 33%;
  padding-left: 7%;
}
.ft-con-itemtwo-en .footer-links-en {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  flex-flow: column;
}
.footer-links-en a {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  text-decoration: none;
  padding-bottom: 8px;
  outline: none;
}
.footer-links-en a:hover {
  color: $white;
  text-decoration: underline;
  outline: none;
}
.ft-con-itemthree-en {
  flex: 0 auto;
  width: 33%;
  padding-left: 7%;
}
.get-direction-en {
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  color: $white;
  text-decoration: none;
}
.get-direction-en:hover {
  color: $white;
  text-decoration: underline;
}
p.address-en {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
}
p.address-en a {
  color: $white;
  text-decoration: none;
}
p.address-en a:hover {
  color: $white;
  text-decoration: underline;
}
p.copywrights-en {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 20px;
  margin: 0px 0px 0px 0px;
}
.kr-footer-en .title {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
}
.kr-footer-en .km-address p.address {
  font-size: 13px;
}
.kr-footer-en p.desc {
  font-size: 13px;
}

.kr-footer-en svg{
margin-right: 10px;
}

// @media all and (min-width:768px) and (max-width: 1100px) {
// .kr-footer-en .footer-container{
// max-width: 90%;
// margin: 0px auto;
// }
// }

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
  .ft-wdcon-en {
    width: 94%;
    margin: 0 auto;
}
}

/* TAB FOOTER CSS STARTS HERE */
.kr-tabfooter-en {
  background: url("https://cdn.insuranceinbox.com/omanbr/footer_images/footer_bg2.png") no-repeat center top;
  display: block;
  width: 100%;
  color: $white;
  margin-top: 40px;
}
.tabcw-en {
  flex: 0 auto;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  text-align: center;
  padding: 20px 0px;
  margin: 0px 0px 0px 0px;
}
.tab-ftimg {
  position: absolute;
  width: 100%;
  top: -60px;
  left: 0px;
}
.tab-ft-con-sec-en {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  padding: 34px 20px 50px;
}
.tabft-con-itemone-en {
  flex: 0 auto;
  width: 33%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  margin-top: 30px;
}
.tabmediaicon-en {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
}
.tabmediaicon-en a {
  margin: 5px 13px 0px 5px;
}
.tabftlinks-en {
  flex: 0 auto;
  width: 33%;
  margin-top: 30px;
  padding-left: 40px;
}
.tabftlinks-en a {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  text-decoration: none;
  margin-bottom: 10px;
  padding-left: 30px;
}
.tabftlinks-en a:hover {
  color: $white;
  text-decoration: underline;
}
.ft-con-itemtwo-en {
  flex: 0 auto;
  width: 33%;
  padding-left: 7%;
}
/* TAB FOOTER CSS ENDS HERE */


/* MOBILE FOOTER CSS STARTS HERE */
.kr-mbfooter-en {
  background: #b30e1f;
  float: left;
  width: 100%;
  position: relative;
  margin-top: 80px;
}
.mob-ftimg {
  position: absolute;
  width: 100%;
  top: -50px;
  left: 0px;
}
.mobft-mobwdcon-en {
  width: 100%;
  margin: 0 auto;
}
.ft-mobwdcon-en {
  background: #b30e1f;
  width: 100%;
  margin: 0 auto;
}
.mobft-con-sec-en {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: row wrap;
  padding-top: 32px;
}
.mobft-con-itemone-en {
  flex: 0 auto;
  width: 100%;
  font-family: 'Poppins', sans-serif;
    font-size: 13px;
    color: $white;
    padding: 0px 30px;
  text-align: center;
}
.mobsmedia-en {
  flex: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin: 20px 0px;
}
.mobsmedia-en a {
  flex: 0 auto;
  margin: 10px;
}
.mobft-lnk-add {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row wrap;
  margin: 0px 0px 0px 0p;
}
.mobftlnkadd-item {
  flex: 0 auto;
  width: 48%;
  padding-left: 25px;
}
.mobftlnkadd-item a {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: $white;
  text-decoration: none;
  margin-bottom: 10px;
}
.mobftlnkadd-item a:hover {
  color: $white;
  text-decoration: underline;
}
.mobftlnkadd-additem {
  flex: 0 auto;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: $white;
  text-align: center;
  margin-top: 20px;
}
.mobftlnkadd-additem a {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: $white;
  text-decoration: none;
}
.mobftlnkadd-additem a:hover {
  color: $white;
  text-decoration: underline;
}
a.mobget-direction-en {
  display: block;
  width: 100%;
  margin-top: 10px;
  text-decoration: none;
}
a.mobget-direction-en {
  color: $white;
  text-decoration: none;
}
a.mobget-direction-en:hover {
  color: $white;
  text-decoration: underline;
}
a.mobget-direction-en img {
  margin-right: 7px;
}
.mobcopywrights-en {
  float: left;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $white;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}