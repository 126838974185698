// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import './mixins.scss';
@import './themes.scss';


@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('../../public/popins/poppins-bold-webfont.eot');
  src: url('../../public/popins/poppins-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/popins/poppins-bold-webfont.woff2') format('woff2'),
       url('../../public/popins/poppins-bold-webfont.woff') format('woff'),
       url('../../public/popins/poppins-bold-webfont.ttf') format('truetype'),
       url('../../public/popins/poppins-bold-webfont.svg#poppinsbold') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('../../public/popins/poppins-medium-webfont.eot');
  src: url('../../public/popins/poppins-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/popins/poppins-medium-webfont.woff2') format('woff2'),
       url('../../public/popins/poppins-medium-webfont.woff') format('woff'),
       url('../../public/popins/poppins-medium-webfont.ttf') format('truetype'),
       url('../../public/popins/poppins-medium-webfont.svg#poppins_mediumregular') format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('../../public/popins/poppins-regular-webfont.eot');
  src: url('../../public/popins/poppins-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/popins/poppins-regular-webfont.woff2') format('woff2'),
       url('../../public/popins/poppins-regular-webfont.woff') format('woff'),
       url('../../public/popins/poppins-regular-webfont.ttf') format('truetype'),
       url('../../public/popins/poppins-regular-webfont.svg#poppinsregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

a {outline : none;}

html, body, #root {
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  height: 100%;
  padding: 0px;
  color: #111;
}
#root {
  display: flex;
  flex-direction: column;
}
.logo {
    text-decoration: none;
    display: block;
    line-height: 1;
  }
  
  header a,
  header a:focus,
  header a:active,
  header a:visited {
    color: #111;
}
.dsk-tab-btn-viewmode-en {
  display: none;
  @include for-tablet-landscape-up {
    display: block;
  }
  @include for-desktop-up {
      display: block;
  }
  @include for-big-desktop-up {
      display: block;
  }
}
.dsk-tab-btn-viewmode-ar {
  display: none;
  @include for-tablet-landscape-up {
    display: block;
  }
  @include for-desktop-up {
      display: block;
  }
  @include for-big-desktop-up {
      display: block;
  }
}
.tab-btn-viewmode-en {
  display: none;
  @include for-tablet-portrait-up {
      display: block;
      .tab-imgtxt-en {
        float: left;
          padding-top: 25px;
      h2 {
        float:left;
        width: 100%;
        margin: 0px 0px 15px 0px;
        padding: 0px 0px 0px 0px;
      }
      .tabs-cont-en {
        width: 100%;
        margin: 0px;
        p {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: justify;
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
        p.txtimg {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: left;
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
      img.rgt-img-en {
        float: right;
        width: 50%;
        // padding: 1%;
        padding: 0% 1% 0% 2%;
      }
        .tabkyfet-en {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-flow: row wrap;
          width: 100%;
          margin-bottom: 20px;
        .tabkeyfet-img-en {
          flex: 0 auto;
          width: 2%;
            img {
              width: 15px;
              }
            }
            .tabkeyfet-txt-en {
                flex: 0 auto;
                width: 96%;
                margin-left: 6px;
                margin-bottom: 12px;
              }
          }
          p.tab-subhead-title-en {
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
            text-align: left;
            line-height: 40px;
          }
          h3.tab-sub-title-en {
            float: left;
            width: 100%;
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
          }
          h3.tab-whykrinsu-title-en {
            float: left;
            width: 100%;
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
          }
          .tab-whykrinsu-pins-list-en {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: row wrap;
            margin: 0px 0px 20px;
            width: 100%;
            .tab-whykrinsu-pinlft-one-en {
              flex: 0 auto;
              width: 35%;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 600;
              color: $black;
              margin-bottom: 15px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              img {
                width: 15px;
                margin-right: 10px;
              }
            }
            .tab-whykrinsu-pinlft-two-en {
              flex: 0 auto;
              width: 65%;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 600;
              color: $black;
              margin-bottom: 15px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              img {
                width: 15px;
                margin-right: 10px;
              }
            }
          }
        }
      }
      .tab-imgtxt-ar {
        float: left;
          padding-top: 25px;
      h2 {
        float:left;
        width: 100%;
        margin: 0px 0px 15px 0px;
        padding: 0px 0px 0px 0px;
        text-align: right;
        font-family: 'Poppins', sans-serif;
        font-size: 36px;
        font-weight: 600;
        color: $vdblue;
      }
      .tabs-cont-ar {
        width: 100%;
        margin: 0px;
        p.tabimg-list {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: right;
          width: 50%;
          img {
            width: 15px;
            margin-left: 6px;
            margin-top: 5px;
          }
        }
        p.tabimg-list-wdth {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: right;
          width: 100%;
          img {
            width: 15px;
            margin-left: 6px;
            margin-top: 5px;
          }
        }
        p {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: right;
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
        p.txtimg {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: right;
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
      img.rgt-img-ar {
        float: left;
        width: 50%;
        // padding: 1%;
        padding: 0% 1% 0% 2%;
      }
        .tabkyfet-ar {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          flex-flow: row wrap;
          width: 100%;
          margin-bottom: 20px;
        .tabkeyfet-img-ar {
          flex: 0 auto;
          width: 2%;
            img {
              width: 15px;
              }
            }
            .tabkeyfet-txt-ar {
                flex: 0 auto;
                width: 96%;
                margin-right: 6px;
                margin-bottom: 12px;
                text-align: right;
              }
          }
          p.tab-subhead-title-en {
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
            text-align: left;
            line-height: 40px;
          }
          h3.tab-sub-title-ar {
            float: left;
            width: 100%;
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
            text-align: right;
          }
          h3.tab-whykrinsu-title-ar {
            float: left;
            width: 100%;
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
            text-align: right;
          }
          .tab-whykrinsu-pins-list-ar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-flow: row wrap;
            margin: 0px 0px 20px;
            width: 100%;
            .tab-whykrinsu-pinlft-one-ar {
              flex: 0 auto;
              width: 35%;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 600;
              color: $black;
              margin-bottom: 15px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              img {
                width: 15px;
                margin-left: 10px;
              }
            }
            .tab-whykrinsu-pinlft-two-ar {
              flex: 0 auto;
              width: 65%;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 600;
              color: $black;
              margin-bottom: 15px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              img {
                width: 15px;
                margin-left: 10px;
              }
            }
          }
        }
      }
  }
  @include for-tablet-landscape-up {
      display: none;
  }
}


//ARBIC
.tab-btn-viewmode-ar {
  display: none;
  @include for-tablet-portrait-up {
      display: block;
      .tab-imgtxt-ar {
        float: left;
          padding-top: 25px;
      h2 {
        float:left;
        width: 100%;
        margin: 0px 0px 15px 0px;
        padding: 0px 0px 0px 0px;
      }
      .tabs-cont-ar {
        width: 100%;
        margin: 0px;
        p {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: justify;
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
        p.txtimg-ar {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: right;
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
      img.rgt-img-ar {
        float: right;
        width: 50%;
        // padding: 1%;
        padding: 0% 1% 0% 2%;
      }
        .tabkyfet-ar {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-flow: row wrap;
          width: 100%;
          margin-bottom: 20px;
        .tabkeyfet-img-ar {
          flex: 0 auto;
          width: 2%;
            img {
              width: 15px;
              }
            }
            .tabkeyfet-txt-ar {
                flex: 0 auto;
                width: 96%;
                margin-left: 6px;
                margin-bottom: 12px;
              }
          }
          p.tab-subhead-title-ar {
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
            text-align: right;
            line-height: 40px;
          }
          h3.tab-sub-title-en {
            float: left;
            width: 100%;
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
          }
          h3.tab-whykrinsu-title-ar {
            float: left;
            width: 100%;
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
          }
          .tab-whykrinsu-pins-list-ar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-flow: row wrap;
            margin: 0px 0px 20px;
            width: 100%;
            .tab-whykrinsu-pinlft-one-ar {
              flex: 0 auto;
              width: 35%;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 600;
              color: $black;
              margin-bottom: 15px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              img {
                width: 15px;
                margin-right: 10px;
              }
            }
            .tab-whykrinsu-pinlft-two-ar {
              flex: 0 auto;
              width: 65%;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 600;
              color: $black;
              margin-bottom: 15px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              img {
                width: 15px;
                margin-right: 10px;
              }
            }
          }
        }
      }
      .tab-imgtxt-ar {
        float: left;
          padding-top: 25px;
      h2 {
        float:left;
        width: 100%;
        margin: 0px 0px 15px 0px;
        padding: 0px 0px 0px 0px;
        text-align: right;
        font-family: 'Poppins', sans-serif;
        font-size: 36px;
        font-weight: 600;
        color: $vdblue;
      }
      .tabs-cont-ar {
        width: 100%;
        margin: 0px;
        p {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: right;
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
        p.txtimg {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: right;
          img {
            width: 15px;
            margin-right: 5px;
          }
        }
        p.tabimg-list {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: right;
          width: 50%;
          img {
            width: 15px;
            margin-left: 6px;
            margin-top: 5px;
          }
        }
        p.tabimg-list-wdth {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          text-align: right;
          width: 100%;
          img {
            width: 15px;
            margin-left: 6px;
            margin-top: 5px;
          }
        }
      img.rgt-img-ar {
        float: left;
        width: 50%;
        // padding: 1%;
        padding: 0% 1% 0% 2%;
      }
        .tabkyfet-ar {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-flow: row wrap;
          width: 100%;
          margin-bottom: 20px;
        .tabkeyfet-img-ar {
          flex: 0 auto;
          width: 2%;
            img {
              width: 15px;
              }
            }
            .tabkeyfet-txt-ar {
                flex: 0 auto;
                width: 96%;
                margin-right: 6px;
                margin-bottom: 12px;
                text-align: right;
              }
          }
          p.tab-subhead-title-en {
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
            text-align: left;
            line-height: 40px;
          }
          h3.tab-sub-title-ar {
            float: left;
            width: 100%;
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
            text-align: right;
          }
          h3.tab-whykrinsu-title-ar {
            float: left;
            width: 100%;
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
            text-align: right;
          }
          .tab-whykrinsu-pins-list-ar {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-flow: row wrap;
            margin: 0px 0px 20px;
            width: 100%;
            .tab-whykrinsu-pinlft-one-ar {
              flex: 0 auto;
              width: 35%;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 600;
              color: $black;
              margin-bottom: 15px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              img {
                width: 15px;
                margin-left: 10px;
              }
            }
            .tab-whykrinsu-pinlft-two-ar {
              flex: 0 auto;
              width: 65%;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 600;
              color: $black;
              margin-bottom: 15px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              img {
                width: 15px;
                margin-left: 10px;
              }
            }
          }
        }
      }
  }
  @include for-tablet-landscape-up {
      display: none;
  }
}

