@import '../css/themes.scss';
@import '../css/mixins.scss';

a {
  outline: none;
}

.main-fluid {
  width: 100%;
  margin: 0px auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
}

.main-fluid .kr-container-bdy-en {
  width: 1300px;
  margin: 0 auto;
}

@media all and (min-width: 1200px) and (max-width:1359px) {
  .main-fluid .kr-container-bdy-en {
    width: 1100px;
    margin: 0 auto;
  }
}

@media all and (min-width:320px) and (max-width: 767px) {
  .main-fluid .kr-container-bdy-en {
    width: 100%;
    margin: 0 auto;
  }
}

.hm-banner-slider-en.slide-image img {
  width: 70%;
  margin: 0px auto;
}

.hm-banner-slider-en .slick-dots li button::before {
  background-color: #00426a;
  border-radius: 50%;
  top: 15px;
  width: 15px;
  height: 15px;
}

.addvalue-en {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin-top: 100px;
}

.addvalue-item-head-en {
  flex: 0 auto;
  width: 100%;
  text-align: center;
}

.addvalue-mob-en {
  display: block;
  width: 100%;

  @include for-phone-only {
    padding: 20px;
  }
}

h3.addvalu-title-en {
  float: left;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  font-weight: 600;
  color: $vdblue;
  text-align: center;
  padding: 0px 10px 70px;

  @include for-phone-only {
    font-size: 22px;
    line-height: 30px;
    padding: 0px 10px 10px;
  }
}

@media all and (min-width: 1200px) and (max-width:1359px) {
  h3.addvalu-title-en {
    padding: 0px 10px 70px;
  }
}

.addvalue-item-head-mob-en h3 {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: $vdblue;
  text-align: center;
  padding: 20px 0px 40px;
}

.addvalue-cards-en {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: row;
  width: 100%;
  position: relative;
  padding: 0 100px;
}

.addvalue-item-en {
  flex: 0 auto;
  max-width: 360px;
  background-color: #fff;
  text-align: center;
  position: relative;
  margin: 20px 50px;
  min-height: 310px;
}

@media all and (min-width: 1200px) and (max-width:1359px) {
  .addvalue-item-en img {
    width: 95%;
  }
}

.addvalue-cards-en .addvalue-item p.bxone-en,
.addvalue-cards-en .addvalue-item p.bxtwo-en {
  font-family: 'Poppins', sans-serif;
  color: $black;
}

.bxone-en {
  position: absolute;
  bottom: 48px;
  left: -145px;
  width: 285px;
  background: $white;
  border-radius: 10px;
  padding: 15px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  text-align: left;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
}

@media all and (min-width: 1200px) and (max-width:1359px) {
  .bxone-en {
    bottom: 52px;
    left: -156px;
    width: 275px;
  }
}

.bxtwo-en {
  position: absolute;
  top: 60px;
  right: -233px;
  width: 300px;
  background: $white;
  border-radius: 10px;
  padding: 15px 25px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  text-align: left;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
}

@media all and (min-width: 1200px) and (max-width:1359px) {
  .bxtwo-en {
    position: absolute;
    top: 40px;
    right: -174px;
    width: 250px;
  }
}

@media all and (min-width: 1360px) and (max-width:1920px) {
  .bxtwo-en {
    position: absolute;
    top: 40px;
    right: -245px;
    width: 250px;
  }
}

.addvalue-cards-en .addvalue-item p.bxtwo-en {
  padding: 20px 40px;
}

.addvalue-itemob-en {
  flex: 0 auto;
  max-width: 100%;
  background-color: #fff;
  text-align: center;
  position: relative;
  margin: 0px;
}

.addvalue-cards-mob-en {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: column;
}

.addvalue-item-mob-en {
  background-color: #fff;
  flex: 0 auto;
  text-align: center;
  position: relative;
  margin: 20px;
  min-height: 310px;
}

.addvalue-item-mob-en img {
  width: 90%;
}

.mb-bxone-en {
  display: block;
  width: 100%;
  background: $white;
  border-radius: 10px;
  padding: 15px 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.mb-bxtwo-en {
  display: block;
  width: 100%;
  background: $white;
  border-radius: 10px;
  padding: 15px 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.hm-contactus-en {
  min-height: 100%;
  position: relative;
  z-index: 0;
  padding: 100px 0px 100px 0px;

  .hm-link-txt-en {
    text-align: center;

    a {
      color: #00426a;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.hm-contactus-en h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 45px;
  font-weight: 700;
}

.hm-contactus-en::after {
  background: url('../../public/homepage_images/circledlines.webp') no-repeat 0% 60%;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 90%;
  background-size: contain;
  background-position: top;
  z-index: -1;
}

.hm-contactus-en h2.hm-contact-title-en {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  color: $vdblue;
  text-align: center;
  margin-bottom: 35px;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-contactus-en h2.hm-contact-title-en {
    font-size: 27px;
    margin-bottom: 30px;
  }
}

.hm-contactus-en p.hm-contact-txt-en {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: $black;
  text-align: center;
  margin-bottom: 100px;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-contactus-en p.hm-contact-txt-en {
    margin-bottom: 40px;
  }
}

.contactUs span.item-two {
  flex: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
}

.hm-contactus-en .contact-persons-en {
  margin-top: 5%;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-contactus-en .contact-persons-en {
    margin-top: 0%;
    width: 100%;
    padding: 20px;
  }
}

.hm-contactus-en .contact-persons-en .slick-list {
  margin: 0px auto 0px auto !important;
  max-width: 1300px;
}

.hm-contactus-en .contact-persons-en .slick-prev {
  background: url("https://cdn.insuranceinbox.com/krImages/arrowright2.png") no-repeat 9px 9px;
  left: -24px;
  top: 35%;
  z-index: 111;
  width: 50px;
  height: 50px;
}

.hm-contactus-en .contact-persons-en .slick-next {
  background: url("https://cdn.insuranceinbox.com/krImages/arrowright1.png") no-repeat 9px 9px;
  right: -55px;
  top: 35%;
  z-index: 111;
  width: 50px;
  height: 50px;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-contactus-en .contact-persons-en .slick-next {
    right: 0px;
  }

  .hm-contactus-en .contact-persons-en .slick-prev {
    left: -7px;
  }
}

.hm-contactus-en .first-person-en .slick-slide img {
  display: inherit;
  width: 250px !important;
}

.hm-contactus-en .first-person-en .person-details {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.hm-contactus-en .contact-persons-en .first-person-en {
  margin: 0px 20px;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  text-align: center;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-contactus-en .contact-persons-en .first-person-en {
    margin: 0px;
  }
}

.first-person-en:nth-child(2),
.first-person-en:nth-child(3) {
  margin-top: 100px;
}

.first-person-en p.person-name-en {
  padding: 15px 0px 0px 0px;
}

.first-person-en p {
  font-family: 'Poppins', sans-serif;
  color: $vdblue;
  font-size: 15px;
  font-weight: 500;
}

.first-person-en .fa {
  font: normal normal normal 14px/1 FontAwesome !important;
  padding-right: 10px;
}

p.em-add-en {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  color: $vdblue;
  margin-bottom: 0px;
}

p.deg-htem-en {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: $mred;
  margin-bottom: 0px;
  padding: 0px;
}

p.em-add-en svg.fa-envelope {
  margin-right: 10px;
}

@media all and (max-width:1200px) {
  .addvalue-cards-en{ 
    display: flex;
    flex-direction: column;
    // padding: 0 180px;
  }
}


@media all and (min-width:320px) and (max-width: 767px) {
  .kr-container-bdy-eng {
    padding-top: 20px;
    width: 100%;
    margin: 0px;
  }

  .we-do .list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;
    text-align: justify;
  }

  .hm-eg-experts-en h2 {
    font-size: 23px;
  }

  .hm-contactus-eng .contact-persons-en .slick-prev {
    left: 0px;
  }

  .list p {
    margin-left: 10px;
    font-size: 16px;
    line-height: 30px;
    padding: 0px 10px;
  }

  .main-fluid .kr-footer {
    font-family: 'Poppins', sans-serif;
    min-height: 100%;
    max-height: 100%;
    padding: 40px 0px 70px 20px;
  }

  .expcard-eng a.carditem-en {
    width: 150px;
    padding: 20px;
    min-height: 195px;
  }

  .expcard-eng a.carditem-en h3 {
    font-size: 16px;
    font-weight: 500;
  }

  .addvalue-item-head-en h3 {
    font-size: 20px !important;
    padding: 0px 0px 20px;
  }

  .addvalue-cards-en {

    flex-flow: column;
  }

  .addvalue-item {
    max-width: 100%;
    min-height: 270px;

  }

  .addvalue-item img {
    max-width: 100%;
  }

  .addvalue-item p.bxone,
  .addvalue-item p.bxtwo {
    font-size: 17px;
    padding: 20px;
  }

  .hm-contactus-en {
    min-height: 100vh;
    position: relative;
    z-index: 0;
    padding: 0px 0px 30px;
  }

  .hm-contactus-en p {
    font-size: 16px;
    padding: 0px 10px;
  }

  .first-person-eng p {
    font-size: 22px;
  }

  p.em-add-en {
    font-size: 22px;
  }

  .contact-persons-en .slick-next {
    right: 15px;
  }

  .contact-persons-en .slick-list {
    margin: 0px 0px 0px 8% !important;
  }

  .contact-persons-en .slick-prev {

    left: 0px;

  }

  .expcard-en a.carditem img {
    width: 50px;
    padding-bottom: 20px;
  }

  .hm-eg-experts-en {
    margin: 0px 0px 30px 0px;
    padding-top: 0px;
  }

  .addvalue-itemob-en img {
    width: 80%;
  }
}

@media all and (min-width:900px) and (max-width: 1400px) {
  .expcard-eng a.carditem {
    width: 210px !important;
  }

  @media all and (min-width:768px) and (max-width: 1100px) {
    .contact-persons-en .slick-list {
      margin-left: 0% !important;
    }

    .contact-persons-en .slick-next {
      right: -5px !important;
    }
  }
}

@media all and (min-width:768px) and (max-width: 1024px) {
  .hm-eg-fr-section-en .we-do {
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px !important;
    margin-top: 0px;
  }

  .we-do .list {
    margin-bottom: 0px;
  }

  .list p {
    margin-left: 15px;
  }
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-banner-slider-en .slick-slider .slick-dots {
    bottom: -5px !important;
  }
}

.hm-ft-con-en {
  max-width: 1360px;
  margin: 0 auto;
  margin-top: 10%;
}

@media all and (min-width: 1024px) and (max-width:1359px) {
  .hm-ft-con-en {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 10%;
  }
}

.hm-ft-con-lft-main-en {
  float: left;
  width: 40%;
  padding-top: 120px;
}

@media all and (min-width: 1200px) and (max-width:1359px) {
  .hm-ft-con-lft-main-en {
    float: left;
    width: 45%;
    padding-top: 65px;
  }

  .hm-ft-con-rgt-main-en {
    float: right;
    width: 55%;
  }

  .hm-ft-con-rgt-main-en .slick-dots {
    bottom: 15px;
  }
}

@media all and (min-width: 1400px) and (max-width:1920px) {
  .hm-ft-con-rgt-main-en {
    float: right;
    width: 58%;
  }
}

.hm-ft-en {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row;
  width: 100%;
}

.hm-ft-lft-en {
  flex: 0 auto;
  width: 40%;
}

.hm-ft-rgt-en {
  flex: 0 auto;
  width: 60%;
}

.hm-ft-heading-en {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  margin-top: 0%;
  line-height: 60px;
  font-weight: 600 !important;
  color: $vdblue;
  padding-right: 45px;
}

.hm-ft-heading-en span.omn-title-en {
  color: $mred;
}

.hm-ft-lft-en p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  margin-top: 20px;
  line-height: 23px;
}

.banner-txt-en {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 23px;
  margin-top: 35px;
  padding-right: 40px;
}

.sub-title-en {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  margin-top: 50px;
  padding-right: 60px;
}

.hm-ft-con-mob-en {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 22%;
}

.hm-ft-con-lft-main-mob-en {
  float: left;
  width: 100%;
  padding: 20px;
}

.hm-ft-heading-mob-en {
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-weight: 600;
  line-height: 36px;
  color: $vdblue;
  text-align: center;
}

.hm-ft-heading-mob-en span.omn-title-en {
  color: $mred;
}

.hm-ft-lft-en p.sub-title-en {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  margin-top: 40px;
}

.hm-ft-con-rgt-main-mob-en {
  float: left;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.hm-ft-con-lft-two-main-mob-en {
  float: left;
  width: 100%;
  padding: 20px;
  text-align: center;
}

.banner-txt-mob-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
}

.sub-title-mob-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
}

/* SECOUND SECTION CSS STARTS HERE */
.hm-sc-con-en {
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 100px;
}

@media all and (min-width: 1200px) and (max-width:1359px) {
  .hm-sc-con-en {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 100px;
  }
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-sc-con-en {
    max-width: 100%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .hm-sc-lft-en {
    width: 100%;
    padding: 10px 20px;
    text-align: center;
  }

  .hm-sc-lft-en img {
    width: 100%;
    margin: auto;
  }
}

.hm-sc-en {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row wrap;
  max-width: 100%;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-sc-en {
    flex-flow: column;
  }
}

.hm-sc-lft-en {
  flex: 0 auto;
  max-width: 50%;
}

@media all and (min-width: 1200px) and (max-width:1359px) {
  .hm-sc-lft-en {
    flex: 0 auto;
    width: 45%;
  }

  .hm-sc-lft-en img {
    width: 100%;
  }
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-sc-lft-en {
    max-width: 100%;
  }
}

.hm-sc-rgt-en {
  flex: 0 auto;
  max-width: 45%;
}

@media all and (min-width: 1200px) and (max-width:1359px) {
  .hm-sc-rgt-en {
    flex: 0 auto;
    width: 50%;
  }
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-sc-rgt-en {
    max-width: 100%;
  }
}

.hm-sc-rgt-en h2.wetitle-en {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  margin-top: 6%;
  line-height: 45px;
  font-weight: 600 !important;
  color: $vdblue;
  text-align: center;
}

.hm-sc-we-do-en {
  width: 100%;
  margin-top: 35px;
  max-width: 100%;
  min-width: 100%;
  margin-left: 0px;
}

.hm-sc-we-do-en h2.we-do-title-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  line-height: 45px;
  font-weight: 600;
  color: $vdblue;
  text-align: center;
  margin: 6% 0px 100px;
}

.we-do-title-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 35px;
  line-height: 45px;
  font-weight: 600;
  color: $vdblue;
  text-align: center;
  margin: 6% 0px 60px;
}

.expertises-sec-en {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  padding-left: 20px;
}

.expertises-card-en {
  flex: 0 auto;
  width: 47%;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  padding: 40px 10px;
  cursor: pointer;
  text-decoration: none;
}

.expertises-card-en:hover {
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
}

.expe-insimg-en {
  flex: 0 auto;
  width: 20%;
  padding-left: 5px;
  padding-bottom: 10px;
}

.expe-insimg-en img {
  width: 35px;
  text-align: center;
}

.expe-insnm-en {
  flex: 0 auto;
  width: 80%;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: $vdblue;
  text-align: center;
}

/* EXPERTISES MOBILE SECITION CSS STARTS HERE */
.expertises-sec-mob-en {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: column;
  width: 100%;
  padding-left: 0px;
}

.expertises-card-mob-en {
  flex: 0 auto;
  width: 90%;
  height: 65px;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  padding: 10px 10px;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
}

.expertises-card-mob-en:hover {
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
}

.expe-insimg-mob-en {
  flex: 0 auto;
  width: 15%;
  padding-left: 5px;
}

.expe-insimg-mob-en img {
  width: 35px;
  text-align: center;
}

.expe-insnm-mob-en {
  flex: 0 auto;
  width: 85%;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: $vdblue;
  text-align: left;
  padding-left: 15px;
}

/* EXPERTISES MOBILE SECITION CSS STARTS HERE */
@media all and (min-width:320px) and (max-width: 767px) {
  h2.we-do-title-en {
    font-size: 22px;
    line-height: 36px;
    font-weight: 600;
    color: $vdblue;
    text-align: center;
    margin: 6% 0px 30px;
  }

  .hm-sc-we-do-en h2.we-do-title-en {
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    color: $vdblue;
    text-align: center;
    margin: 6% 0px 30px;
  }
}

.hm-sc-we-do-en .hm-sc-list-en {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 50px;
}

.hm-sc-list-en .hm-sc-list-img-item-one-en {
  flex: 0 auto;
  width: 80px;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-sc-list-en .hm-sc-list-img-item-one-en {
    margin-bottom: 20px;
  }
}

.hm-sc-list-img-item-one-en img {
  width: 71px;
  height: 60px;
}

.hm-sc-list-en .hm-sc-list-img-item-two-en {
  flex: 0 auto;
  width: 80px;
  text-align: center;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-sc-list-en .hm-sc-list-img-item-two-en {
    margin-bottom: 20px;
  }
}

.hm-sc-list-img-item-two-en img {
  width: 55px;
  height: 62px;
}

.hm-sc-list-en .hm-sc-list-img-item-three-en {
  flex: 0 auto;
  width: 80px;
  text-align: center;
}

.hm-sc-list-img-item-three-en img {
  width: 66px;
  height: 58px;
}

.hm-sc-list-en .hm-sc-list-txt-item-en {
  flex: 0 auto;
  width: 80%;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-sc-list-en .hm-sc-list-txt-item-en {
    width: 100%;
  }
}

.hm-sc-list-txt-item-en p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin-left: 10px;
  line-height: 23px;
  padding-left: 10px;
  text-align: justify;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-sc-list-txt-item-en p {
    font-size: 16px;
    font-weight: normal;
    margin-left: 0px;
    line-height: 23px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
}

/* SECOUND SECTION CSS ENDS HERE */

/* THIRD SECTION CSS STARTS HERE */
.hm-thr-con-en {
  width: 1100px;
  margin: 0 auto;
  margin-top: 100px;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-thr-con-en {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    margin-top: 0px;
  }
}

.hm-thr-title-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  line-height: 45px;
  font-weight: 600;
  color: $vdblue;
  text-align: center;
  margin-bottom: 100px;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-thr-title-en {
    font-size: 28px;
    line-height: 35px;
    font-weight: 600;
    color: $vdblue;
    text-align: center;
    margin-bottom: 50px;
  }
}

.hm-thr-con-en .hm-thr-expcard-en {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-thr-con-en .hm-thr-expcard-en {
    justify-content: space-between;
  }
}

.hm-thr-expcard-en a.carditem-en {
  flex: 0 auto;
  width: 250px;
  text-align: center;
  margin: 8px;
  padding: 30px 20px 30px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-thr-expcard-en a.carditem-en {
    flex: 0 auto;
    width: 45%;
    text-align: center;
    margin: 8px;
    padding: 30px 10px 30px;
  }
}

a.carditem-en:hover {
  background-color: #fff;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -khtml-border-radius: 20px;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
}

.hm-thr-expcard-en a.carditem-en img {
  padding-top: 20px;
  padding-bottom: 50px;
}

.hm-thr-expcard-en a.carditem-en h3 {
  float: left;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: $black;
  text-decoration: none;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .hm-thr-expcard-en a.carditem-en h3 {
    font-size: 17px;
  }
}

.hm-thr-expcard-en a.carditem-en h3 span {
  float: left;
  width: 100%;
}

/* THIRD SECTION CSS ENDS HERE */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .dsk-visable {
    display: none;
  }

  .tab-visable {
    display: none;
  }
}

/* TAB CSS Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
  .example {
    background: blue;
  }

  .dsk-visable {
    display: none;
  }

  .tab-visable {
    display: block;
  }

  .hm-ft-con-tab-en {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 14%;
  }

  .hm-ft-con-lft-main-tab-en {
    float: left;
    width: 47%;
    padding: 20px;
  }

  .hm-ft-heading-tab-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 29px;
    font-weight: 600;
    line-height: 36px;
    color: $vdblue;
    text-align: center;
    padding: 100px 0px 0px 0px;
  }

  .hm-ft-heading-tab-en span.omn-title-en {
    color: $mred;
  }

  .hm-ft-con-rgt-main-tab-en {
    float: right;
    width: 52%;
    padding: 20px 0px 20px 20px;
    text-align: right;
  }

  .hm-ft-con-lft-two-main-tab-en {
    float: left;
    width: 100%;
    padding: 20px 10px;
    text-align: center;
  }

  .banner-txt-tab-en {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }

  .sub-title-tab-en {
    display: block;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    margin: 50px auto 0px;
  }

  .hm-sc-tabcon-en {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 100px;
  }

  .hm-sc-tabinc-en {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-bottom: 20px;
  }

  .hm-sc-tabinc-item-en {
    flex: 0 1 auto;
    width: 48%;
    margin-bottom: 25px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    padding: 40px 10px;
    cursor: pointer;
    text-decoration: none;
  }

  .hm-sc-wedotabcon-en {
    float: left;
    width: 100%;
    margin: 20px 0px 0px 0px;
  }

  .hm-sc-wedotabconlist-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin-bottom: 30px;
  }

  .hm-sc-listimg-item-en {
    flex: 0 auto;
    width: 12%;
  }

  .hm-sc-listtxt-item-en {
    flex: 0 auto;
    width: 85%;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: normal;
    margin-left: 10px;
    line-height: 36px;
    text-align: justify;
  }

  .hm-sc-lft-en {
    width: 100%;
    padding: 20px;
    text-align: center;
  }

  .hm-sc-lft-en img {
    width: 100%;
  }

  .hm-sc-rgt-en {
    flex: 0 auto;
    width: 100%;
  }

  .hm-sc-we-do-en .hm-sc-list-en {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: row wrap;
    margin-bottom: 50px;
  }

  .hm-sc-list-en .hm-sc-list-img-item-one-en {
    flex: 0 1 auto;
    width: 100px;
  }

  .hm-sc-list-img-item-one-en img {
    width: 100%;
    height: 85%;
  }

  .hm-sc-list-en .hm-sc-list-txt-item-en {
    flex: 0 1 auto;
    width: 80%;
  }

  .hm-sc-list-txt-item-en p {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: normal;
    margin-left: 10px;
    line-height: 36px;
    padding-left: 25px;
  }

  .hm-sc-list-en .hm-sc-list-img-item-two-en {
    flex: 0 1 auto;
    width: 100px;
    text-align: center;
  }

  .hm-sc-list-img-item-two-en img {
    width: 100px;
    height: 115px;
  }

  .hm-thr-con-en {
    width: 98%;
    margin: 0 auto;
    margin-top: 70px;
  }

  .hm-thr-con-en .hm-thr-expcard-en {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: row wrap;
  }

  .main-fluid .kr-container-bdy-en {
    width: 100%;
    margin: 0 auto;
  }

  .hm-thr-title-en {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    line-height: 45px;
    font-weight: 600;
    color: $vdblue;
    text-align: center;
    margin-bottom: 50px;
  }

  .hm-contactus-en {
    min-height: 100%;
    position: relative;
    z-index: 0;
    padding: 50px 0px 100px 0px;
  }

  .hm-contactus-en p.hm-contact-txt-en {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    color: $black;
    padding: 0px 30px;
    text-align: center;
    margin-bottom: 50px;
  }

  .hm-contactus-en .contact-persons-en .slick-list {
    margin: 0px auto 0px auto !important;
    max-width: 100%;
  }

  .hm-contactus-en .contact-persons-en .first-person-en {
    margin: 0px 10px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    text-align: center;
  }

  .slick-slide img {
    width: 100%;
    display: block;
  }

  .hm-contactus-en .contact-persons-en .slick-prev {
    background: url("https://cdn.insuranceinbox.com/krImages/arrowright2.png") no-repeat 9px 9px;
    left: 8px;
    top: 40%;
    z-index: 111;
    width: 50px;
    height: 50px;
  }

  .hm-contactus-en .contact-persons-en .slick-next {
    background: url("https://cdn.insuranceinbox.com/krImages/arrowright1.png") no-repeat 9px 9px;
    right: 12px;
    top: 40%;
    z-index: 111;
    width: 50px;
    height: 50px;
  }

  .slick-slide img.slideimg {
    width: 60%;
  }
}

/* TAB CSS ENDS HERE */

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .hidden-mobile {
    display: none;
  }

  .dsk-visable {
    display: none;
  }

  .tab-visable {
    display: none;
  }

  .mobile-view {
    display: block;
  }

}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .hidden-lowtab {
    display: none;
  }

  .dsk-visable {
    display: none;
  }

  .tab-visable {
    display: none;
  }

  .mobile-view {
    display: block;
  }

}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .hidden-portrait-mode-tab {
    display: none;
  }

  .dsk-visable {
    display: none;
  }

  .tab-visable {
    display: block;
  }

  .mobile-view {
    display: none;
  }

}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
  .hidden-laptop-desktops {
    display: none;
  }

  .dsk-visable {
    display: block;
  }

  .tab-visable {
    display: none;
  }

  .mobile-view {
    display: none;
  }

}

/* Media Query for Large screens */
@media (min-width: 1281px) {
  .hidden-largscreen {
    display: none;
  }

  .dsk-visable {
    display: block;
  }

  .tab-visable {
    display: none;
  }

  .mobile-view {
    display: none;
  }

}