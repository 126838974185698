@import '../css/themes.scss';
@import '../css/mixins.scss';

.getaquote span.item-two {
  flex: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
}
.hm-eg-fr-section-en .min-title {
  font-size: 16px;
}
.hm-eg-fr-section-en .we-do {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  /* max-width: 435px;
  min-width: 435px; */
  margin-left: 0px;
}
.we-do h1.wetitle-en {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  margin-top: 6%;
  line-height: 45px;
  font-weight: 600 !important;
  color: $vdblue;
  text-align: center;
}
.we-do .list {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.list img {
  width: 50px;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .list img {
    width: 65px;
  }
}
.list p {
  margin-left: 30px;
  line-height: 23px;
  font-size: 14px;
}
.getaquote .fa {
  font: normal normal normal 18px/1 FontAwesome !important;
}
.hm-eg-experts-en {
  margin: 0px 0px 30px 0px;
  padding-top: 80px;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .hm-eg-experts-en {
    padding-top: 30px;
  }

}
.hm-eg-experts-en h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  margin-top: 5%;
  margin-bottom: 25px;
  line-height: 60px;
  font-weight: 600 !important;
  color: $vdblue;
  text-align: center;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .hm-eg-experts-en h2 {
    font-size: 30px;
    margin-top: 3%;
    margin-bottom: 10px;
  }
}
.hm-eg-experts-en .expcard-en {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
}
.expcard-en a.carditem-en {
  flex: 0 auto;
  width: 250px;
  text-align: center;
  margin: 8px;
  padding: 30px 20px 30px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .expcard-en a.carditem-en {
    flex: 0 auto;
    width: 155px;
    text-align: center;
    margin: 8px;
    padding: 30px 10px 30px;
  }
}
a.carditem-en:hover {
  background-color: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -khtml-border-radius: 10px;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
}
.expcard-en a.carditem-en img {
  padding-top: 20px;
  padding-bottom: 50px;
}
.expcard-en a.carditem-en h3 {
  float: left;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: $black;
  text-decoration: none;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .expcard-en a.carditem-en h3 {
    font-size: 17px;
  }
}
.expcard-en a.carditem-en h3 span {
  float: left;
  width: 100%;
}

.hm-eg-experts-en .experts-cards-en {
  background-color: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -khtml-border-radius: 5px;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  margin: 20px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -khtml-border-radius: 10px;
  min-width: 200px;
  height: 190px;
}
.experts-cards-en .cards-items {
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  flex-flow: column;
  padding: 20px 0px 20px 20px;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}
.cards-items img {
  margin: 0px 0px 10px 0px;
}
.cards-items .cards-item-title {
  width: 100%;
  
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 15px 0px;
  color: #323232;
  line-height: 20px;
}

.cards-items .cards-item-title span {
  float: left;
  width: 100%;
}

.cards-items p {
  
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: $mred;
}

.cards-items p a {
  color: $mred;
  text-decoration: none;
}

@media all and (min-width:320px) and (max-width: 767px) {
  .main-fluid .kr-container-en {
    padding-top: 25%;
    width: 100%;
  }

  .kr-container-en .hm-eg-fr-section-en {
    padding: 0% 0%;
  }

  .kr-container-bdy-eng {
    padding-top: 20px;
    width: 100%;
    margin: 0px;
  }

  .hm-eg-fr-section-en .mb-img {
    max-width: 90%;
    margin: 0px auto;
    padding: 4%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  .hm-eg-fr-section-en h1 {
    font-size: 21px;
    line-height: 26px;
    margin: 16px 0px 30px;
    text-align: center;
  }

  .hm-eg-fr-section-en .min-title {
    float: left;
    width: 100%;
    font-size: 30px;
    text-align: center;
  }

  .hm-eg-fr-section-en p {
    font-size: 16px;
    margin-top: 20px;
    line-height: 26px;
    text-align: center;
  }

  .hm-eg-fr-section-en .sub-title {
    font-size: 17px;
    margin-bottom: 20px;
  }

  .hm-eg-fr-section-en .we-do {
    width: 100%;
    margin-top: 35px;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
  }
  .we-do .list {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;
    text-align: justify;
  }

  .hm-eg-experts-en h2 {
    font-size: 23px;
  }


  .getaquote span.item-one {
    font-size: 27px;
  }

  .hm-contactus-eng h2 {
    font-size: 27px;
    margin-bottom: 10px;
  }

  .hm-contactus-eng .contactUs {
    background: linear-gradient(-45deg, $mred 13%, $vdblue 0%);
    background: -moz-linear-gradient(-45deg, $mred 13%, $vdblue 0%);
    background: -o-linear-gradient(-45deg, $mred 13%, $vdblue 0%);
    background: -webkit-linear-gradient(-45deg, $mred 13%, $vdblue 0%);
    width: 100%;
  }

  .main-fluid .kr-footer {
    font-family: 'Poppins', sans-serif;
    min-height: 100%;
    max-height: 100%;
    padding: 40px 0px 70px 20px;
  }
  .expcard-eng a.carditem-en {
    width: 150px;
    padding: 20px;
    min-height: 195px;
  }

  .expcard-eng a.carditem-en h3 {
    font-size: 16px;
    font-weight: 500;
  }

  .addvalue-item-head-en h3 {
    font-size: 20px !important;
    padding: 0px 0px 20px;
  }

  .addvalue-cards-en {

    flex-flow: column;
  }

  .addvalue-item {
    max-width: 100%;
    min-height: 270px;

  }

  .addvalue-item img {
    max-width: 100%;
  }

  .addvalue-item p.bxone,
  .addvalue-item p.bxtwo {
    font-size: 17px;
    padding: 20px;
  }

  .hm-contactus-en {
    min-height: 100vh;
    position: relative;
    z-index: 0;
    padding: 0px 0px 30px;
  }

  .hm-contactus-en p {
    font-size: 16px;
    padding: 0px 10px;
  }

  .first-person-eng p {
    font-size: 22px;
  }

  p.em-add-en {
    font-size: 22px;
  }

  p.mob-num-en {
    font-size: 22px;
  }

  .contact-persons-en .slick-next {
    right: 15px;
  }

  .contact-persons-en .slick-list {
    margin: 0px 0px 0px 8% !important;
  }

  .contact-persons-en .slick-prev {

    left: 0px;

  }

  .expcard-en a.carditem img {
    width: 50px;
    padding-bottom: 20px;
  }
}

@media all and (min-width:900px) and (max-width: 1400px) {
  .expcard-eng a.carditem {
    width: 210px !important;
  }

  @media all and (min-width:768px) and (max-width: 1100px) {
    .contact-persons-en .slick-list {
      margin-left: 0% !important;
    }

    .contact-persons-en .slick-next {
      right: -5px !important;
    }
  }

  @media all and (min-width:900px) and (max-width: 1024px) {
    .kr-footer .footer-container {
      max-width: 900px;
    }
  }

}

@media all and (min-width:768px) and (max-width: 1024px) {
}

@media all and (min-width:320px) and (max-width: 767px) {}

@media all and (min-width: 768px) and (max-width: 991px) {

}

@media all and (min-width: 992px) and (max-width: 1199px) {
}

@media all and (min-width: 1200px) {
}
@media all and (min-width: 1200px) and (max-width:1359px) {
}
@media all and (min-width: 1200px) and (max-width:1359px) {
}
/* TAB SECTION CSS STARTS HERE */
.taboexp-con-en {
  width: 100%;
  padding: 20px;
  margin: 0;
  margin-top: 100px;
  padding-bottom: 100px;
}
.taboexp-title-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  line-height: 45px;
  font-weight: 600;
  color: $vdblue;
  text-align: center;
  margin: 4% 0px 60px;
}
.oursecinc-en {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  width: 90%;
  margin: 0 auto;
}
.oursecinc-expcard-en {
  flex: 0 auto;
  width: 45%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  padding: 40px 0px;
  margin-bottom: 20px;
  text-decoration: none;
  cursor: pointer;
}
.oursecinc-expcard-en:hover {
  text-decoration: none;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
}
.oursecinc-insimg-en {
  flex:0 auto;
  width: 100%;
  text-align: center;
}
.oursecinc-insimg-en img {
  width: 60px;
  text-align: center;
  margin-bottom: 10px;
}
.oursecinc-insnm-en {
  flex:0 auto;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: $vdblue;
  text-align: center;
}
.oursecincsectab-en {
  float: left;
  width: 100%;
  padding: 40px 20px 10px;
}
.oursecincsectab-list-en {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  width: 90%;
  margin: 0px auto 50px;
}
.oursecincsectab-img-en {
  flex: 0 auto;
  width: 12%;
  text-align: center;
}
.oursecincsectab-img-en img {
  width: 100%;
  height: 100%;
}
.oursecincsectab-img-en img.simg-en {
  width: 65px;
}
.oursecincsectab-info-en {
  flex: 0 auto;
  width: 80%;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: justify;
  margin-left: 20px;
}
/* TAB SECTION CSS ENDS HERE */
/* SECOUND SECTION CSS STARTS HERE */
.ourexp-con-en {
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 100px;
  padding-bottom: 100px;
}
@media all and (min-width: 1200px) and (max-width:1359px) {
  .ourexp-con-en {
    max-width: 90%;
    margin: 0 auto;
    margin-top: 100px;
  }
}
@media all and (min-width:320px) and (max-width: 767px) {
  .ourexp-con-en {
    max-width: 100%;
    margin: 0 auto;
    padding-top: 80px;
  }
.ourexp-lft-en img {
  width: 100%;
  margin: auto;
}
}
.ourexp-sc-en {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row wrap;
  max-width: 100%;
}
.ourexp-sc-mob-en {
  float: left;
  width: 100%;
}
.ourexp-img-mob-en {
  float: left;
  width: 100%;
  padding: 20px;
  text-align: center;
}
.ourexp-img-mob-en img {
  width: 100%;
}
.ourexpcrd-mob-sec-en {
  float: left;
  width: 100%;
  padding: 20px;
}
.ourexp-crds-mob-en {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
  padding: 12px;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  cursor: pointer;
  text-decoration: none;
}
.ourexp-crds-mob-en:hover {
  text-decoration: none;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
}
.ourexp-crds-mob-img-en {
  flex: 0 auto;
  width: 35px;
}
.ourexp-crds-mob-img-en img {
  width: 35px;
  height: 100%;
}
.ourexp-crds-mob-txt-en {
  flex: 0 auto;
  width: 88%;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: $vdblue;
  padding-left: 20px;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .ourexp-sc-en {
    flex-flow: column;
  }
}
.ourexp-lft-en {
  flex: 0 auto;
  max-width: 45%;
}
@media all and (min-width: 1200px) and (max-width:1359px) {
  .ourexp-lft-en {
    flex: 0 auto;
    width: 45%;
  }
  .ourexp-lft-en img {
    width: 100%;
  }
}
@media all and (min-width:320px) and (max-width: 767px) {
  .ourexp-lft-en {
    width: 100%;
  }
}
.ourexp-rgt-en {
  flex: 0 auto;
  max-width: 45%;
}
@media all and (min-width: 1200px) and (max-width:1359px) {
  .ourexp-rgt-en {
    flex: 0 auto;
    width:50%;
  }
}
@media all and (min-width:320px) and (max-width: 767px) {
  .ourexp-rgt-en {
    width: 100%;
  }
}
.ourexp-rgt-en h2.wetitle-en {
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  margin-top: 6%;
  line-height: 45px;
  font-weight: 600 !important;
  color: $vdblue;
  text-align: center;
}
.ourexp-wedotitle-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  line-height: 45px;
  font-weight: 600;
  color: $vdblue;
  text-align: center;
  margin: 10% 0px 60px;
}
.ourexp-sec-en {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  padding-left: 20px;
}
.ourexp-expcard-en {
  flex: 0 auto;
  width: 47%;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.41);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.41);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.41);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: column wrap;
  padding: 40px 10px;
  text-decoration: none;
  cursor: pointer;
}
.ourexp-expcard-en:hover {
  text-decoration: none;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.31);
}
.ourexp-expe-insimg-en {
  flex:0 auto;
  width: 20%;
  padding-left: 5px;
}
.ourexp-expe-insimg-en img {
  width: 35px;
  text-align: center;
}
.ourexp-expe-insnm-en {
  flex:0 auto;
  width: 80%;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: $vdblue;
}
@media all and (min-width:320px) and (max-width: 767px) {
  h2.ourexp-wedotitle-en {
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    color: $vdblue;
    text-align: center;
    margin: 6% 0px 30px;
  }
}
@media all and (min-width:320px) and (max-width: 767px) {
  .ourinsusol-sc-list-en .hm-sc-list-img-item-two-en {
    margin-bottom: 20px;
  }
}
.hm-sc-list-img-item-two-en img {
  width: 55px;
  height: 62px;
}
.ourinsusol-sc-list-en .hm-sc-list-img-item-three-en {
  flex: 0 auto;
  width: 80px;
  text-align: center;
}
.hm-sc-list-img-item-three-en img {
  width: 66px;
  height: 58px;
}
.ourinsusol-sc-list-en .ourinsusol-list-txt-item-en {
  flex: 0 auto;
  width: 80%;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .ourinsusol-sc-list-en .ourinsusol-list-txt-item-en {
    width: 100%;
  }
}
.ourinsusol-list-txt-item-en p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: normal;
  margin-left: 10px;
  line-height: 23px;
  padding-left: 10px;
}
@media all and (min-width:320px) and (max-width: 767px) {
  .ourinsusol-list-txt-item-en p {
    font-size: 16px;
    font-weight: normal;
    margin-left: 0px;
    line-height: 23px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }
}
/* SECOUND SECTION CSS ENDS HERE */

/* THIRD SECTION CSS STARTS HERE */
/* .hm-thr-con-en {
  width: 1100px;
  margin: 0 auto;
  margin-top: 100px;
} */
@media all and (min-width:320px) and (max-width: 767px) {
  /* .hm-thr-con-en {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    margin-top: 0px;
  } */
}
/* .hm-thr-title-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  line-height: 45px;
  font-weight: 600;
  color: $vdblue;
  text-align: center;
  margin-bottom: 100px;
} */
@media all and (min-width:320px) and (max-width: 767px) {
  /* .hm-thr-title-en {
    font-size: 28px;
    line-height: 35px;
    font-weight: 600;
    color: $vdblue;
    text-align: center;
    margin-bottom: 50px;
  } */
}
/* .hm-thr-con-en .hm-thr-expcard-en {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
} */
@media all and (min-width:320px) and (max-width: 767px) {
  /* .hm-thr-con-en .hm-thr-expcard-en {
    justify-content: space-between;
  } */
}
/* .hm-thr-expcard-en a.carditem-en {
  flex: 0 auto;
  width: 250px;
  text-align: center;
  margin: 8px;
  padding: 30px 20px 30px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
} */
@media all and (min-width:320px) and (max-width: 767px) {
  /* .hm-thr-expcard-en a.carditem-en {
    flex: 0 auto;
    width: 45%;
    text-align: center;
    margin: 8px;
    padding: 30px 10px 30px;
  } */
}
/* a.carditem-en:hover {
  background-color: #fff;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -khtml-border-radius: 20px;
  box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -webkit-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
  -moz-box-shadow: -2px 0px 13px -1px rgb(0, 0, 0, 0.21);
}
.hm-thr-expcard-en a.carditem-en img {
  padding-top: 20px;
  padding-bottom: 50px;
}
.hm-thr-expcard-en a.carditem-en h3 {
  float: left;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: $black;
  text-decoration: none;
} */
@media all and (min-width:320px) and (max-width: 767px) {
  /* .hm-thr-expcard-en a.carditem-en h3 {
    font-size: 17px;
  } */
}
/* .hm-thr-expcard-en a.carditem-en h3 span {
  float: left;
  width: 100%;
} */
/* THIRD SECTION CSS ENDS HERE */



/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* .example {background: red;}
  .dsk-visable {
    display: none;
  }
  .tab-visable {
    display: none;
  } */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  /* .example {background: green;}
  .dsk-visable {
    display: none;
  }
  .tab-visable {
    display: none;
  } */
}

/* TAB CSS Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
  /* .example {background: blue;}
  .dsk-visable {
    display: none;
  }
.tab-visable {
  display: block;
}
.hm-ft-con-tab-en {
  max-width: 98%;
  margin: 0 auto;
  margin-top: 14%;
} */
/* .hm-ft-con-lft-main-tab-en {
  float: left;
  width: 38%;
  padding: 20px;
} */
/* .hm-ft-heading-tab-en {
  float: left;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 29px;
  font-weight: 600;
  line-height: 36px;
  color: $vdblue;
  text-align: center;
  padding: 100px 0px 0px 0px;
} */
/* .hm-ft-heading-tab-en span.omn-title-en {
  color: $mred;
} */
/* .hm-ft-con-rgt-main-tab-en {
  float: right;
  width: 58%;
  padding: 20px 0px 20px 20px;
  text-align: right;
} */
/* .hm-ft-con-lft-two-main-tab-en {
  float: left;
  width: 100%;
  padding: 20px 10px;
  text-align: center;
} */
/* .banner-txt-tab-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
} */
/* .sub-title-tab-en {
  display: block;
  width: 80%;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  margin: 50px auto 0px;
} */
/* .ourexp-con-en {
  max-width: 90%;
  margin: 0 auto;
  margin-top: 100px;
} */
.ourexp-lft-en {
  width: 100%;
  padding: 20px;
  text-align: center;
}
.ourexp-lft-en img {
  width: 100%;
}
.ourexp-rgt-en {
  flex: 0 auto;
  width: 100%;
}
/* .ourinsusol-list-txt-item-en p {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: normal;
  margin-left: 10px;
  line-height: 36px;
  padding-left: 25px;
}
.ourinsusol-sc-list-en .hm-sc-list-img-item-two-en {
  flex: 0 1 auto;
  width: 100px;
  text-align: center;
}
.hm-sc-list-img-item-two-en img {
  width: 100px;
  height: 115px;
}
.hm-thr-con-en {
  width: 98%;
  margin: 0 auto;
  margin-top: 70px;
}
.hm-thr-con-en .hm-thr-expcard-en {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row wrap;
}
.main-fluid .kr-container-bdy-en {
  width: 100%;
  margin: 0 auto;
} */
/* .hm-thr-title-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  line-height: 45px;
  font-weight: 600;
  color: $vdblue;
  text-align: center;
  margin-bottom: 50px;
}
.hm-contactus-en {
  min-height: 100%;
  position: relative;
  z-index: 0;
  padding: 50px 0px 100px 0px;
} */
/* .hm-contactus-en p.hm-contact-txt-en {
  display: block;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  color: $black;
  padding: 0px 30px;
  text-align: center;
  margin-bottom: 50px;
} */
/* .hm-contactus-en .contact-persons-en .slick-list {
  margin: 0px auto 0px auto !important;
  max-width:100%;
} */
/* .hm-contactus-en .contact-persons-en .first-person-en {
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  text-align: center;
} */
/* .slick-slide img {
  width: 100%;
  display: block;
} */
/* .hm-contactus-en .contact-persons-en .slick-prev {
  background: url("https://cdn.insuranceinbox.com/krImages/arrowright2.png") no-repeat 9px 9px;
  left: 8px;
  top: 40%;
  z-index: 111;
  width: 50px;
  height: 50px;
} */
/* .hm-contactus-en .contact-persons-en .slick-next {
  background: url("https://cdn.insuranceinbox.com/krImages/arrowright1.png") no-repeat 9px 9px;
  right: 12px;
  top: 40%;
  z-index: 111;
  width: 50px;
  height: 50px;
} */
/* .slick-slide img.slideimg {
  width: 60%;
} */
} 
/* TAB CSS ENDS HERE */
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  /* .example {background: orange;}
  .dsk-visable {
    display: block;
  }
  .tab-visable {
    display: none;
  } */
} 

/* .hm-link-txt-en {
  float: left;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  text-align: center;
  margin-bottom: 5px;
}
.hm-link-txt-en a {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  color: $vdblue;
  text-align: center;
  text-decoration: none;
}
.hm-link-txt-en a:hover {
  color: $vdblue;
  text-decoration: underline;
} */

.ourinsu-sec-mob-en {
  float: left;
  width: 100%;
  padding: 0px 20px 20px;
}
.ourinsu-list-mob-en {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin-bottom: 20px;
}
.ourinsu-list-img-mob-en {
  flex: 0 auto;
  text-align: center;
}
.ourinsu-list-img-mob-en img {
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}
.ourinsu-list-info-mob-en {
  flex: 0 auto;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}


.ourinsol-dskmain-en {
  width: 100%;
  margin-top: 35px;
  max-width: 100%;
  min-width: 100%;
  margin-left: 0px;
}
.ourinsusol-dsk-list-en {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-flow: row;
  margin-bottom: 50px;
  width: 100%;
}
.oursol-dsk-list-img-en {
  flex: 0 auto;
  width: 80px;
}
.oursol-dsk-list-img-en img.imgone {
  width: 71px;
}
.oursol-dsk-list-img-en img.imgtwo {
  width: 55px;
}
.oursol-dsk-list-img-en img.imgthree {
  width: 55px;
}
.oursol-dsk-list-txt-en {
  flex: 0 auto;
  width: 85%;
}
.oursol-dsk-list-txt-en p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  padding-left: 25px;
  text-align: justify; 
}