@import '../css/mixins.scss';
@import '../css/themes.scss';

a {outline : none;}
.kr-container-about-en {
    max-width: 1360px;
    margin: 0 auto;
    margin-top: 5%;
}
@media all and (min-width: 1200px) and (max-width:1359px) {
    .kr-container-about-en {
      width: 1200px;
      margin: 0 auto;
      margin-top: 5%;
    }
  }
@media all and (min-width:320px) and (max-width: 767px) {
    .kr-container-about-en {
        max-width: 100%;
        margin: 0 auto;
        margin-top: 15%;
        padding: 20px;
    }
}
h1.abt-title-en {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    margin: 0px 0px 10px;
    padding: 0px;
}
@media all and (min-width:320px) and (max-width: 767px) {
h1.abt-title-en {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    text-align: center;
    margin: 15px 0px 18px;
    }
}
.kr-about-banner-en {
    padding-top: 5%;
}
.at-title-txt-en {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: $vdblue;
    padding: 0px;
    margin: 0px;
}
.kr-about-banner-en .kr-banner-titles {
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.abt-banner-txt-en {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: $vdblue;
    line-height: 23px;
    margin-top: 35px;
    padding-right: 40px;
  }
  .abt-sub-title-en {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin-top: 50px;
    padding-right: 60px;
  }
.kr-aboutus-en .kr-services-en h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    color: $mred;
    padding: 40px 0px;
    text-align: center;
}
.kr-services-en h2.sev-title-en {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    padding: 40px 0px;
    text-align: center;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .kr-services-en h2.sev-title-en {
        display: block;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 28px;
        font-weight: 600;
        /* color: $mred; */
    color: $vdblue;
        text-align: center;
        padding: 40px 20px 30px;
    }
.abt-banner-txt-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    line-height: 23px;
    margin-top: 0px;
    padding-right: 0px;
  }
}
.kr-services-en .kr-ser-wrp-en {
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
}

.kr-ser-wrp-en .kr-serv-card {
    border: solid 1px rgb(0, 66, 106, 0.2);
    min-width: 360px;
    max-width: 360px;
    min-height: 260px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin: 20px;
    padding: 30px 28px;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    flex-flow: column;
    /* justify-content: center; */
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
}

.kr-ser-wrp-en img {
    max-width: 60px;
    margin-bottom: 20px;
}

.kr-ser-wrp-en p {
  font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

.kr-milestones-en {
    margin: 20px 0px 50px 0px;
    text-align: center;
    position: relative;
}
.kr-milestones-en img.rd-img-en {
    max-width: 100%;
    height: auto;
}
@media all and (min-width: 1200px) and (max-width:1359px) {
    .kr-milestones-en img.rd-img-en {
        /* max-width: 50%; */
    }
}
.kr-milestones-en h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    padding: 40px 0px;
    text-align: center;
}
.kr-milestone-2017-en {
    position: absolute;
    top: 117px;
    right: -35px;
    width: 500px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: left;
    line-height: initial !important;
}
.kr-milestone-2016-en {
    position: absolute;
    top: 263px;
    left: 50px;
    width: 450px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: left;
    line-height: initial !important;
}
.kr-milestone-2015-en {
    position: absolute;
    top: 330px;
    right: 55px;
    width: 433px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: left;
    line-height: initial !important;
}
.kr-milestone-2014-en {
    position: absolute;
    top: 536px;
    left: 265px;
    width: 450px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: left;
    line-height: initial !important;
}
.kr-milestone-2013-en {
    position: absolute;
    top: 703px;
    right: -170px;
    width: 441px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: left;
    line-height: initial !important;
}
.kr-milestone-2012-en {
    position: absolute;
    top: 863px;
    left: 255px;
    width: 450px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: left;
    line-height: initial !important;
}
.kr-milestone-1987-en {
    position: absolute;
    top: 1105px;
    right: 32px;
    width: 414px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: left;
    line-height: initial !important;
    border: #f00 solid 0px;
}
@media all and (min-width: 1200px) and (max-width:1359px) {
    .kr-milestone-2017-en {
        position: absolute;
        top: 118px;
        right: -50px;
        font-size: 16px;
    }
    .kr-milestone-2016-en {
        top: 226px;
        left: 50px;
        font-size: 16px;
    }
    .kr-milestone-2015-en {
        top: 277px;
        right: 22px;
        font-size: 16px;
    }
    .kr-milestone-2014-en {
        top: 430px;
        left: 180px;
        font-size: 16px;
    }
    .kr-milestone-2013-en {
        top: 555px;
        right: -30px;
        width: 322px;
        font-size: 16px;
    }
    .kr-milestone-2012-en {
        top: 677px;
        left: 155px;
        font-size: 16px;
    }
    .kr-milestone-1987-en {
        top: 856px;
        right: 18px;
        font-size: 16px;
    }
}
.kr-milestones-en .milestone-wrp {
    align-items: center;
    /* max-width: 580px; */
    max-width: 640px;
    justify-content: center;
    flex-flow: row;
    margin: 0px auto 20px auto;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
}

/* ROAD MAP SECTION CSS FOR MOBILE STARTS HERE */
.kr-milestones-mob-en {
    margin: 20px 0px 50px 0px;
    text-align: center;
    position: relative;
}
.kr-milestones-mob-en img.rd-img-en {
    max-width: 100%;
    height: auto;
}
.kr-milestones-mob-en h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: 600;
    /* color: $mred; */
    color: $vdblue;
    padding: 40px 0px;
    text-align: center;
}
.kr-milestone-2017-mob-en {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 50%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: left;
    line-height: initial !important;
}
/* ROAD MAP SECTION CSS FOR MOBILE ENDS HERE */

.milestone-wrp h4 {
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    color: $vdblue;
}

.milestone-wrp p {
    
  font-family: 'Poppins', sans-serif;
    font-size: 13px;
}

.milestone-wrp img.imgSize {
    width: 40px;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .kr-aboutus-en .kr-about-banner-en h2 {
        width: 100%;
        font-size: 35px;
        text-align: center;
        padding-bottom: 15px;
    }
    /* .kr-about-banner-en p {
        float: left;
        width: 100%;
  font-family: 'Poppins', sans-serif;
        font-size: 16px;
        color: $vdblue;
        text-align: center;
        margin-bottom: 15px;
    } */
    .kr-aboutus-en .kr-services-en h3 {
        font-family: 'Poppins', sans-serif;
        font-size: 36px;
        color: $mred;
        padding: 40px 0px 20px;
    }
    .kr-ser-wrp-en p {
        
  font-family: 'Poppins', sans-serif;
        font-size: 17px;
    }
    .milestone-wrp h4 {
        font-size: 22px;
        color: $vdblue;
    }
    .milestone-wrp p {
        font-size: 16px;
    }
    
}

.kr-csr-en {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    margin-bottom: 100px;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .kr-csr-en {
        flex-flow: column;
        margin-bottom: 50px;
    }
}
.kr-csr-en h2.kr-csr-title-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    text-align: center;
    margin-bottom: 70px;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .kr-csr-en h2.kr-csr-title-en {
        flex: 0 auto;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 28px;
        font-weight: 600;
        /* color: $mred; */
        color: $vdblue;
        text-align: center;
        margin-bottom: 30px;
    }
}
.kr-csr-lft-en {
    flex: 0 auto;
    /* width: 50%; */
    width: 732px;
    padding-right: 50px;
}
@media all and (min-width: 1200px) and (max-width:1359px) {
    .kr-csr-lft-en {
        width: 50%;
    }
}
@media all and (min-width:320px) and (max-width: 767px) {
    .kr-csr-lft-en {
        flex: 0 auto;
        width: 100%;
        padding: 0px;
    }
}
.kr-csr-lft-en p.csr-txt-en {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    margin-bottom: 10px;
}
.kr-csr-lft-en p.csr-lnktxt-en {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    margin-top: 20px;
    margin-bottom: 10px;
}
p.csr-lnktxt-en a {
    color: $mred;
    text-decoration: none;
}
p.csr-lnktxt-en a:hover {
    color: $mred;
    text-decoration: underline;
}
.kr-csr-lft-en h6.csr-hd-en {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 25px;
    margin-top: 25px;
}
.kr-csr-rgt-en {
    flex: 0 auto;
    width: 568px;
}
@media all and (min-width: 1200px) and (max-width:1359px) {
    .kr-csr-rgt-en {
        width: 50%;
    }
}
@media all and (min-width:320px) and (max-width: 767px) {
    .kr-csr-rgt-en {
        width: 100%;
    }
}
.kr-csr-rgt-en img {
    width: 100%;
    height: 100%;
}
@media all and (min-width:320px) and (max-width: 767px) {

.abt-banner-txt-en {
    float: left;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: $vdblue;
    line-height: 23px;
    margin-top: 5px;
    padding-right: 0px;
  }

  .abt-sub-title-en {
    float: left;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    color: $vdblue;
    text-align: center;
    margin-top: 10px;
    padding-right: 0px;
    width: 100%;
  }
    .kr-csr-rgt-en img {
        width: 100%;
        height: 100%;
    }

    .kr-ser-wrp-en .kr-serv-card {
        border: solid 1px rgb(0, 66, 106, 0.2);
        min-width: 85%;
        max-width: 85%;
        min-height: 150px;
        border-radius: 10px;
        margin: 20px;
        padding: 30px 28px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        background-color: #fff;
    }
}
/* TAB CSS Starts Here Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
.kr-container-about-en {
    max-width: 100%;
    margin: 0 auto;
    margin-top: 13%;
}
.kr-aboutus-en {
    padding: 20px;
}
.kr-services-en .kr-ser-wrp-en {
    align-items: center;
    justify-content: space-evenly;
    flex-flow: row wrap;
    display: flex;
}
.kr-ser-wrp-en .kr-serv-card {
    border: solid 1px rgb(0, 66, 106, 0.2);
    min-width: 300px;
    max-width: 300px;
    min-height: 320px;
    border-radius: 10px;
    margin: 20px;
    padding: 30px 28px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}
.kr-milestones-en img.rd-img-en {
    /* max-width: 60%; */
    height: auto;
}
.kr-milestone-2017-en {
    top: 119px;
    right: -10px;
    width: 270px;
    font-size: 14px;
}
.kr-milestone-2016-en {
    top: 195px;
    left: 0px;
    width: 290px;
    font-size: 14px;
}
.kr-milestone-2015-en {
    top: 235px;
    right: 0px;
    width: 245px;
    font-size: 14px;
}
.kr-milestone-2014-en {
    top: 344px;
    left: 5px;
    width: 375px;
    font-size: 14px;
}
.kr-milestone-2013-en {
    top: 439px;
    right: 0px;
    width: 135px;
    font-size: 14px;
}
.kr-milestone-2012-en {
    top: 527px;
    left: 30px;
    width: 320px;
    font-size: 14px;
}
.kr-milestone-1987-en {
    top: 655px;
    right: 0px;
    width: 240px;
    font-size: 14px;
}
.kr-csr-en {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    margin-bottom: 100px;
}
.kr-csr-en h2.kr-csr-title-en {
    flex: 0 1 auto;
    width: 100%;
    font-size: 30px;
    font-weight: 600;
    color: $mred;
    text-align: center;
    margin-bottom: 50px;
}
.kr-csr-lft-en {
    flex: 0 1 auto;
    width:100%;
    padding-right: 0px;
}
.kr-csr-rgt-en {
    flex: 0 1 auto;
    width:100%;
    text-align: center;
    margin-top: 20px;
    padding-bottom: 30px;
}
}
/* TAB CSS Ends Here Medium devices (landscape tablets, 768px and up) */