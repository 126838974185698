@import '../../css/mixins.scss';
@import '../../css/themes.scss';

.liabilityinsu-tab-imagebannermain-ar {
    float: left;
    width: 100%;
    background: url(https://cdn.insuranceinbox.com/krImages/products_tabs_bg_01.jpg) no-repeat top center;
    padding: 5% 0px 0px 0px;
    height: 413px;
  background-size: cover;
  background-position: top;
  @include for-tablet-portrait-up {
    height: 465px;
  }
}
@media all and (min-width:320px) and (max-width: 767px) {
    .liabilityinsu-tab-imagebannermain-ar {
        float: left;
        width: 100%;
        background: $white;
        padding: 80px 10px 0px;
        margin-top: 15px;
        height: auto;
}
.liabilityinsu-tab-imagebannermain-ar h1.liabilityinsu-banner-heading-ar {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;    
    color: $vdblue;
    text-align: center;
}
}
.liabilityinsu-banner-con-ar {
    width: 1200px;
    margin: 0px auto;
}
.liabilityinsu-bdy-ar {
    float: left;
    width: 100%;
    margin-bottom: 100px;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .liabilityinsu-banner-con-ar {
        width: 100%;
        margin: 0px auto;
    }
}
/* DESKTOP BANNER BUTTONS CSS STARTS HERE */
.liabilityinsu-tab-btn-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row-reverse wrap;
    margin-top: 20px;
    @include for-tablet-portrait-up {
        margin-top: 10px;
    }
    .liabilityinsu-tab-btn-item-ar {
        flex: 0 auto;
        background: $white;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: $vdblue;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
    .liabilityinsu-tab-btn-item-active-ar {
        flex: 0 auto;
        background: $vdblue !important;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
}
/* DESKTOP BANNER BUTTONS CSS ENDS HERE */
.liabilityinsutab-banne-sec-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}
.liabilityinsutab-banne-sec-ar h1.liabilityinsu-banner-heading-ar {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;
    padding-top: 20px;
    @include for-tablet-portrait-up {
        padding-top: 40px;
    }
}
@media all and (min-width:320px) and (max-width: 767px) {
    .liabilityinsutab-banne-sec-ar h1 {
        flex: 0 auto;
        font-size: 22px;
        font-weight: bold;
        color: $mred;
        padding-top: 40px;
    }
}

@media screen and (max-width: 650px) {
}
@media screen and (max-width: 400px) {
}

/* RESPONSIVE CSS STARTS HERE  */

@media all and (min-width:320px) and (max-width: 767px) {
}
/* TAB CSS STARTS HERE Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
.liabilityinsu-tab-imagebannermain-ar {
    width: 100%;
}
.liabilityinsu-banner-con-ar {
    width: 98%;
    margin: 0px auto;
    padding: 20px;
}
}
/* TAB CSS ENDS HERE Medium devices (landscape tablets, 768px and up) */

.liabilityinsu-dflex-ar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row;
    width: 100%;
    padding-top: 25px;
}
.liabilityinsu-dlex-lft-ar {
    flex: 0 auto;
    width: 600px;
    padding-left: 50px;
}
.liabilityinsu-dlex-lft-ar h2.pagetitle-ar {
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.liabilityinsu-dlex-lft-ar p.pg-txt-con-ar {
    text-align: right;
    margin: 0px 0px 1rem 0px;
    padding: 0px 0px 0px 0px;
}
.liabilityinsu-dlex-lft-ar p.pg-txt-con-ar a {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #b10e1f;
    text-decoration: none;
}
.liabilityinsu-dlex-lft-ar p.pg-txt-con-ar a:hover {
    text-decoration: underline;    
}
.liabilityinsu-dlex-lft-ar h3.whykrinsu-title-en {
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: 600;
    color: $vdblue;
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
h3.liabilityinsu-whykrinsu-title-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.liabilityinsu-pins-list-dksp-en {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
}
.liabilityinsu-pins-list-dksp-en .liabilityinsu-lt-one-dksp-en, .liabilityinsu-pins-list-dksp-en .liabilityinsu-lt-two-dksp-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $vdblue;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.liabilityinsu-pins-list-dksp-en .liabilityinsu-lt-one-dksp-en img, .liabilityinsu-pins-list-dksp-en .liabilityinsu-lt-two-dksp-en img {
    width: 15px;
    margin-right: 10px;
}
.liabilityinsu-pins-list-dksp-en .liabilityinsu-lt-one-dksp-en {
    width: 20%;
}
.liabilityinsu-pins-list-dksp-en .liabilityinsu-lt-two-dksp-en {
    width: 80%;
}
.liabilityinsu-pins-list-lft-ar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
}
.liabilityinsu-pins-list-lft-ar .liabilityinsu-lt-one-lft-ar, .liabilityinsu-pins-list-lft-ar .liabilityinsu-lt-two-lft-ar {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.liabilityinsu-pins-list-lft-ar .liabilityinsu-lt-one-lft-ar img, .liabilityinsu-pins-list-lft-ar .liabilityinsu-lt-two-lft-ar img {
    width: 15px;
    margin-left: 10px;
}
.liabilityinsu-pins-list-lft-ar .liabilityinsu-lt-one-lft-ar {
    width: 25%;
}
.liabilityinsu-pins-list-lft-ar .liabilityinsu-lt-two-lft-ar {
    width: 75%;
}
h3.liabilityinsu-page-sbu-title-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.liabilityinsu-pins-list-dsk-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
    .liabilityinsu-pins-list-dsk-itemimg-ar {
        flex: 0 auto;
        width: 2%;
        text-align: right;
        margin-bottom: 15px;
        img {
            width: 15px;
        }
    }
    .liabilityinsu-pins-list-dsk-item-ar {
        flex: 0 auto;
        width: 97%;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: $black;
        text-align: right;
        margin-bottom: 15px;
        margin-right: 5px;
    }
}
.liabilityinsu-pins-list-dsk-item-ar a {
    color: #b10e1f;
    text-decoration: none;
}
.liabilityinsu-pins-list-dsk-item-ar a:hover {
    color: #b10e1f;
    text-decoration: underline;
}
.liabilityinsu-pins-list-en {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    margin: 20px 0px;
}
.liabilityinsu-pins-list-en .liabilityinsu-lt-one-en, .liabilityinsu-pins-list-en .liabilityinsu-lt-two-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $vdblue;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.liabilityinsu-pins-list-en .liabilityinsu-lt-one-en img, .liabilityinsu-pins-list-en .liabilityinsu-lt-two-en img {
    width: 15px;
    margin-right: 10px;
}
.liabilityinsu-pins-list-en .liabilityinsu-lt-one-en {
    width: 46%;
}
.liabilityinsu-pins-list-en .liabilityinsu-lt-two-en {
    width: 54%;
}
.liabilityinsu-dlex-rgt-ar {
    flex: 0 auto;
    width: 600px;
}
.liabilityinsu-dlex-rgt-ar img {
    width: 100%;
}
p.liabilityinsu-txt-con-ar {
    flex: 0 auto;
    width: 100%;
    text-align: right;
}
h4.liabilityinsu-keyfet-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: $vdblue;
    text-align: right;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.liabilityinsu-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}
.liabilityinsu-keyfeatures-q-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin: 0px 0px;
    padding: 0px;
}
.liabilityinsu-keyfeatures-q-en img {
    width: 15px;
    margin-right: 3px;
    margin-top: -3px;
}
.liabilityinsu-keyfeatures-ans-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin: 0px 0px 25px 23px;
    padding: 0px;
}
.liabilityinsu-keypoint-en {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}
.liabilityinsu-keypoint-itemimg-en {
    flex: 0 auto;
    width: 3%;
    margin-bottom: 15px;
}
.liabilityinsu-keypoint-itemimg-en img {
    width: 15px;
    margin-top: -2px;
}
.liabilityinsu-keypoint-item-en {
    flex: 0 auto;
    width: 96%;
    margin-left: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin-bottom: 15px;
}

.liabilityinsu-kpnt-en {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}
.liabilityinsu-kpnt-itemimg-en {
    flex: 0 auto;
    width: 2%;
    margin-bottom: 15px;
}
.liabilityinsu-kpnt-itemimg-en img {
    width: 15px;
    margin-top: -2px;
}
.liabilityinsu-kpnt-item-en {
    flex: 0 auto;
    width: 97%;
    margin-left: 0px;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin-bottom: 15px;
}


/* MOBILE SECTION CSS STARTS HERE */
.liabilityinsu-mob-main-ar {
    float: left;
    width: 100%;
    padding: 72px 15px 20px;
    margin: 0px 0px 0px 0px;
}
.liabilityinsu-mob-main-ar h2.liabilityinsu-mob-main-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin: 0px 0px 20px;
    padding: 0px;
}
.liabilityinsu-mob-img-ar {
    width: 100%;
    display: block;
    margin: 20px 0px;
}
.liabilityinsu-mob-img-ar img {
    width: 100%;
}
.liabilityinsu-mob-txt-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: right;
    margin: 0px 0px 20px;
    padding: 0px;
}
.liabilityinsu-mob-txt-ar a {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: #b10e1f;
    text-decoration: none;
}
.liabilityinsu-mob-txt-ar a:hover {
    color: #b10e1f;
    text-decoration: underline;
}
h3.liabilityinsu-whykrinsu-mob-title-ar {
    float:left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    text-align: right;
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}
.liabilityinsu-pins-list-mob-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
}
.liabilityinsu-pins-list-mob-itemimg-ar {
    flex: 0 auto;
    width: 3%;
    margin-bottom: 15px;
    padding-top: 3px;
}
.liabilityinsu-pins-list-mob-itemimg-ar img {
    width: 22px;
}
.liabilityinsu-pins-list-mob-item-ar {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: right;
    margin-left: 20px;
    margin-bottom: 15px;
}
.liabilityinsu-pins-list-mob-item-ar a {
    color: #b10e1f;
    text-decoration: none;
}
.liabilityinsu-pins-list-mob-item-ar a:hover {
    color: #b10e1f;
    text-decoration: underline;
}
.liabilityinsu-mob-whykrlist-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}
.liabilityinsu-mob-whykrlist-item-ar {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    text-align: right;
    margin-bottom: 15px;
}
.liabilityinsu-mob-whykrlist-item-ar img {
    width: 21px;
    margin-right: 5px;
}
h3.liabilityinsu-subtitle-ar, h4.liabilityinsu-subtitle-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px;
}
.liabilityinsu-mob-keyfeatures-en {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}
.liabilityinsu-mob-keyfeatures-img-en {
    flex: 0 auto;
    width: 3%;
}
.liabilityinsu-mob-keyfeatures-img-en img {
    width: 20px;
    padding-top: 5px
}
.liabilityinsu-mob-keyfeatures-q-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin: 0px 0px 20px 20px;
    padding: 0px;
}
.liabilityinsu-mob-keyfeatures-ans-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin: 10px 10px 20px 8%;
    padding: 0px;
}
.dropdown-sec {
    background: #F7F7F7;
    padding: 30px 20px 20px 20px;
    position: fixed;
    text-align: center;
    left: 0px;
    right: 0px;
    top: 65px;
    border-bottom: #ECECEC solid 1px;
}
.form-select {
    font-family: 'Poppins', sans-serif;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: $vdblue !important;
    height: 45px !important;
    
}
.form-select option {
    margin: 10px 0px !important;
    font-size: 13px !important;
    color: #000 !important;
}
/* MOBILE SECTION CSS STARTS HERE */