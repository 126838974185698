@import '../../css/themes.scss';
@import '../../css/mixins.scss';

.homemaidinsu-tab-imagebannermain-ar {
    float: left;
    width: 100%;
    background: url(https://cdn.insuranceinbox.com/krImages/products_tabs_bg_01.jpg) no-repeat top center;
    padding: 5% 0px 0px 0px;
    height: 413px;
  background-size: cover;
  background-position: top;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .homemaidinsu-tab-imagebannermain-ar {
        float: left;
        width: 100%;
        background: $white;
        padding: 80px 10px 0px;
        margin-top: 15px;
        height: auto;
}
.homemaidinsu-tab-imagebannermain-ar h1.homemaidinsu-banner-heading-ar {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;    
    color: $vdblue;
    text-align: center;
}
}
.homemaidinsu-banner-con-ar {
    width: 1200px;
    margin: 0px auto;
}
.homemaidinsu-bdy-ar {
    float: left;
    width: 100%;
    margin-bottom: 100px;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .homemaidinsu-banner-con-ar {
        width: 100%;
        margin: 0px auto;
    }
}
/* DESKTOP BANNER BUTTONS CSS STARTS HERE */
.homemaidinsu-tab-btn-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row-reverse wrap;
    margin-top: 20px;
    @include for-tablet-portrait-up {
        margin-top: 10px;
    }
    .homemaidinsu-tab-btn-item-ar {
        flex: 0 auto;
        background: $white;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: $vdblue;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
    .homemaidinsu-tab-btn-item-active-ar {
        flex: 0 auto;
        background: $vdblue !important;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
}
/* DESKTOP BANNER BUTTONS CSS ENDS HERE */
.homemaidinsutab-banne-sec-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}
.homemaidinsutab-banne-sec-ar h1.homemaidinsu-banner-heading-ar {
    flex: 0 auto;
    padding-top: 80px;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .homemaidinsutab-banne-sec-ar h1 {
        flex: 0 auto;
        font-size: 22px;
        font-weight: bold;
        color: $mred;
        padding-top: 40px;
    }
}

@media screen and (max-width: 650px) {
}
@media screen and (max-width: 400px) {
}

/* RESPONSIVE CSS STARTS HERE  */

@media all and (min-width:320px) and (max-width: 767px) {
}

/* ACCORDING CSS STARTS HERE **** /

/*Acco*/
.acc-container {
    max-width: 100%;
    width: 100%;
  }
  .acc {
    margin-bottom: 10px;
  }  
  .acc-head-en {
    background-color: #ccc;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    padding: 8px 10px;
    margin: 0px;
    position: relative;
    cursor: pointer;
   }
   .acc-head-en.active {
     background-color: #b10e1f;
     color: #fff;
    }
   .acc-head-en::before,
   .acc-head-en::after {
    content: '';
    position: absolute;
    top: 50%;
    background-color: #fff;
    transition: all .3s;
   }
   .acc-head-en::before {
    right: 20px;
    width: 3px;
    height: 20px;
    margin-top: -10px;
  }
  .acc-head-en::after {
    right: 11px;
    width: 20px;
    height: 3px;
    margin-top: -2px;
  }
  .acc-head-en p {
    padding: 0px 18px 0px 0px;
    margin: 0px;
   }
  .acc-content {
    padding: 15px 10px;
    display: none;
  } 
  .acc-head-en.active::before {
      transform: rotate(90deg);
  }

.acc-content h2.person-hdtitle-en {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 700;
    
    color: $vdblue;
}
.acc-content h3.person-subtitle-en {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    
    color: $vdblue;
    margin: 20px 0px 10px;
}
.acc-content h4.person-sub-twotitle-en {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    margin: 20px 0px 10px;
}
.acc-content p.txt-en {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    padding: 0px;
    margin: 0px 0px 10px;
}
.acc-content .tab-keyfeatures-mob-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 20px;
}
.acc-content .plin-mob-img-en {
    width: 100%;
    display: block;
    margin: 20px 0px;

}
.acc-content .plin-mob-img-en img {
    width: 100%;
}
/* TAB CSS STARTS HERE Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
.homemaidinsu-tab-imagebannermain-ar {
    width: 100%;
}
.homemaidinsu-banner-con-ar {
    width: 98%;
    margin: 0px auto;
    padding: 20px;
}
}
/* TAB CSS ENDS HERE Medium devices (landscape tablets, 768px and up) */

.homemaidinsu-dflex-ar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row;
    width: 100%;
    padding-top: 25px;
}
.homemaidinsu-dlex-lft-ar {
    flex: 0 auto;
    width: 600px;
    padding-left: 40px;
}
.homemaidinsu-dlex-lft-ar h2.pagetitle-ar {
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.homemaidinsu-dlex-lft-ar p.pg-txt-con-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 0px 0px 1rem 0px;
    padding: 0px 0px 0px 0px;
}
h3.homemaidinsu-whykrinsu-title-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
h3.homemaidinsu-page-sbu-title-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 5px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.homemaidinsu-pins-list-ar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    margin: 20px 0px;
    width: 100%;
}
.homemaidinsu-pins-list-ar .homemaidinsu-lt-one-ar, .homemaidinsu-pins-list-ar .homemaidinsu-lt-two-ar {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    text-align: right;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.homemaidinsu-pins-list-ar .homemaidinsu-lt-one-ar img, .homemaidinsu-pins-list-ar .homemaidinsu-lt-two-ar img {
    width: 15px;
    margin-left: 10px;
}
.homemaidinsu-pins-list-ar .homemaidinsu-lt-one-ar {
    width: 25%;
}
.homemaidinsu-pins-list-ar .homemaidinsu-lt-two-ar {
    width: 75%;
}
.homemaidinsu-dlex-rgt-ar {
    flex: 0 auto;
    width: 600px;
}
.homemaidinsu-dlex-rgt-ar img {
    width: 100%;
}
p.homemaidinsu-txt-con {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    flex: 0 auto;
    width: 100%;
}
h4.homemaidinsu-keyfet-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: $vdblue;
    text-align: right;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.homemaidinsu-keyfeatures-ar-ar-en {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}
.homemaidinsu-keyfeatures-ar-ar-q-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin: 0px 0px;
    padding: 0px;
}
.homemaidinsu-keyfeatures-ar-ar-q-en img {
    width: 15px;
    margin-right: 3px;
    margin-top: -3px;
}
.homemaidinsu-keyfeatures-ar-ar-ans-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin: 0px 15px 25px 0px;
    padding: 0px;
}
.homemaidinsu-keyfeatures-ar-ar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 20px;
}
p.homemaidinsu-lifinsu-fet-ar {
    flex: 0 1 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: right;
    margin: 0px 10px 15px 0px;
    padding: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}
p.homemaidinsu-lifinsu-fet-ar img {
    width: 15px;
    margin-left: 7px;
    margin-top: 7px;
}
p.homemaidinsu-subtxt-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: right;
    padding-left: 4%;
}

/* MOBILE SECTION CSS STARTS HERE */
.homemaidinsu-mob-main-ar {
    float: left;
    width: 100%;
    padding: 72px 15px 20px;
    margin: 0px 0px 0px 0px;
}
.homemaidinsu-mob-main-ar h2.homemaidinsu-mob-main-title-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin: 0px 0px 20px;
    padding: 0px;
}
.homemaidinsu-mob-img-ar {
    width: 100%;
    display: block;
    margin: 20px 0px;
}
.homemaidinsu-mob-img-ar img {
    width: 100%;
}
.homemaidinsu-mob-txt-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: right;
    margin: 0px 0px 20px;
    padding: 0px;
}
h3.homemaidinsu-whykrinsu-mob-title-ar {
    float:left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    text-align: right;
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}
.homemaidinsu-mob-whykrlist-ar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
}
.homemaidinsu-mob-whykrlist-item-ar {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    text-align: right;
    margin-bottom: 15px;
}
.homemaidinsu-mob-whykrlist-item-ar img {
    width: 22px;
    margin-left: 5px;
}
h3.homemaidinsu-subtitle-en, h4.homemaidinsu-subtitle-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    text-align: right;
    margin: 10px 0px;
}
.homemaidinsu-mob-keyfeatures-ar {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    .homemaidinsu-mob-keyfeatures-img-ar {
        flex: 0 auto;
        width: 3%;
        text-align: right;
        img {
            width: 22px;
            margin-top: 4px;
        }
    }
    .homemaidinsu-mob-keyfeatures-q-ar {
        flex: 0 auto;
        width: 89%;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: $black;
        text-align: right;
        margin: 0px 15px 20px 0px;
        padding: 0px;
    }
    .homemaidinsu-mob-keyfeatures-ans-ar {
        flex: 0 auto;
        width: 90%;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: $black;
        text-align: right;
        margin: 0px 0px 15px 10%;
        padding: 0px;
    }
}
/* MOBILE SECTION CSS STARTS HERE */