@import '../css/themes.scss';
@import '../css/mixins.scss';

@media all and (max-width: 767px) {
    .hidden-mobile {
      display: none;
    }
 
  }
 
  @media all and (min-width: 768px) and (max-width: 991px) {
 
    .hidden-desktop {
      display: none;
    }
  }
  @media all and (min-width: 992px) and (max-width: 1199px) {
    .hidden-md-device {
      display: none;
    }
  }
  @media all and (min-width: 1200px) {
    .hidden-lg {
      display: none !important;
    }
  }
 
  .main-fluid .kr-header-ar {
    box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.13);
    -webkit-box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0px 15px 15px 0px rgba(0, 0, 0, 0.13);
    position: fixed;
    width: 100%;
    z-index: 100;
    background-color: $white;
    min-height: 65px;
    border-bottom: solid 0px rgb(39, 153, 137, 50%);
  }
 
  .kr-header-ar .hr-container-ar {
    display: flex;
    flex-flow: row wrap;
    height: 65px;
    align-items: center;
    margin: 0px auto;
    @include for-tablet-portrait-up {
      max-width: 97%;
      min-width: 97%;
      height: 80px;
    }
    @include for-tablet-landscape-up {
      max-width: 95%;
      min-width: 95%;
    }
    @include for-phone-only {
      max-width: 90%;
      min-width: 90%;
      height: 80px;
    }
  }
 
  .hr-container-ar .logo-ar {
    height: 65px;
    padding: 0px 0px 0px 0px;
    flex: 1 auto;
    width: 30%;
    justify-content: flex-start;
    text-align: right;
  }
 
  .logo-ar img {
    min-width: 185px;
    max-width: 185px;
    margin-top:3px;
  }
 
  .hr-container-ar .hr-navigation-ar {
    flex: 1 auto;
    width: 70%;
    color: #323232;
    justify-content: flex-start;
    display: -webkit-box;
    /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;
    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;
    /* TWEENER - IE 10 */
    display: -webkit-flex;
    /* NEW - Chrome */
    display: flex;
    align-items: center;
    position: relative;
    font-family: 'Noto Sans Arabic', sans-serif !important; 
  } 
  .hr-navigation-ar a {
    font-family: 'Noto Sans Arabic', sans-serif !important; 
    font-size: 15px;
    color: $vdblue;
    flex: 0 auto;
    text-decoration: none;
    padding-right: 10px;
  }  
  .hr-navigation-ar div {
    display: inline-block;
    font-family: 'Noto Sans Arabic', sans-serif !important; 
    font-size: 15px;
    color: #999;
    font-weight: 600;
    flex: 0 auto;
    text-decoration: none;
    padding-right: 10px;
    cursor: pointer;
  } 
  .hr-navigation-ar a.services, .hr-navigation-ar span.services {
    font-family: 'Noto Sans Arabic', sans-serif !important;
   border: solid 0px $gray !important;
   color: $gray;
   -moz-border-radius: 0px;
   -webkit-border-radius: 0px;
   -khtml-border-radius: 0px;
   border-radius: 0px;
   padding: 8px 5px;
   cursor: pointer;
   text-decoration: none;
   flex: 0 auto;
   align-items: center;
   justify-content: center;
   margin: 0px 17px 0px 0px;
   font-weight: 600;
   text-transform: none;
   @include for-desktop-up {
    margin: 0px 0px 0px 10px;
   }
  }
  .hr-navigation-ar a.services:hover, .hr-navigation-ar div.services:hover {
    font-family: 'Noto Sans Arabic', sans-serif !important; 
   color: $vdblue;
   border:solid 0px $vdblue !important;
  }
 
  .hr-navigation-ar a.contactus-btn {
    font-family: 'Noto Sans Arabic', sans-serif !important; 
    border: solid 1px $mcany !important;
    color: $mcany !important;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    -khtml-border-radius: 20px;
    border-radius: 20px;
    padding: 8px 20px;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
    flex: 0 auto;
    align-items: center;
    justify-content: center;
    margin: 0px 5px 0px 5px;
    font-weight: 600;
   text-transform: uppercase;
  } 
  @media all and (min-width:1026px) and (max-width: 1280px) {
    .hr-navigation-ar a.services, .hr-navigation-ar div.services {
      font-family: 'Noto Sans Arabic', sans-serif !important; 
      margin: 0px 5px 0px 0px;
    }
    .hr-navigation-ar a.contactus-btn {
      padding: 8px 12px;
    }
  }
  .hr-navigation-ar .fa {
    font: normal normal normal 25px/1 FontAwesome !important;
  }
 
  /****swiper switch css***/
  .hr-navigation-ar .switch {
    position: relative;
    display: inline-block;
    margin: 0px 20px 0px 10px;
    // display: none;
  }
   .switch>span {
    position: absolute;
    top: 10px;
    pointer-events: none;
    font-family: 'Helvetica', Arial, sans-serif;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
    width: 50%;
    text-align: center;
    justify-content: center;
    display: -webkit-box; 
    display: -moz-box; 
    display: -ms-flexbox; 
    display: -webkit-flex; 
    display: flex;
    align-items: center;
  }
 
  .switch>span img {
    margin-right: 10px;
  }
 
  input.check-toggle-round-flat:checked~.off {
    color: $vdblue;
  }
 
  input.check-toggle-round-flat:checked~.on {
    color: $white;
  }
 
  .switch>span.on {
    left: 0;
    padding-left: 2px;
    color: $vdblue;
  }
 
  .switch>span.off {
    right: 0;
    padding-right: 4px;
    color: $white;
  }
 
  .check-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }
 
  .check-toggle+label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
 
  input.check-toggle-round-flat+label {
    padding: 2px;
    width: 130px;
    height: 35px;
    background-color: $vdblue;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  }
 
  input.check-toggle-round-flat+label:before,
  input.check-toggle-round-flat+label:after {
    display: block;
    position: absolute;
    content: "";
  }
  input.check-toggle-round-flat+label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px; 
    background-color: $vdblue;
    -webkit-border-radius: 60px; 
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
    border-radius: 60px;
  } 
  input.check-toggle-round-flat+label:after {
    top: 1px;
    left: 1px;
    bottom: 1px;
    width: 65px;
    background-color: $white;
    -webkit-border-radius: 52px;
    -moz-border-radius: 52px;
    -ms-border-radius: 52px;
    -o-border-radius: 52px;
    border-radius: 52px;
    -webkit-transition: margin 0.2s;
    -moz-transition: margin 0.2s;
    -o-transition: margin 0.2s;
    transition: margin 0.2s;
    border: solid 1px $mred;
  }
 
  input.check-toggle-round-flat:checked+label {}
 
  input.check-toggle-round-flat:checked+label:after {
    margin-left: 62px;
  }
 
  @media all and (min-width:320px) and (max-width: 767px) {
    .hr-container-ar .logo-ar {
      margin: 10px 0px 0px 0px;
    }
    .logo-ar img {
     min-width: 135px;
     max-width: 135px;
   }
    .hr-navigation-ar .switch {
      margin: 0px 0px 0px 112px;
      display: block;
    }
 
    .navigation__nav .navigation__link:link {
      font-size: 1.0rem;
    }
 
    .hr-navigation-ar .navigation__button {
      top: -7px !important;
      right: 17px !important;
    }
 
    .hr-navigation-ar .navigation__icon {
      margin-top: 1.3rem;
    } 
    .hr-navigation-ar .navigation__nav ul.navigation__list {
      padding: 0rem;
      top: 30%;
    }
 
    .navigation__icon,
    .navigation__icon::before,
    .navigation__icon::after {
      width: 1.5rem !important;
    }
 
    .hr-navigation-ar a.contactus-btn {
      width: 200px;
    }
 
  }
 
  /******** mobile navigation starts here *******/
  .hr-navigation-ar .navigation {
    flex: 0 auto;
    width: 40px;
  }
 
  .hr-navigation-ar .navigation__checkbox {
    display: none;
  }
 
  .hr-navigation-ar .navigation__button {
    position: absolute;
    top: -10px;
    left: -1px;
    height: 2.7rem;
    width: 2.7rem;
    text-align: center;
    background-color: $white;
    border-radius: 50%;
    z-index: 300;
    box-shadow: none;
    cursor: pointer;
  }
 
  @media screen and (min-width: 768px) {
    .navigation__button {
      top: 0px;
      right: 0px;
      height: 3rem;
      width: 3rem;
    }
  }
 
  .hr-navigation-ar .navigation__background {
    position: absolute;
    top: -4px;
    left: 0px;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background-color: rgb(0, 48, 41, 95%);
    background-size: cover;
    background-position: center;
    z-index: 100;
    transition: all 800ms cubic-bezier(0.86, 0, 0.07, 1);
    @include for-tablet-portrait-up {
      top: -6px;
    }
    @include for-desktop-up {
      top: -6px;
    }
    @include for-big-desktop-up {
      top: -5px;
    }
  }
 
  @media screen and (min-width: 768px) {
    .navigation__background {
      top: 4.5rem;
      right: 4.5rem;
      height: 6rem;
      width: 6rem;
    }
  }
 
  .hr-navigation-ar .navigation__nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    opacity: 0;
    width: 0;
    visibility: hidden;
    z-index: 200;
    transition: all 800ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
 
  .hr-navigation-ar .navigation__list {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    list-style: none;
  }
 
  .hr-navigation-ar .navigation__item {
    margin: 1rem;
  }
 
  .navigation__link:link,
  .navigation__link:visited {
    display: inline-block;
    padding: 1rem 2rem;
    text-transform: uppercase;
    color: #f4f4f4;
    font-size: 2.4rem;
    text-decoration: none;
    transition: all .2s;
    font-family: 'Poppins', sans-serif;
  }
 
  @media(min-width: 768px) { 
    .navigation__link:link,
    .navigation__link:visited {
      font-size: 1rem;
      font-weight: 600;
    }
  }
 
  .navigation__link span {
    margin-right: 1.5rem;
    display: inline-block;
  }
 
  .navigation__link:hover {
    color: $mcany;
    transform: scale(1.1);
  }
 
  .navigation__checkbox:checked~.navigation__background {
    transform: scale(300)
  }
 
  .navigation__checkbox:checked~.navigation__nav {
    width: 100%;
    visibility: visible;
    opacity: 1;
    background-color: rgb(0, 48, 41, 15%);
  }
 
  .hr-navigation-ar .navigation__icon {}
 
  .navigation__icon {
 
    position: relative;
    margin-top: 2.5rem;
  }
 
  @media screen and (min-width: 768px) {
    .navigation__icon {
      margin-top: 1.3rem;
    }
  }
 
  .navigation__icon,
  .navigation__icon::before,
  .navigation__icon::after {
    display: inline-block;
    width: 2.2rem;
    height: 2px;
    background-color: #4A676A;
  }
 
  @media (min-width: 768px) {
 
    .navigation__icon,
    .navigation__icon::before,
    .navigation__icon::after {
      width: 1.2rem;
    }
  }
 
  .navigation__icon::before,
  .navigation__icon::after {
    content: '';
    position: absolute;
    left: 0;
    transition: all 200ms;
  }
 
  .navigation__icon::before {
    top: -.4rem;
  }
 
  .navigation__icon::after {
    top: .4rem;
  }
 
  .navigation__button:hover .navigation__icon::before {
    top: -0.4rem;
  }
 
  .navigation__button:hover .navigation__icon::after {
    top: 0.4rem;
  }
 
  .navigation__checkbox:checked+.navigation__button .navigation__icon {
    background-color: transparent;
  }
 
  .navigation__checkbox:checked+.navigation__button .navigation__icon::before {
    top: 0;
    transform: rotate(135deg);
    @media all and (min-width:320px) and (max-width: 767px) {
      top: 0px;
    }
  }
 
  .navigation__checkbox:checked+.navigation__button .navigation__icon::after {
    top: 0;
    transform: rotate(-135deg);
    @media all and (min-width:320px) and (max-width: 767px) {
      top: 0px;
    }
  }
 
  @media all and (min-width:320px) and (max-width: 540px) {
    .hr-navigation-ar .navigation__background {
      right: 21px;
    }
 
    .hr-container-ar .hr-navigation-ar {
      justify-content: center;
      width: 20%;
      padding-right: 20px;
    }
 
  }