@import '../css/themes.scss';
@import '../css/mixins.scss';

.privacy-policy-bannermain-ar {
    float: left;
    width: 100%;
    background: url(https://cdn.insuranceinbox.com/krImages/products_tabs_bg_01.jpg) no-repeat top center;
    padding: 5% 0px 0px 0px;
    height: 413px;
  background-size: cover;
  background-position: top;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .privacy-policy-bannermain-ar {
        float: left;
        width: 100%;
        background: $white;
        padding: 80px 20px 0px;
        margin-top: 15px;
        height: auto;
}
.privacy-policy-bannermain-ar h1.privacy-policy-heading-ar {
    display: block;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: $mred;
    text-align: center;
    padding-bottom: 10px;
}
}
.privacy-policy-banner-con-ar {
    width: 1200px;
    margin: 0px auto;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .privacy-policy-banner-con-ar {
        width: 100%;
        margin: 0px auto;
    }
}
.privacy-policy-banner-sec-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    height: 350px;
}
.privacy-policy-banner-sec-ar h1.privacy-policy-heading-ar {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .privacy-policy-banner-sec-ar h1 {
        flex: 0 auto;
        font-size: 22px;
        font-weight: bold;
        color: $mred;
        padding-top: 40px;
    }
}
.pri-pol-conent-ar {
    float: left;
    width: 100%;
    margin-bottom: 100px;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .pri-pol-conent-ar {
        float: left;
        width: 100%;
        margin-bottom: 20px;
    }
}
.pri-pol-conent-ar h1.privacy-title-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
}
@media all and (min-width:320px) and (max-width: 767px) {
    .pri-pol-conent-ar h1.privacy-title-ar {
        padding-top: 15px;
    }
}
.pri-pol-conent-ar p {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: right;
    margin-bottom: 20px;
}

@media all and (min-width:768px) and (max-width:1200px) {
.privacy-policy-banner-con-ar {
    width: 90%;
    margin: 0px auto;
}
}

