@import '../../css/themes.scss';
@import '../../css/mixins.scss';

.reportclaim-tab-imagebannermain-en {
    float: left;
    width: 100%;
    background: url(https://cdn.insuranceinbox.com/krImages/products_tabs_bg_01.jpg) no-repeat top center;
    padding: 5% 0px 0px 0px;
    height: 413px;
    background-size: cover;
    background-position: top;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .reportclaim-tab-imagebannermain-en {
        float: left;
        width: 100%;
        background: $white;
        padding: 80px 10px 0px;
        margin-top: 15px;
        height: auto;
    }

    .reportclaim-tab-imagebannermain-en h1.reportclaim-banner-heading-en {
        display: block;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-weight: bold;
        color: $vdblue;
        text-align: center;
    }
}

.reportclaim-banner-con-en {
    width: 1200px;
    margin: 0px auto;
}

.reportclaim-bdy-en {
    float: left;
    width: 100%;
    margin-bottom: 100px;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .reportclaim-banner-con-en {
        width: 100%;
        margin: 0px auto;
    }
}
/* DESKTOP BANNER BUTTONS CSS STARTS HERE */
.reportclaim-tab-btn-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row-reverse wrap;
    margin-top: 20px;
    @include for-tablet-portrait-up {
        margin-top: 10px;
    }
    .reportclaim-tab-btn-item-ar {
        flex: 0 auto;
        background: $white;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: $vdblue;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
    .reportclaim-tab-btn-item-active-ar {
        flex: 0 auto;
        background: $vdblue !important;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
}
/* DESKTOP BANNER BUTTONS CSS ENDS HERE */
.reportclaimtab-banne-sec-en {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}
.reportclaimtab-banne-sec-en h1.reportclaim-banner-heading-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    padding-top: 100px;
    width: 100%;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .reportclaimtab-banne-sec-en h1 {
        flex: 0 auto;
        font-size: 22px;
        font-weight: bold;
        color: $mred;
        padding-top: 40px;
    }
}

@media screen and (max-width: 650px) {}

@media screen and (max-width: 400px) {}

/* RESPONSIVE CSS STARTS HERE  */

@media all and (min-width:320px) and (max-width: 767px) {}

/* TAB CSS STARTS HERE Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
    .reportclaim-tab-imagebannermain-en {
        width: 100%;
    }

    .reportclaim-banner-con-en {
        width: 98%;
        margin: 0px auto;
        padding: 20px;
    }
}

/* TAB CSS ENDS HERE Medium devices (landscape tablets, 768px and up) */

.reportclaim-dflex-en {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row;
    width: 100%;
    padding-top: 15px;
}
.reportclaim-bdy-en h2.pagetitle-en {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    margin: 30px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.reportclaim-dlex-lft-en {
    flex: 0 auto;
    width: 600px;
    padding-right: 50px;
    position: relative;
}
.dskclaimform-ar {
    display: block;
    width: 100%;
    height: 100vh;
    // height: 1600px;
    position: relative;
    #ff-compose-Mob {
        .ff-form .ff-powered-img {
            @media only screen and (min-width:320px) and (max-width: 768px) {
                padding: 0px !important;
                width: 50% !important;
            }            
        }
    }
    #ff-compose-Tab {
        .ff-form .ff-powered-img {
            @media only screen and (min-width:320px) and (max-width: 768px) {
                padding: 0px !important;
                width: 50% !important;
            }            
        }
    }
    #ff-compose{
        width:100%;
        // height:100%;
        margin:auto;
        background-color: #ffffff;
        padding: 20px 30px;
        .ff-partial {
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 8px;
            padding: 0.75rem .50rem !important;
            border: 1px solid transparent;
            border-radius: 0.25rem;
            font-size: 14px !important;
            color: #B11D28 !important;
            background-color: rgba(177,29,40, 0.15) !important;
            border-color: rgba(177,29,40, 0.15) !important;
            span a {
                font-size: 14px !important;
                text-decoration: none !important;
                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
        .ff-form {
            padding:0px;
            @media only screen and (min-width:320px) and (max-width: 768px) {
                margin-bottom: 85px !important;
            }
            input[type="text"],
            input[type="email"],
            input[type="tel"],
            input[type="url"],
            .ff-form select,
            textarea {
                background-color: #ffffff !important;
                border: 1px solid #cdcdcd !important;
                &:hover {
                    background-color: #ffffff !important;
                    border: 1px solid #cdcdcd !important;
                }
                &:focus {
                    background-color: #ffffff !important;
                    border: 1px solid #cdcdcd !important;
                }
            }
            .ff-form input[type="email"]:focus {
                background-color: #ffffff !important;
                border: 1px solid #cdcdcd !important;              
            }
            .ff-section h3.ff-title {
                font-family: "Poppins", sans-serif;
                font-size: 36px;
                font-weight: 600;
                color: #00426a;
                margin: 10px 0px 15px 0px;
                padding: 0px 0px 0px 0px;
                margin-left: -10px !important;
            }
            .ff-item {
                padding-bottom: 0px !important;
            }
            .ff-section .ff-secfields .ff-item {
                .ff-widget-error {
                    display: flex !important;
                    justify-content: flex-start !important;
                    align-items: flex-start !important;
                    color: #ab2328 !important;
                    padding: 5px 0px 0px 0px !important;
                    b {
                        flex: 0 auto;
                        margin-top: 3px;
                        width: 20px !important;
                        height: 20px !important;
                        background-color: #ab2328 !important;
                    }
                }
            }
        }
    }
}
.dskclaimform-ar iframe {
    width: 100%;
    position: absolute;
    left: -25px;
}
.reportclaim-dlex-lft-en h2.pagetitle-en {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}

.reportclaim-dlex-lft-en p.reportclaim-pg-txt-con {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    margin: 0px 0px 1rem 0px;
    padding: 0px 0px 0px 0px;
}

.reportclaim-dlex-lft-en h3.reportclaim-whykrinsu-title-en {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}

h3.reportclaim-whykrinsu-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}

h3.reportclaim-page-sbu-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.reportclaim-pins-list-lftdksp-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
}

.reportclaim-pins-list-lftdksp-en .reportclaim-lt-one-lftdksp-en,
.reportclaim-pins-list-lftdksp-en .reportclaim-lt-two-lftdksp-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.reportclaim-pins-list-lftdksp-en .reportclaim-lt-one-lftdksp-en img,
.reportclaim-pins-list-lftdksp-en .reportclaim-lt-two-lftdksp-en img {
    width: 15px;
    margin-right: 10px;
}

.reportclaim-pins-list-lftdksp-en .reportclaim-lt-one-lftdksp-en {
    width: 45%;
}

.reportclaim-pins-list-lftdksp-en .reportclaim-lt-two-lftdksp-en {
    width: 55%;
}

.reportclaim-pins-list-dksp-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
}

.reportclaim-pins-list-dksp-en .reportclaim-lt-one-dksp-en,
.reportclaim-pins-list-dksp-en .reportclaim-lt-two-dksp-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $vdblue;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.reportclaim-pins-list-dksp-en .reportclaim-lt-one-dksp-en img,
.reportclaim-pins-list-dksp-en .reportclaim-lt-two-dksp-en img {
    width: 15px;
    margin-right: 10px;
}

.reportclaim-pins-list-dksp-en .reportclaim-lt-one-dksp-en {
    width: 20%;
}

.reportclaim-pins-list-dksp-en .reportclaim-lt-two-dksp-en {
    width: 80%;
}

.reportclaim-pins-list-dsk-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
}

.reportclaim-pins-list-dsk-itemimg-en {
    flex: 0 auto;
    width: 2%;
    margin-bottom: 15px;
}

.reportclaim-pins-list-dsk-itemimg-en img {
    width: 15px;
}

.reportclaim-pins-list-dsk-item-en {
    flex: 0 auto;
    width: 97%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 15px;
}

.reportclaim-pins-list-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    margin: 20px 0px;
}

.reportclaim-pins-list-en .reportclaim-lt-one-en,
.reportclaim-pins-list-en .reportclaim-lt-two-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $vdblue;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.reportclaim-pins-list-en .reportclaim-lt-one-en img,
.reportclaim-pins-list-en .reportclaim-lt-two-en img {
    width: 15px;
    margin-right: 10px;
}

.reportclaim-pins-list-en .reportclaim-lt-one-en {
    width: 46%;
}

.reportclaim-pins-list-en .reportclaim-lt-two-en {
    width: 54%;
}

.reportclaim-dlex-rgt-en {
    flex: 0 auto;
    width: 600px;
}

.reportclaim-dlex-rgt-en img {
    width: 100%;
}

p.reportclaim-txt-con {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    flex: 0 auto;
    width: 100%;
}

h4.reportclaim-keyfet-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: $vdblue;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}

.reportclaim-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}

.reportclaim-keyfeatures-q-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px;
    padding: 0px;
}

.reportclaim-keyfeatures-q-en img {
    width: 15px;
    margin-right: 3px;
    margin-top: -3px;
}

.reportclaim-keyfeatures-ans-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 25px 23px;
    padding: 0px;
}

.reportclaim-keypoint-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}

.reportclaim-keypoint-itemimg-en {
    flex: 0 auto;
    width: 3%;
    margin-bottom: 15px;
}

.reportclaim-keypoint-itemimg-en img {
    width: 15px;
    margin-top: -2px;
}

.reportclaim-keypoint-item-en {
    flex: 0 auto;
    width: 96%;
    margin-left: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    margin-bottom: 15px;
}

.reportclaim-kpnt-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}

.reportclaim-kpnt-itemimg-en {
    flex: 0 auto;
    width: 2%;
    margin-bottom: 15px;
}

.reportclaim-kpnt-itemimg-en img {
    width: 15px;
    margin-top: 4px;
}

.reportclaim-kpnt-item-en {
    flex: 0 auto;
    width: 97%;
    margin-left: 0px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 15px;
}


/* MOBILE SECTION CSS STARTS HERE */
.reportclaim-mob-main-en {
    float: left;
    width: 100%;
    padding: 72px 0px 20px;
    margin: 0px 0px 0px 0px;
}

.reportclaim-mob-main-en h2.reportclaim-mob-main-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 20px;
    padding: 0px;
}

.reportclaim-mob-img-en {
    width: 100%;
    display: block;
    margin: 20px 0px;
}

.reportclaim-mob-img-en img {
    width: 100%;
}

.reportclaim-mob-txt-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: justify;
    margin: 0px 0px 20px;
    padding: 0px;
}

h3.reportclaim-whykrinsu-mob-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 23px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}

.reportclaim-pins-list-mob-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
}

.reportclaim-pins-list-mob-itemimg-en {
    flex: 0 auto;
    width: 3%;
    margin-bottom: 15px;
    padding-top: 3px;
}

.reportclaim-pins-list-mob-itemimg-en img {
    width: 22px;
}

.reportclaim-pins-list-mob-item-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    margin-left: 20px;
    margin-bottom: 15px;
}

.reportclaim-mob-whykrlist-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}

.reportclaim-mob-whykrlist-item-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    margin-bottom: 15px;
}

.reportclaim-mob-whykrlist-item-en img {
    width: 21px;
    margin-right: 5px;
}

h3.reportclaim-subtitle-en,
h4.reportclaim-subtitle-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px;
}

.reportclaim-mob-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}

.reportclaim-mob-keyfeatures-img-en {
    flex: 0 auto;
    width: 3%;
}

.reportclaim-mob-keyfeatures-img-en img {
    width: 20px;
    padding-top: 5px
}

.reportclaim-mob-keyfeatures-q-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 20px 20px;
    padding: 0px;
}

.reportclaim-mob-keyfeatures-ans-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: justify;
    margin: 10px 10px 20px 8%;
    padding: 0px;
}

.dropdown-sec {
    background: #F7F7F7;
    padding: 30px 20px 20px 20px;
    position: fixed;
    text-align: center;
    left: 0px;
    right: 0px;
    top: 65px;
    border-bottom: #ECECEC solid 1px;
}

.form-select {
    font-family: 'Poppins', sans-serif;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: $vdblue !important;
    height: 45px !important;

}

.form-select option {
    margin: 10px 0px !important;
    font-size: 13px !important;
    color: #000 !important;
}

/* MOBILE SECTION CSS STARTS HERE */


/* Media Query for Mobile Devices */
@media (max-width: 480px) {
    .hidden-mobile {
        display: none;
    }
    .dsk-view {
        display: none;
    }
    .tab-view {
        display: none;
    }
    .mobile-view {
        display: block;
    }
    
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {
.hidden-lowtab {
    display: none;
}
.dsk-view {
    display: none;
}
.tab-view {
    display: none;
}
.mobile-view {
    display: block;
}

}
      
/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
.hidden-portrait-mode-tab {
    display: none;
}
.dsk-view {
    display: none;
}
.tab-view {
    display: block;
}
.mobile-view {
    display: none;
}
.tabreportclaim-dflex-en {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    padding-top: 15px;
}
.tabreportclaim-dlex-lft-en {
    flex: 0 auto;
    width: 100%;
}
h2.tabpagetitle-en {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    margin: 30px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}
.tabreportclaim-dlex-rgt-en {
    flex: 0 auto;
    width: 30%;
    display: none;
}
.teQAzf {
    max-width: 100vw !important;
    margin: 0px !important;
    padding: 0px !important;
}

}
      
/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px){
.hidden-laptop-desktops {
    display: none;
}
.dsk-view {
    display: block;
}
.tab-view {
    display: none;
}
.mobile-view {
    display: none;
}
    
}
      
/* Media Query for Large screens */
@media (min-width: 1281px) {
.hidden-largscreen {
    display: none;
}
.dsk-view {
    display: block;
}
.tab-view {
    display: none;
}
.mobile-view {
    display: none;
}
    
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .reportclaim-mob-main-en {
.ff-form .ff-powered-img {
    width: 50% !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
#ff-compose-Mob {
    .ff-form .ff-powered-img {
        @media only screen and (min-width:320px) and (max-width: 768px) {
            padding: 0px !important;
            width: 50% !important;
        }            
    }
}
#ff-compose-Tab {
    .ff-form .ff-powered-img {
        @media only screen and (min-width:320px) and (max-width: 768px) {
            padding: 0px !important;
            width: 50% !important;
        }            
    }
}
#ff-compose{
    width:100%;
    // height:100%;
    margin:auto;
    background-color: #ffffff;
    // border-radius: 10px;
    // box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    // -webkit-box-shadow: -2px 0px 13px -1px rgb(0 0 0 / 21%);
    // -moz-box-shadow: -2px 0px 13px -1px rgba(0, 0, 0, 0.21);
    padding: 20px 30px;
    .ff-partial {
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 8px;
        padding: 0.75rem .50rem !important;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        font-size: 14px !important;
        color: #B11D28 !important;
        background-color: rgba(177,29,40, 0.15) !important;
        border-color: rgba(177,29,40, 0.15) !important;
        span a {
            font-size: 14px !important;
            text-decoration: none !important;
            &:hover {
                text-decoration: underline !important;
            }
        }
    }
    .ff-form {
        padding:0px;
        @media only screen and (min-width:320px) and (max-width: 768px) {
            margin-bottom: 85px !important;
        }
        input[type="text"],
        input[type="email"],
        input[type="tel"],
        input[type="url"],
        textarea {
            background-color: #ffffff;
            border: 1px solid #cdcdcd;
        }
        textarea:hover, textarea:focus,
        input[type="tel"]:hover, input[type="tel"]:focus,
        input[type="text"]:hover, input[type="text"]:focus, 
        input[type="email"]:hover, input[type="email"]:focus {
            background-color: rgba(242,244,245, 0.15) !important;
            border: 1px solid rgba(242,244,245, 0.9) !important;
        }
        .ff-section h3.ff-title {
            font-family: "Poppins", sans-serif;
            font-size: 36px;
            font-weight: 600;
            color: #00426a;
            margin: 10px 0px 15px 0px;
            padding: 0px 0px 0px 0px;
            margin-left: -10px !important;
        }
        .ff-item {
            padding-bottom: 0px !important;
        }
        .ff-section .ff-secfields .ff-item {
            .ff-widget-error {
                display: flex !important;
                justify-content: flex-start !important;
                align-items: flex-start !important;
                color: #ab2328 !important;
                padding: 5px 0px 0px 0px !important;
                b {
                    flex: 0 auto;
                    margin-top: 3px;
                    width: 20px !important;
                    height: 20px !important;
                    background-color: #ab2328 !important;
                }
            }
        }
    }
}

}
}


// .ff-form input[type="text"], .ff-form input[type="email"], .ff-form input[type="tel"], .ff-form input[type="url"], .ff-form textarea, .ff-form select {
//     width: 100%;
//     border: 1px solid #ccc !important;
//     background: #fff !important;
//     margin: 0 0 5px;
//     padding: 10px;
//     border-radius: 0px;
// }
// .ff-form input:focus, .ff-form textarea:focus, .ff-form select:focus {
//     background: #fff;
//     outline: 0;
//     border: 1px solid #ccc !important;
//     background: #fff !important;
//     border-radius: 0px !important;
// }
// .ff-form .ff-item {
//             padding-block-end: 0px !important;
//         }