@import '../../css/themes.scss';
@import '../../css/mixins.scss';

.riskinspetserv-tab-imagebannermain-ar {
    float: left;
    width: 100%;
    background: url(https://cdn.insuranceinbox.com/krImages/products_tabs_bg_01.jpg) no-repeat top center;
    padding: 5% 0px 0px 0px;
    height: 413px;
    background-size: cover;
    background-position: top;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .riskinspetserv-tab-imagebannermain-ar {
        float: left;
        width: 100%;
        background: $white;
        padding: 80px 10px 0px;
        margin-top: 15px;
        height: auto;
    }

    .riskinspetserv-tab-imagebannermain-ar h1.riskinspetserv-banner-heading-ar {
        display: block;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 32px;
        font-weight: bold;
        color: $vdblue;
        text-align: center;
    }
}

.riskinspetserv-banner-con-ar {
    width: 1200px;
    margin: 0px auto;
}

.riskinspetserv-bdy-ar {
    float: left;
    width: 100%;
    margin-bottom: 100px;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .riskinspetserv-banner-con-ar {
        width: 100%;
        margin: 0px auto;
    }
}
/* DESKTOP BANNER BUTTONS CSS STARTS HERE */
.riskinspetserv-tab-btn-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row-reverse wrap;
    margin-top: 20px;
    @include for-tablet-portrait-up {
        margin-top: 10px;
    }
    .riskinspetserv-tab-btn-item-ar {
        flex: 0 auto;
        background: $white;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: $vdblue;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
    .riskinspetserv-tab-btn-item-active-ar {
        flex: 0 auto;
        background: $vdblue !important;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        padding: 8px 15px;
        margin: 5px;
        cursor: pointer;
        border-radius: 4px;
    }
}
/* DESKTOP BANNER BUTTONS CSS ENDS HERE */
.riskinspetservtab-banne-sec-ar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
}

.riskinspetservtab-banne-sec-ar h1.riskinspetserv-banner-heading-ar {
    flex: 0 auto;
    text-align: center;
    padding-top: 100px;
    width: 100%;
}

@media all and (min-width:320px) and (max-width: 767px) {
    .riskinspetservtab-banne-sec-ar h1 {
        flex: 0 auto;
        font-size: 22px;
        font-weight: bold;
        color: $mred;
        padding-top: 40px;
    }
}

@media screen and (max-width: 650px) {}

@media screen and (max-width: 400px) {}

/* RESPONSIVE CSS STARTS HERE  */

@media all and (min-width:320px) and (max-width: 767px) {}

/* TAB CSS STARTS HERE Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width:1200px) {
    .riskinspetserv-tab-imagebannermain-ar {
        width: 100%;
    }

    .riskinspetserv-banner-con-ar {
        width: 98%;
        margin: 0px auto;
        padding: 20px;
    }
}

/* TAB CSS ENDS HERE Medium devices (landscape tablets, 768px and up) */

.riskinspetserv-dflex-en {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row;
    width: 100%;
    padding-top: 15px;
}
.riskinspetserv-bdy-ar h2.pagetitle-ar {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    margin: 30px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}
.riskinspetserv-dlex-lft-ar {
    flex: 0 auto;
    width: 600px;
    padding-left: 50px;
}
.riskinspetserv-dlex-lft-ar h2.pagetitle-ar {
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.riskinspetserv-dlex-lft-ar p.riskinspetserv-pg-txt-con-ar {
    float: left;
    width: 100%;
    text-align: right;
    margin: 0px 0px 1rem 0px;
    padding: 0px 0px 0px 0px;
}
h3.riskinspetserv-whykrinsu-title-ar {
    text-align: right;
    margin: 0px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
h3.riskinspetserv-page-sbu-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    color: $vdblue;
    text-align: right;
    margin: 10px 0px 15px 0px;
    padding: 0px 0px 0px 0px;
}
.riskinspetserv-pins-list-lftdksp-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
}
.riskinspetserv-pins-list-lftdksp-en .riskinspetserv-lt-one-lftdksp-en,
.riskinspetserv-pins-list-lftdksp-en .riskinspetserv-lt-two-lftdksp-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $vdblue;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.riskinspetserv-pins-list-lftdksp-en .riskinspetserv-lt-one-lftdksp-en img,
.riskinspetserv-pins-list-lftdksp-en .riskinspetserv-lt-two-lftdksp-en img {
    width: 15px;
    margin-right: 10px;
}
.riskinspetserv-pins-list-lftdksp-en .riskinspetserv-lt-one-lftdksp-en {
    width: 45%;
}
.riskinspetserv-pins-list-lftdksp-en .riskinspetserv-lt-two-lftdksp-en {
    width: 55%;
}
.riskinspetserv-pins-list-dksp-ar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-flow: row wrap;
}
.riskinspetserv-pins-list-dksp-ar .riskinspetserv-lt-one-dksp-ar,
.riskinspetserv-pins-list-dksp-ar .riskinspetserv-lt-two-dksp-ar {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: $black;
    text-align: right;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.riskinspetserv-pins-list-dksp-ar .riskinspetserv-lt-one-dksp-ar img,
.riskinspetserv-pins-list-dksp-ar .riskinspetserv-lt-two-dksp-ar img {
    width: 15px;
    margin-left: 10px;
}
.riskinspetserv-pins-list-dksp-ar .riskinspetserv-lt-one-dksp-ar {
    width: 25%;
}
.riskinspetserv-pins-list-dksp-ar .riskinspetserv-lt-two-dksp-ar {
    width: 75%;
}
.riskinspetserv-pins-list-dsk-en {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
}
.riskinspetserv-pins-list-dsk-itemimg-en {
    flex: 0 auto;
    width: 2%;
    margin-bottom: 15px;
}

.riskinspetserv-pins-list-dsk-itemimg-en img {
    width: 15px;
}

.riskinspetserv-pins-list-dsk-item-en {
    flex: 0 auto;
    width: 97%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    margin-bottom: 15px;
}

.riskinspetserv-pins-list-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    margin: 20px 0px;
}

.riskinspetserv-pins-list-en .riskinspetserv-lt-one-en,
.riskinspetserv-pins-list-en .riskinspetserv-lt-two-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $vdblue;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.riskinspetserv-pins-list-en .riskinspetserv-lt-one-en img,
.riskinspetserv-pins-list-en .riskinspetserv-lt-two-en img {
    width: 15px;
    margin-right: 10px;
}

.riskinspetserv-pins-list-en .riskinspetserv-lt-one-en {
    width: 46%;
}

.riskinspetserv-pins-list-en .riskinspetserv-lt-two-en {
    width: 54%;
}
.riskinspetserv-dlex-rgt-ar {
    flex: 0 auto;
    width: 600px;
}

.riskinspetserv-dlex-rgt-ar img {
    width: 100%;
}
p.riskinspetserv-txt-con {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    flex: 0 auto;
    width: 100%;
}

h4.riskinspetserv-keyfet-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: $vdblue;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}

.riskinspetserv-keyfeatures-en {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}

.riskinspetserv-keyfeatures-q-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px;
    padding: 0px;
}

.riskinspetserv-keyfeatures-q-en img {
    width: 15px;
    margin-right: 3px;
    margin-top: -3px;
}

.riskinspetserv-keyfeatures-ans-en {
    flex: 0 auto;
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 25px 23px;
    padding: 0px;
}

.riskinspetserv-keypoint-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    .riskinspetserv-keypoint-itemimg-ar {
        flex: 0 auto;
        width: 3%;
        margin-bottom: 15px;
        img {
            width: 15px;
            margin-top: -2px;
        }
    }
    .riskinspetserv-keypoint-item-ar {
        flex: 0 auto;
        width: 94%;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: $black;
        text-align: right;
        margin-bottom: 15px;
        margin-right: 10px;
    }
}
.riskinspetserv-kpnt-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
}
.riskinspetserv-kpnt-itemimg-en {
    flex: 0 auto;
    width: 2%;
    margin-bottom: 15px;
}
.riskinspetserv-kpnt-itemimg-en img {
    width: 15px;
    margin-top: 4px;
}
.riskinspetserv-kpnt-item-ar {
    flex: 0 auto;
    width: 97%;
    margin-left: 0px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin-bottom: 15px;
}
/* MOBILE SECTION CSS STARTS HERE */
.riskinspetserv-mob-main-ar {
    float: left;
    width: 100%;
    padding: 72px 15px 20px;
    margin: 0px 0px 0px 0px;
}
.riskinspetserv-mob-main-ar h2.riskinspetserv-mob-main-title-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: right;
    margin: 0px 0px 20px;
    padding: 0px;
}

.riskinspetserv-mob-img-ar {
    width: 100%;
    display: block;
    margin: 20px 0px;
}

.riskinspetserv-mob-img-ar img {
    width: 100%;
}

.riskinspetserv-mob-txt-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: $black;
    text-align: justify;
    margin: 0px 0px 20px;
    padding: 0px;
}

h3.riskinspetserv-whykrinsu-mob-title-ar {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 23px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px 20px 0px;
    padding: 0px 0px 0px 0px;
}

.riskinspetserv-pins-list-mob-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0px;
    width: 100%;
    .riskinspetserv-pins-list-mob-itemimg-ar {
        flex: 0 auto;
        width: 3%;
        margin-bottom: 15px;
        padding-top: 3px;
        img {
            width: 22px;
        }
    }
    .riskinspetserv-pins-list-mob-item-ar {
        flex: 0 auto;
        width: 90%;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: $black;
        text-align: right;
        margin-right: 10px;
        margin-bottom: 15px;
    }
}
.riskinspetserv-mob-whykrlist-ar {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    .riskinspetserv-mob-whykrlist-item-ar {
        flex: 0 auto;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: $black;
        text-align: right;
        margin-bottom: 15px;
        img {
            width: 21px;
            margin-left: 10px;
        }
    }
}

h3.riskinspetserv-subtitle-en,
h4.riskinspetserv-subtitle-en {
    float: left;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 600;
    color: $vdblue;
    margin: 10px 0px;
}

.riskinspetserv-mob-keyfeatures-en {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
}

.riskinspetserv-mob-keyfeatures-img-en {
    flex: 0 auto;
    width: 3%;
}

.riskinspetserv-mob-keyfeatures-img-en img {
    width: 20px;
    padding-top: 5px
}

.riskinspetserv-mob-keyfeatures-q-en {
    flex: 0 auto;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    margin: 0px 0px 20px 20px;
    padding: 0px;
}

.riskinspetserv-mob-keyfeatures-ans-en {
    flex: 0 auto;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 400;
    color: $vdblue;
    text-align: justify;
    margin: 10px 10px 20px 8%;
    padding: 0px;
}

.dropdown-sec {
    background: #F7F7F7;
    padding: 30px 20px 20px 20px;
    position: fixed;
    text-align: center;
    left: 0px;
    right: 0px;
    top: 65px;
    border-bottom: #ECECEC solid 1px;
}

.form-select {
    font-family: 'Poppins', sans-serif;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: $vdblue !important;
    height: 45px !important;

}

.form-select option {
    margin: 10px 0px !important;
    font-size: 13px !important;
    color: #000 !important;
}

/* MOBILE SECTION CSS STARTS HERE */